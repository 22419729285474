.site-header {
  background-color: var(--whiteColor);
  padding: 0;
  box-shadow: var(--headerBoxShadow);
  z-index: 2;
}

/* .site-header>div {
    max-width: 1720px;
    padding: 0 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
} */

/* .site-header>div>div:first-child {
    flex: 1;
    display: flex;
    justify-content: center;
} */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-right: 2.5rem;
  flex-flow: row;
}

.header-search {
  width: 563px;
  max-width: 563px;
  max-height: 37px;
  background: #f4f6f8;
  align-items: center;
}

.header-search input::placeholder {
  color: #707070;
}

.header-search input {
  font-size: 18px;
  margin-left: 8px;
}
