/* FONT TYPE */
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

@font-face {
  font-family: "eFont";
  font-style: normal;
  font-weight: 400;
  src: local("eFont"),
    url("../../fonts/GTWalsheimPro-Regular.woff") format("woff"),
    url("../../fonts/GTWalsheimPro-Regular.eot") format("eot"),
    url("../../fonts/GTWalsheimPro-Regular.ttf") format("ttf"),
    url("../../fonts/GTWalsheimPro-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "eFont";
  font-style: normal;
  font-weight: bold;
  src: local("eFont"), url("../../fonts/GTWalsheimPro-Bold.woff") format("woff"),
    url("../../fonts/GTWalsheimPro-Bold.ttf") format("ttf");
}

@font-face {
  font-family: "eFont";
  font-style: normal;
  font-weight: 500;
  src: local("eFont"),
    url("../../fonts/GTWalsheimPro-Medium.woff") format("woff"),
    url("../../fonts/GTWalsheimPro-Medium.eot") format("eot"),
    url("../../fonts/GTWalsheimPro-Medium.woff2") format("woff2"),
    url("../../fonts/GTWalsheimPro-Medium.ttf") format("ttf");
}

/* FONT SIZE */

.text-md {
  font-size: 1.625rem;
  line-height: 1.4;
}

.text-smd {
  font-size: 1.125rem !important;
}

.text-sm {
  font-size: 0.8125rem !important;
}

.text-xlxs {
  font-size: 0.75rem;
}

.text-xs {
  font-size: 0.69rem !important;
}

/* FONT WEIGHT */

.text-normal {
  font-weight: 400 !important;
}

.text-semi-bold {
  font-weight: 600 !important;
}

.text-bold {
  font-weight: 700 !important;
}

.text-xbold {
  font-weight: 800 !important;
}

.text-xlbold {
  font-weight: 900 !important;
}

.text-light {
  font-weight: 300 !important;
}

.text-normal {
  font-weight: 400 !important;
}

.no-box--shadow {
  box-shadow: none !important;
}

.text-underline {
  text-decoration: underline;
}

/* FONT COLOR */

.color-primary {
  color: #5a50fe;
}

.color-primary:hover {
  color: #5a50fe;
}

.color-negative {
  color: #f7685b;
}

.color-white {
  color: #fff;
}

.color-faded {
  color: rgb(0, 0, 0, 0.5);
}

.color-light {
  color: rgb(0, 0, 0, 0.7);
}

.color-green {
  color: #dcffec;
}

.color-green {
  color: #008a00;
}

.color-text {
  color: #555555;
}

.color-bold {
  color: #1c223c;
}

.color-inactive {
  color: #707070;
}

.color-grey-2 {
  color: #48484d;
}

.color-black {
  color: #000;
}

.text-center {
  text-align: center;
}

.password__check--text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 1px;
  color: #55555e !important;
}

.password__check--text-p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #55555e;
}

.text-subtitle {
  color: var(--subTitleColor) !important;
}
