.dashboard-header-container {
  margin-bottom: 2.25rem;
}

.dashboard-header-container > div {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  min-width: 100%;
}

.dashboard-header-container > div > h4 {
  margin: 0;
  font-weight: bold;
  font-size: 1.25rem;
  color: var(--titleColor);
}

.dashboard-header-container > div > p {
  margin: 0.25rem 0 0 0;
  font-size: 1rem;
  color: var(--bodyTextColor);
}

.dashboard-overview-button-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.dashboard-overview-button-group > button {
  margin: 1.5rem 1rem 0 0;
  font-weight: 700;
  height: 2.5rem;
  padding: 0 0.675rem;
  font-size: 0.925rem;
  box-shadow: none;
  border-radius: 0.25rem !important;

  @media screen and (max-width: 768px) {
    &:last-of-type {
      margin-right: 0;
    }
  }
}

.dashboard-overview-button-group > button:not([disabled]) {
  color: var(--primaryColor);
  background-color: var(--secondaryButtonColor);
  border-color: var(--secondaryButtonColor);
}

.dashboard-overview-button-group > button:not([disabled]):hover {
  border-color: var(--primaryColor);
  background-color: var(--secondaryButtonColor);
  color: var(--primaryColor);
}

.dashboard-overview-button-group > button:not([disabled]).ant-btn-primary {
  color: var(--whiteColor);
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
}

.dashboard-overview-container {
  margin-bottom: 1.5rem;
}

.dashboard-overview-container > .ant-col > .ant-card,
.dashboard-wallets-container {
  height: 100%;
  box-shadow: var(--dashboardCardBoxShadow);
  border-radius: 0.25rem;
}

.dashboard-overview-summary-card > .ant-card-body {
  padding: 0;
  height: 100%;
}

.dashboard-overview-summary-card > .ant-card-body > div {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.dashboard-overview-summary-card > .ant-card-body > div > div {
  padding: 1.25rem;
}

.dashboard-overview-summary-card > .ant-card-body > div > div:first-child {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1rem;
  color: var(--subTitleColor);
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.dashboard-overview-summary-card
  > .ant-card-body
  > div
  > div:first-child
  .anticon {
  font-size: 1.2rem;
}

.dashboard-overview-summary-card
  > .ant-card-body
  > div
  > div:first-child
  > div {
  width: 100%;
  margin-top: 0.5rem;
  color: var(--titleColor);
  font-size: 1.25rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-overview-summary-card
  > .ant-card-body
  > div
  > div:first-child
  > div
  > span {
  display: inline-block;
  margin-right: 0.5rem;
}

.dashboard-overview-summary-card
  > .ant-card-body
  > div
  > div:first-child
  > div
  > span:nth-child(2) {
  margin-right: 1rem;
}

.dashboard-overview-summary-card
  > .ant-card-body
  > div
  > div:first-child
  > div
  .ant-avatar-image {
  height: 1.15rem;
  width: 1.15rem;
}

.dashboard-overview-summary-card > .ant-card-body > div > div:last-child {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  font-size: 1rem;
  background-color: var(--dashboardCardFooterbackgroundColor);
  color: var(--subTitleColor);
}

.dashboard-overview-summary-card > .ant-card-body > div > div:last-child > div {
  width: 100%;
  margin-top: 0.25rem;
  color: var(--titleColor);
  font-size: 1.1rem;
  font-weight: 500;
}

.dashboard-overview-summary-card
  > .ant-card-body
  > div
  > div:last-child
  > div
  > span {
  display: inline-block;
  margin-right: 0.5rem;
}

.dashboard-analytics-container > .ant-card-body,
.dashboard-wallets-container > .ant-card-body {
  padding: 1.25rem 1.25rem 0.75rem 1.25rem;
}

.dashboard-analytics-summary-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.dashboard-analytics-summary-container > div {
  display: flex;
}

.dashboard-analytics-summary-container > div img {
  margin: 0 0.5rem 0 0;
  height: 1.5rem;
  width: 1.5rem;
}

.dashboard-analytics-summary-container > div h5 {
  font-weight: normal;
  font-size: 1rem;
  color: var(--subTitleColor);
  margin: 0.15rem 0 0 0;
}

.dashboard-analytics-summary-container > div p {
  color: var(--titleColor);
  font-size: 1.05rem;
  margin: 0.25rem 0 0.1rem 0;
}

.dashboard-analytics-summary-container > div span {
  color: var(--titleColor);
  font-size: 0.85rem;
  margin: 0;
  display: block;
  color: var(--negativeChangeColor);
}

.dashboard-analytics-summary-container
  > div
  span.negative-percentage-change::before {
  content: "";
}

.dashboard-analytics-summary-container > div span.postive-percentage-change {
  color: var(--positiveChangeColor);
}

.dashboard-analytics-summary-container
  > div
  span.postive-percentage-change::before {
  content: "+";
}

.dashboard-analytics-graph {
  max-height: 15rem;
}

.dashboard-chart-loader-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dashboard-chart-loader-container > div:first-child {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  font-size: 1rem;
}

.dashboard-chart-loader-container > div:first-child > *:first-child {
  margin-right: 1rem;
  color: var(--subTitleColor);
}

.dashboard-chart-loader-container > div:first-child > *:last-child {
  border-bottom: 1px solid var(--bgGrey);
  flex: 1;
  padding: 0 0 1rem 0;
  margin-bottom: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--bodyTextColor);
  font-size: 1.025rem;
  text-align: center;
  height: 10rem;
}

.dashboard-chart-loader-container > div:first-child > *:last-child ul {
  list-style: none;
  display: flex;
  flex-grow: 1;
  margin: 0 0 1rem 0;
  align-items: flex-end;
}

.dashboard-chart-loader-container > div:first-child > *:last-child ul li {
  background-color: var(--chartLoaderBarColor);
  width: 1.5rem;
  margin: 0 0.5rem;
  height: 100%;
  border-radius: 0.25rem;
}

.dashboard-chart-loader-container
  > div:first-child
  > *:last-child
  ul
  li:nth-child(1) {
  height: 50%;
  opacity: 0.4;
}

.dashboard-chart-loader-container
  > div:first-child
  > *:last-child
  ul
  li:nth-child(3) {
  height: 75%;
  opacity: 0.85;
}

.dashboard-chart-loader-container
  > div:first-child
  > *:last-child
  ul
  li:nth-child(4) {
  height: 55%;
  opacity: 0.4;
}

.dashboard-chart-loader-legend-container {
  padding: 0.75rem 0 0.25rem 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: var(--subTitleColor);
  font-size: 0.95rem;
}

.dashboard-chart-loader-legend-container > span {
  margin-left: 2.25rem;
  position: relative;
}

.dashboard-chart-loader-legend-container > span::before {
  content: "\00B7";
  font-size: 3.5rem;
  line-height: 0rem;
  position: absolute;
  top: 0.7rem;
  left: -1.15rem;
}

.dashboard-analytics-header-container,
.dashboard-wallets-header-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
}

.dashboard-analytics-header-container h3,
.dashboard-wallets-header-container h3 {
  font-size: 1.1rem;
  font-weight: 700;
  color: var(--subTitleColor);
  margin: 0;
}

.dashboard-analytics-header-container > div:first-child,
.dashboard-wallets-header-container > div:first-child {
  flex: 1;
}

.dashboard-analytics-header-container .filter-analytics button,
.dashboard-analytics-header-container .wallet-selector button {
  padding: 0;
  border: none;
  width: 6rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 1rem;
  margin-left: 2rem;
  background-color: var(--whiteColor);
  color: var(--subTitleColor);
  margin-top: -0.25rem;
}

.dashboard-analytics-header-container .filter-analytics button {
  width: 2.5rem;
}

.dashboard-analytics-header-container .filter-analytics span:nth-child(2) {
  display: none;
}

.dashboard-analytics-header-container .filter-analytics img {
  height: 1rem;
  margin: 0 0.75rem 0 0;
}

.dashboard-analytics-header-container .wallet-selector .ant-avatar {
  height: 1.75rem;
  width: 1.75rem;
  margin-right: 0.5rem;
}

.dashboard-analytics-header-container .filter-analytics .anticon,
.dashboard-analytics-header-container .wallet-selector .anticon {
  margin-left: 0.5rem;
}

.dashboard-analytics-header-container
  .filter-analytics
  .ant-dropdown-open
  .anticon,
.dashboard-analytics-header-container
  .wallet-selector
  .ant-dropdown-open
  .anticon {
  transform: rotate(180deg);
  margin-bottom: 0.15rem;
}

.dashboard-dropdown-wallet-list {
  max-height: 25vh;
  overflow-y: auto;
  margin: 0 -0.25rem 0 0;
  overflow-x: hidden;
}

.dashboard-dropdown-wallet-list li {
  border-bottom: 1px solid var(--dropdownMenuSectionBackgroundColor);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem 0 0.75rem 0.75rem;
  min-width: 7rem;
  font-size: 0.95rem;
}

.dashboard-dropdown-wallet-list li > span:first-child {
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 0.5rem;
}

.dashboard-dropdown-wallet-list li:last-child {
  border-bottom-width: 0;
}

.ant-dropdown.filter-dropdown-menu.mobile {
  left: 7.5vw !important;
}

.dashboard-dropdown-container {
  max-width: 85vw;
}

.dashboard-dropdown-container > h3 {
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--dropdownMenuTitleColor);
  padding: 1rem 1.5rem 1rem 1.5rem;
  border-bottom: 1px solid var(--bgGrey);
  margin: 0;
}

.dashboard-dropdown-section > h4 {
  font-size: 1rem;
  font-weight: 500;
  color: var(--dropdownMenuTitleColor);
  padding: 1rem 1.25rem;
  margin: 0;
}

.dashboard-dropdown-section > div h5 {
  font-size: 1rem;
  font-weight: 500;
  color: var(--dropdownMenuTitleColor);
  padding: 0 0 1rem 0;
  margin: 0;
}

.dashboard-dropdown-section > div {
  background-color: var(--dropdownMenuSectionBackgroundColor);
  padding: 0.85rem 1.25rem;
}

.dashboard-dropdown-section > div ul {
  list-style: none;
  padding-left: 1.25rem;
  position: relative;
}

.dashboard-dropdown-section > div ul::before {
  content: "";
  border-left: 3px solid var(--dropdownMenuListBorderColor);
  position: absolute;
  top: -0.85rem;
  left: 0;
  bottom: 3rem;
}

.dashboard-dropdown-section > div ul li {
  position: relative;
}

.dashboard-dropdown-section > div ul li::before {
  content: "";
  border: 3px solid var(--dropdownMenuListBorderColor);
  border-width: 0 0 3px 3px;
  border-radius: 0 0 0 0.5rem;
  top: 0;
  bottom: 45%;
  left: -1.25rem;
  width: 0.75rem;
  position: absolute;
}

.dashboard-dropdown-section > div ul li:first-child {
  margin-bottom: 1rem;
}

.dashboard-dropdown-section .ant-radio-group {
  display: flex;
  flex-wrap: wrap;
}

.dashboard-filter-type-options > label:last-child,
.dashboard-filter-period-options > label:first-child,
.dashboard-filter-period-options > label:last-child {
  display: none;
}

.dashboard-dropdown-section .ant-radio-group .ant-radio-button-wrapper {
  padding: 0rem 0.65rem;
  min-width: auto;
  background-color: transparent;
  margin: 0.5rem 0.75rem 0.5rem 0;
  border-color: transparent;
  box-shadow: none;
  color: var(--subTitleColor);
}

.dashboard-dropdown-section .ant-radio-group .ant-radio-button-wrapper::before {
  background: none;
}

.dashboard-dropdown-section
  .ant-radio-group
  .ant-radio-button-wrapper:last-child {
  margin-right: 0;
}

.dashboard-dropdown-section
  .ant-radio-group
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  border-color: var(--primaryColor);
  background-color: var(--dropdownMenuSelectedRadioButtonBackgroundColor);
  color: var(--dropdownMenuTitleColor);
}

.dashboard-dropdown-section .ant-picker-range {
  height: 2.5rem;
  border-radius: 0.25rem;
  box-shadow: none !important;
  border-color: var(--lightPrimaryColor);
  margin-bottom: 0.5rem;
  width: 100%;
}

.dashboard-dropdown-section .ant-picker-range.ant-picker-focused {
  border-color: var(--primaryColor);
}

.dashboard-dropdown-section
  .ant-picker-range.ant-picker-focused
  .ant-picker-active-bar {
  background-color: var(--primaryColor);
}

.dashboard-dropdown-footer-container {
  padding: 1.25rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.dashboard-dropdown-footer-container button {
  margin-left: 1rem;
  border-color: var(--dropdownMenuSectionBackgroundColor);
  background-color: var(--dropdownMenuSectionBackgroundColor);
  color: var(--primaryColor);
  font-weight: 500;
  font-size: 0.9rem;
  text-shadow: none !important;
  padding: 0.25rem 1rem;
  height: 2.25rem;
  border-radius: 0.25rem;
  box-shadow: none !important;
}

.dashboard-dropdown-footer-container button:hover,
.dashboard-dropdown-footer-container button:focus {
  border-color: var(--primaryColor);
  color: var(--primaryColor);
}

.dashboard-dropdown-footer-container button.ant-btn-primary {
  border-color: var(--primaryColor);
  background-color: var(--primaryColor);
  color: var(--whiteColor);
}

.dashboard-wallets-container {
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .dashboard-header-container > div > h4 {
    font-size: 1.6rem;
  }
  .dashboard-header-container > div > p {
    font-size: 1.05rem;
  }
  .dashboard-overview-button-group > button {
    padding: 0 1.25rem;
    font-size: 0.95rem;
  }
  .dashboard-analytics-summary-container {
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 0rem;
  }
  .dashboard-analytics-summary-container > div img {
    margin: 0 0.75rem 0 0;
    height: 1.75rem;
    width: 1.75rem;
  }
  .dashboard-analytics-summary-container > div h5 {
    font-size: 1rem;
    margin: 0.25rem 0 0 0;
  }
  .dashboard-analytics-summary-container > div p {
    font-size: 1.15rem;
    margin: 0.35rem 0 0.2rem 0;
  }
  .dashboard-chart-loader-container > div:first-child > *:first-child {
    margin-right: 2rem;
  }
  .dashboard-chart-loader-container > div:first-child > *:last-child {
    padding: 0 4rem 1rem 4rem;
    height: 11.75rem;
  }
  .dashboard-dropdown-container {
    max-width: 29rem;
  }
  .dashboard-filter-type-options > label:last-child,
  .dashboard-filter-period-options > label:first-child,
  .dashboard-filter-period-options > label:last-child {
    display: block;
  }
  .dashboard-dropdown-section .ant-picker-range {
    width: 17.5rem;
  }
  .dashboard-analytics-header-container .filter-analytics span:nth-child(2) {
    display: block;
  }
  .dashboard-analytics-header-container .filter-analytics button {
    width: 6rem;
  }
}

@media only screen and (min-width: 992px) {
  .dashboard-header-container > div {
    min-width: auto;
  }
  .dashboard-header-container > div > h4 {
    font-size: 1.65rem;
  }
  .dashboard-header-container > div > p {
    font-size: 0.95rem;
  }
  .dashboard-overview-button-group {
    justify-content: flex-end;
  }
  .dashboard-overview-button-group > button {
    margin: 0 0 0 0.75rem;
    margin-right: 0;
    height: 2.25rem;
    padding: 0 1rem;
  }
  .dashboard-overview-summary-card > .ant-card-body > div > div {
    padding: 1.5rem;
  }
  .dashboard-overview-summary-card > .ant-card-body > div > div:first-child {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .dashboard-overview-summary-card
    > .ant-card-body
    > div
    > div:first-child
    > div {
    margin-top: 0.75rem;
    font-size: 1.4rem;
  }
  .dashboard-overview-summary-card > .ant-card-body > div > div:last-child,
  .dashboard-overview-summary-card > .ant-card-body > div > div:first-child {
    font-size: 0.95rem;
  }
  .dashboard-overview-summary-card
    > .ant-card-body
    > div
    > div:first-child
    > div
    .ant-avatar-image {
    height: 1.25rem;
    width: 1.25rem;
  }
  .dashboard-analytics-summary-container {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }
  .dashboard-chart-loader-container > div:first-child > *:last-child {
    padding: 0 1rem 1rem 1rem;
    height: 9.25rem;
  }
}

@media only screen and (min-width: 1200px) {
  .dashboard-header-container > div > p {
    font-size: 1rem;
  }
  .dashboard-overview-button-group > button {
    margin: 0 0 0 1rem;
    height: 2.5rem;
    padding: 0 1.25rem;
  }
  .dashboard-overview-summary-card > .ant-card-body > div > div {
    padding: 1.25rem 3rem;
  }
  .dashboard-overview-summary-card
    > .ant-card-body
    > div
    > div:first-child
    > div {
    margin-top: 1rem;
    font-size: 1.5rem;
  }
  .dashboard-overview-summary-card > .ant-card-body > div > div:last-child,
  .dashboard-overview-summary-card > .ant-card-body > div > div:first-child {
    font-size: 1rem;
  }
  .dashboard-overview-summary-card
    > .ant-card-body
    > div
    > div:first-child
    > div
    .ant-avatar-image {
    height: 1.5rem;
    width: 1.5rem;
  }
  .dashboard-analytics-summary-container {
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 0rem;
  }
  .dashboard-chart-loader-container > div:first-child > *:last-child {
    padding: 0 3rem 1rem 3rem;
    height: 11.5rem;
  }
  .dashboard-wallets-container {
    margin-bottom: 12rem;
  }
  .dashboard-wallets-list-outside-container,
  .dashboard-wallets-list-outside-container .ant-tabs {
    margin-bottom: -13rem;
    padding-bottom: 13rem;
  }
}

@media screen and (min-width: 1400px) {
  .dashboard-chart-loader-container > div:first-child > *:last-child {
    padding: 0 5.5rem 1rem 5.5rem;
    height: 11.75rem;
  }
}

@media screen and (min-width: 1600px) {
  .dashboard-chart-loader-container > div:first-child > *:last-child {
    padding: 0 16rem 1rem 16rem;
  }
}
