@import "~antd/dist/antd.css";
@import "bootstrap/dist/css/bootstrap.min.css";
@import url("variables.css");
@import url("reset.css");
@import url("general/load.css");
@import url("sections/load.css");

/* BORDER RADIUS */

.br-2 {
  border-radius: 8px;
}

.no-border {
  border: none !important;
}

.menu.m-top-10 {
  margin-top: 3.5em;
}

.e-header--wrap {
  box-shadow: 0px 2px 3px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  background: var(--whiteColor);
  width: 100%;
}

.e-logo {
  width: 100%;
  padding: 0 2.75rem;
  margin-top: 1.875rem;
}

.e-input {
  height: 53px;
}

div.e-input div.ant-select-selector,
.auth-login-wrap,
.header-search input {
  height: 100% !important;
}

.faded {
  color: var(--textColor);
}

.ant-input.ant-input-lg {
  background: transparent;
}

.content-wrap {
  padding: 40px;

  @media screen and (max-width: 768px) {
    padding: 20px;
  }
}

/* OVERVIEW RADIO BUTTONS */

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #000;
  background: #2f80ed;
  border-color: #2f80ed;
}

.ant-radio-button-wrapper {
  min-width: 154px;
  padding: 12px;
  /* min-height: 45px; */
  height: unset;
  margin-right: 1rem;
  text-align: center;
  border: 1px solid #1c223c;
  color: #1c223c;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  background: transparent;
}

.ant-radio-button-wrapper:first-child {
  border-left: 1px solid #1c223c;
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover {
  /* color: var(--whiteColor) !important; */
  color: #000;
}

.avatar-normal {
  width: 30px;
  height: 30px;
}

.avatar-medium {
  width: 32px;
  height: 32px;
}

.account-overview--card,
.account-analytics--card {
  width: 100% !important;
  max-width: 100%;
  box-shadow: 0px 2px 10px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
}

.account-balance--card,
.bulk__description--text {
  padding: 12px 24px;
}

.account-value--card {
  align-items: center;
  display: flex;
  justify-content: center;
}

.ant-card-body {
  padding: 20px;
}

.account-overview--card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.welcome-name {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 24px;
  color: #040052;
}

.welcome-paragraph {
  max-width: 565px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4b487a;
}

.link-btn {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #5a50fe;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  /* width:100%; */
}

.link-btn img {
  width: 30px;
}

.link-btn:focus {
  border: none;
  outline: none;
}

@media only screen and (max-width: 1024px) {
  .welcome-paragraph {
    font-size: 14px;
  }

  .link-btn {
    font-size: 12px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.badge-purple > .ant-badge-count {
  background: var(--purpleLightColor);
  color: #0000e0;
  padding: 5px;
  height: 27px;
  border-radius: 100px;
  width: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner-card {
  max-width: 100%;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 10px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  padding: 12px 18px;
}

.line-card {
  max-width: 100%;
  width: 100%;
  background: #ffffff;
  padding: 12px 18px;
  border-bottom: solid 1px rgba(24, 39, 75, 0.12);
}

.line-card:hover {
  background: #fafafa;
  cursor: pointer;
}

.account-volume--card {
  padding: 21px 23px;
}

.account-overview--wrap {
  width: 100%;
  max-width: 100%;
  flex-wrap: wrap;
}

.analytics-divider--wrap {
  max-width: 10%;
  padding: 79px 50px;
}

.analytics-divider--wrap > div {
  width: 1px;
  background: #ececef;
  border-radius: 8px;
  height: 94px;
}

.recharts-surface,
.recharts-wrapper,
.recharts-legend-wrapper {
  width: 100%;
  max-width: 100%;
}

.ant-scroll-number-only {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-scroll-number-only > p.ant-scroll-number-only-unit {
  height: fit-content;
}

.auth-login-preview {
  transition: transform 0.8s;
  transform: translateY(0px) !important;
  min-height: calc(100vh - 120px);
  min-height: calc(100vh - 120px);
  padding: 63px 40px;
  min-height: 100vh;
  padding-top: 130px;
}

.auth-login-wrap {
  max-width: 429px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.form-card {
  box-shadow: 0px 2px 10px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  padding: 60px 40px;
}

.form-card .ant-card-body {
  justify-content: unset;
  align-items: unset;
}

.form-card .ant-btn-primary {
  width: 100%;
  max-width: 100%;
  min-height: 50px;
}

.recovery-form-wrap {
  min-width: 519px;
  max-width: 519px;
}

.recovery-card.active {
  border: 2px solid #2f80ed;
}

.recovery-card {
  padding: 20px 24px;
  cursor: pointer;
}

.recovery-btn {
  min-width: 77.2%;
  max-width: 77.2%;
  min-height: 57px;
}

.btn-link {
  color: #5a50fe;
}

.signup-preview {
  width: 100%;
  min-height: 100vh;
}

.signup-wrap {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 4.5rem;
  padding-bottom: 4rem;
}

.register-info-wrap {
  padding-right: 2rem;
}

/* modal overlay */

.ant-modal-mask {
  background-color: rgba(53, 67, 142, 0.35);
}

.resend-modal-btn,
.welcome-modal-btn {
  width: 100%;
  height: 57px;
  background-color: #5a50fe !important;
  border-color: #5a50fe !important;
}

.welcome-modal-btn {
  height: 48px;
}

.simple-block-btn {
  width: 100%;
  height: 50px;
  background: #5a50fe;
}

.simple-block-btn:focus {
  background: #5a50fe;
}

.simple-block-btn:hover {
  background: #5a50fe;
}

.formInput {
  background: #fff !important;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 50px;
  font-weight: 400;
  margin-bottom: 10px;
  height: 56px;
  font-size: 14px;
  border: 1px solid #cbcae5;
  border-radius: 8px;
  width: 100%;
}

.formInput:focus {
  outline: none !important;
  border: 1px solid #5a50fe !important;
  box-shadow: none !important;
}

.ant-input-affix-wrapper-lg {
  padding: 0px 11px;
}

.mobile-input .formInput {
  border-bottom: 1px solid var(--offWhiteTrans);
}

.react-tel-input:focus {
  outline: none;
  border: 1px solid #5a50fe;
  box-shadow: none;
}

.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: transparent !important;
  bottom: 10px !important;
  border-radius: 0 !important;
}

.input-error-border {
  border: 2px solid #b11030;
}

.input-error-border:focus {
  border: 2px solid #b11030;
}

.header-dropdown-item:hover {
  background: transparent;
  color: #2f80ed;
}

.e-sidebar-logo {
  margin-top: 4rem;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
}

.onboarding-card {
  box-shadow: 0px 2px 10px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
}

.test-onboarding .ant-card-body {
  padding: 100px 0;
}

.test-onboarding .identity-image {
  width: 50px;
  margin-bottom: 20px;
}

.ant-input,
.ant-input-number-input,
.form__select {
  height: 50px !important;
}

.ant-input-number-input {
  height: 48px !important;
}

/* .ant-select-selection-item {
  line-height: 50px !important;
} */

.ant-select-selector img,
.ant-select-item-option-content img {
  width: 20px;
  margin-right: 15px;
}

.form__select {
  width: 100% !important;
}

.form__select .ant-select-selector {
  height: 100% !important;
  align-items: center;
}

.ant-select-selection-search input {
  height: 100% !important;
}

.btn {
  border-radius: 4px !important;
}

.auth-btn {
  height: auto;
  background: #5a50fe;
}

.auth-btn:hover {
  background: #5a50fe;
}

.btn-primary {
  background: #5a50fe;
  border: none;
  color: #fff;
}

.btn-primary:disabled {
  background-color: #d1cfff;
}

.btn-primary:focus {
  background-color: #5a50fe !important;
}

.btn-primary:hover {
  background-color: #5a50fe;
}

.auth-btn > span {
  padding: 9.5px 16.5px;
}

.forced-title {
  font-family: "eFont";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #040052;
}

.auth-btn[disabled],
.auth-btn[disabled]:hover,
.auth-btn[disabled]:focus,
.auth-btn[disabled]:active,
.e__btn--primary[disabled],
.e__btn--primary[disabled]:hover,
.e__btn[--primarydisabled]:focus,
.e__btn--primary[disabled]:active {
  color: white !important;
  background: #d1cfff !important;
  border-color: transparent !important;
}

.password__checker div div {
  height: 4px !important;
  border-radius: 30px;
}

.auth__card--wrap {
  background: #fff;
  padding: 40px;
}

.section__icon--md {
  width: 91px;
  height: 91px;
  margin-left: auto;
  margin-right: auto;
}

.section__icon--md svg {
  width: 100%;
  height: 100%;
}

.section__text--md-2 {
  font-size: 26px;
  color: #1c223c;
}

.bg__overlay--verify {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(60 73 121 / 40%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-input-affix-wrapper,
.header-search,
.ant-radio-button-wrapper,
.ant-radio-button-wrapper:first-child,
.inner-card,
.form-card,
.email-recovery-card,
.recovery-btn,
.ant-btn-primary {
  border-radius: 4px !important;
}

.ant-input-affix-wrapper {
  padding: 4px 11px;
}

.tab-header {
  display: flex;
  width: 100%;
  list-style-type: none;
  margin-bottom: 0;
}

.tab-header li {
  width: 33.33333%;
  padding: 19px 59px;
  background: #e3eefd;
  color: #707070;
  margin-right: 1px;
  cursor: pointer;
}

.tab-header li.active {
  background: #2f80ed;
  color: #fff;
  font-weight: 700;
}

.pin-input input {
  background: white;
  border-radius: 3px;
  border: 0;
  width: calc(25% - 5px);
  height: 44px;
  max-width: 44px;
  outline: none;
  text-align: center;
}

.pin-input {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  max-width: 196px;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid #35438e;
  border-radius: 4px;
  padding: 1px 10px;
}

.beneficiary--header {
  padding: 0.5rem 1.5rem 0.5rem 1.8rem;
  border: 1px solid #eee;
}

.empty__wrap--center {
  max-width: 359px;
  margin-left: auto;
  margin-right: auto;
}

.empty__button--wrap {
  max-width: 236px;
}

.empty__button {
  background: #e5e5e5;
  color: #1c223c;
  border: transparent;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.empty__button span:first-of-type {
  padding: 5px 5px 5px 1rem;
}

.empty__button span:nth-of-type(2) {
  padding-left: 0px;
}

.empty__description--beneficiary {
  max-width: 359px;
}

.empty__body--wrap {
  padding-top: 130px;
  padding-bottom: 130px;
}

.empty__body--wrap .btn-primary:hover {
  background: #5a50fe;
  color: #fff;
}

.bg__box--shadow {
  box-shadow: inset 0px 1px 0px -0.5px #c4cdd5;
}

.e__tabs {
  min-height: 450px;
}

.e__tabs .ant-tabs-nav-wrap {
  justify-content: center;
}

.e__tabs .ant-tabs-nav::before {
  border: none;
}

.card__account--verify {
  background: #f3f3f4;
  padding: 24px;
}

.group__btn--beneficiary {
  height: 45px;
}

.group__btn--beneficiary.first {
  margin-right: 32px;
}

.upload__card {
  background-color: #fffbe6;
  border: 1px solid #fff9dd;
  border-radius: 4px 4px 0px 0px;
  box-sizing: border-box;
  margin: 0;
  color: #000000d9;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  /* display: flex;
    align-items: center; */
  padding: 8px 15px;
  word-wrap: break-word;
}

.alert-icon {
  width: 45px;
  height: 45px;
}

.alert-icon span,
.alert-icon span svg {
  width: 100%;
  height: 100%;
}

.drag__card--info {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: auto;
}

.drag__card--info p {
  max-width: 300px;
}

.beneficiary__header--preview {
  padding: 12px 1.5rem;
  box-shadow: inset 0px -1px 0px -0.5px #c4cdd5;
  border-radius: 4px 4px 0px 0px;
}

.beneficiary__header--closeBtn {
  background: #f1f6fe;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.beneficiary__body--preview {
  flex-direction: row;
}

.beneficiary__body--preview-left {
  width: 40%;
  box-shadow: 0px 2px 0px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
}

.beneficiary__body--preview-right {
  width: 60%;
}

.beneficiary__avatar {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #ff84bf;
  position: relative;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.beneficiary__country {
  position: absolute;
  width: 17px;
  height: 17px;
  background: chocolate;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  border: 1px solid #fff;
}

.beneficiaries__list {
  overflow-x: scroll;
  max-height: 356px;
}

.beneficiary__item--details {
  box-shadow: inset 0px -1px 0px -0.5px rgba(196, 205, 213, 0.5);
  cursor: pointer;
}

.beneficiary__item--details:hover {
  background: #ecebff;
}

::-webkit-scrollbar {
  width: 4px;
}

/* Track */

::-webkit-scrollbar-track {
  border-radius: 16px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #2f80ed;
  border-radius: 16px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #2f80ed;
}

.beneficiary--preview-right-header,
.beneficiary--preview-right-content {
  padding: 50px 1.5rem 10px;
  display: flex;
}

.beneficiary__country-relative {
  width: 32px;
  height: 32px;
  background: chocolate;
  border-radius: 50%;
  border: 1px solid #fff;
  display: flex;
  margin-right: 14.1px;
}

.beneficiary-details-wrap {
  width: fit-content;
  display: inline-block;
}

.beneficiary-details-wrap p:first-of-type,
.beneficiary-details-wrap div.flex {
  margin-bottom: 10px !important;
}

.beneficiary__body--preview-footer {
  box-shadow: inset 0px 1px 0px -0.5px #c4cdd5;
  padding: 19px 32px;
  border-radius: 0px 0px 4px 4px;
  display: flex;
  justify-content: space-between;
}

.beneficiary__body--preview-footer button {
  padding-left: 60px;
  padding-right: 60px;
}

.account-balance--text.blur {
  -webkit-filter: blur(10.5px);
  filter: blur(10.5px);
}

.account__dropdown--value {
  display: flex;
  align-items: center;
}

.ant-table-wrapper {
  background-color: rgb(255, 255, 255);
}

thead[class*="ant-table-thead"] th {
  background-color: rgb(255, 255, 255);
  border-top: 1px solid #f0f0f0;
}

.ant-table-pagination {
  padding-right: 10px;
}

.card-btn {
  padding: 20px 64px 20px 64px;
  border: 1px solid #ffffff;
  cursor: pointer;
}

.card-btn:hover {
  border: 1px solid #dae2f1;
}

.completed-kyc-state {
  background: #ffffff;
  box-shadow: 0px 2px 10px -2px rgba(24, 39, 75, 0.12),
    0px 2px 4px -2px rgba(24, 39, 75, 0.08);
  border-radius: 4px;
  padding: 100px 0;
}

.completed-kyc-state h1 {
  font-family: "eFont";
  margin: 10px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #1c223c;
}

.completed-kyc-state p {
  font-family: "eFont";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #48484d;
  max-width: 433px;
  margin: auto;
  margin-bottom: 10px;
}

.completed-kyc-state button {
  font-family: "eFont";
  background: #5a50fe;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #ffffff;
  outline: none;
  border: none;
  padding: 10px 30px;
  margin-top: 20px;
}

.item-flex {
  position: relative;
}

.item-flex h1 {
  margin-top: 10px;
  margin-bottom: 10px !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 24px;
}

.input-fields-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.input-fields-flex > div {
  flex: 50%;
  margin-bottom: 10px;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  padding: 10px;
  cursor: pointer;
  width: 100%;
  /* margin-bottom: 30px; */

  height: 56px;
  background: #ffffff;
  border: 1px solid #cbcae5;
  border-radius: 8px;
  position: relative;
}

.upload-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background: rgb(216, 214, 255, 0.6);
  border-radius: 8px 0px 0px 8px;
}

.custom-file-upload.rejected-border {
  border: 1px solid #ea0016 !important;
}

.centered-items {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.custom-file-upload img {
  margin: 0 10px;
  width: 20px;
}

.check-svg-required svg {
  width: 25px;
}

.rejected-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #ea0016;
}

.custom-file-upload p {
  font-size: 16px !important;
  line-height: 24px !important;
  color: #6f6e80 !important;
  margin: 0 !important;
}

.custome-styling .nav-tabs {
  border-bottom: 1px solid transparent;
  flex-wrap: nowrap;
  align-items: stretch;
  margin: 0;
  padding: 0;
}

.custome-styling .nav-tabs .nav-link {
  /* width: 373px; */
  height: 64px;
  font-family: "eFont";
  background: #e3eefd;
  border-radius: 0px;
  margin: 0px 0.5px;
  padding: 19px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #707070;
  text-align: center;
  flex: 1;
}

.custome-styling .nav-tabs .nav-link.active {
  background: #2f80ed;
  border-color: transparent;
  color: #ffffff;
}

.settings-bg {
  background: #ffffff;
  box-shadow: 0px 2px 10px -2px rgba(24, 39, 75, 0.12),
    0px 2px 4px -2px rgba(24, 39, 75, 0.08);
  border-radius: 4px;
  height: 50vh;
}

.main-settings {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  font-family: "eFont";
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #1c223c;
  cursor: pointer;
}

.main-settings span {
  font-weight: bold;
  font-size: 16px;
  color: #1c223c;
}

.title-settings {
  background: #ffffff;
  box-shadow: inset 0px -1px 0px -0.5px rgba(196, 205, 213, 0.4);
  border-radius: 4px 4px 0px 0px;
  margin: 9px 0px;
  padding: 10px 30px;
}

.title-settings h1 {
  font-style: normal;
  font-weight: bold;
  font-family: "eFont";
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #555555;
}

.content-settings {
  padding: 0px 30px 20px 30px;
}

.content-settings h1 {
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  font-family: "eFont";
  color: #48484d;
  margin-bottom: 25px;
  cursor: pointer;
}

.content-settings h1 span {
  background: rgba(9, 26, 47, 0.5);
  border-radius: 4px;
  padding: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.03em;
  color: #ffffff;
  margin-left: 10px;
}

.content-settings > .h1:hover {
  background: #d5e5fb;
}

.spans:hover {
  color: #000 !important;
}

.content-settings h1 span {
  background: none;
}

.settings-tabs-settings .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #5a50fe;
}

.settings-tabs-settings .ant-tabs-tab .ant-tabs-tab-btn:hover {
  color: #5a50fe;
}

.settings-tabs-settings .nav-tabs {
  width: 100%;
}

.settings-tabs-settings .nav-tabs .nav-link {
  padding: 0 40px 10px 0;
  font-style: normal;
  font-weight: normal;
  font-family: "eFont";
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #555555;
}

.settings-tabs-settings .nav-tabs .nav-link span {
  background: rgba(9, 26, 47, 0.5);
  border-radius: 4px;
  padding: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.03em;
  color: #f7fafe;
  margin-left: 10px;
}

.settings-tabs-settings .nav-tabs .nav-link:hover {
  background-color: transparent;
  border: none;
}

.settings-tabs-settings .nav-tabs .nav-link.active {
  background-color: transparent;
  border-color: transparent;
  color: #5a50fe;
  border-bottom: 2px solid #5a50fe;
}

.settings-tabs-settings .tab-content {
  padding-top: 10px;
}

.business-settings-card {
  background: #ffffff;
  box-shadow: 0px 2px 10px -2px rgba(24, 39, 75, 0.12),
    0px 2px 4px -2px rgba(24, 39, 75, 0.08);
  border-radius: 4px;
  padding: 20px 0;
}

.business-settings-card .card-link:after {
  content: "\f107";
  font-family: "FontAwesome";
  font-weight: 900;
  float: right;
}

.business-settings-card .card-link.collapsed:after {
  content: "\f105";
  font-family: "FontAwesome";
}

.business-settings-card .card {
  border: none;
}

.business-settings-card .card-header {
  background: #ffffff;
  padding: 10px 40px;
  border: none;
}

.business-settings-card .card-header .card-link {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  font-family: "eFont";
  letter-spacing: 0.01em;
  text-decoration: none;
  color: #48484d;
}

.business-settings-card .card-header .card-link .verified {
  background: #dbefdc;
  border-radius: 8px;
  padding: 8px 8px;
  font-family: "eFont";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.015em;
  /* Eversend Blue/Semantics/Success/Dark/10 */
  color: #265828;
}

.business-settings-card .card-header .card-link .unverified {
  background: #fffbe6;
  border-radius: 8px;
  padding: 8px 8px;
  font-family: "eFont";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.015em;
  /* Eversend Blue/Semantics/Success/Dark/10 */
  color: #806900;
}

.business-settings-card .card-body {
  background: #f6f6f7;
  padding: 20px 40px;
}

.business-settings-card .card-body h1 {
  padding: 8px 16px;
  background: #e5e5e5;
  border-radius: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: #00004b;
  font-family: "eFont";
  width: 198px;
}

.business-settings-card .card-body h1 img {
  width: 15px;
}

.settings-picture .deleteIcon {
  width: 15px;
  height: 18px;
  margin-top: -12px;
  cursor: pointer;
}

.settings-picture .no-uploads {
  border-radius: 50%;
  border: 1px dashed #5a50fe;
  width: 80px;
  height: 80px;
  padding: 4px;
  margin-right: 10px;
}

.settings-picture .label-profile {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #2f80ed;
  margin-right: 10px;
  cursor: pointer;
}

.settings-picture .no-uploads .no-uploads-child {
  width: 70px;
  height: 70px;
  background: #f6f6f7;
  border-radius: 50%;
  margin: auto;
  color: #a1acbc;
  font-size: 40px;
  font-weight: 500;
  padding: 5px 10px 5px 23px;
  font-style: normal;
}

.business-settings-card .card-body input {
  background: #ffffff;
  border: 2px solid #35438e;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 56px;
  padding: 10px 20px;
  font-style: normal;
  font-weight: normal;
  font-family: "eFont";
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #707070;
}

.business-settings-card .card-body input:disabled {
  background: #e5e5e5;
  border: 2px solid #bdbdbd;
}

.business-settings-card .card-body button {
  background: #5a50fe;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.015em;
  color: #ffffff;
  padding: 16px 24px;
  width: 100%;
  border: none;
  outline: none;
}

.business-settings-card.business-settings-card .business-settings-card-edit-btn,
.business-settings-card-clip-board.business-settings-card-clip-board button {
  width: auto;
  background-color: transparent;
  color: #5a50fe;
  padding: 0;
}

.label-auth {
  font-family: "eFont";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #1c223c;
}

.profile-tab-settings {
  background: #ffffff;
  box-shadow: 0px 2px 10px -2px rgba(24, 39, 75, 0.12),
    0px 2px 4px -2px rgba(24, 39, 75, 0.08);
  border-radius: 4px;
  padding: 20px 30px;
}

.profile-tab-settings h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.015em;
  color: #0000e0;
  font-family: "eFont";
  background: #cdcdf9;
  border-radius: 8px;
  padding: 4px 8px;
  width: 98px;
  text-transform: capitalize;
}

.profile-tab-settings .role-title {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.015em;
  color: #0000e0;
  font-family: "eFont";
  background: #cdcdf9;
  border-radius: 8px;
  padding: 4px 8px;
  /* width: 98px; */
  text-transform: capitalize;
}

.profile-tab-settings h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #1c223c;
}

.profile-tab-settings input {
  background: #ffffff;
  border: 2px solid #35438e;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 56px;
  padding: 10px 20px;
  font-style: normal;
  font-weight: normal;
  font-family: "eFont";
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #707070;
}

.profile-tab-settings input:disabled {
  background: #e5e5e5;
  border: 2px solid #bdbdbd;
}

.profile-tab-settings button {
  background: #5a50fe;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.015em;
  color: #ffffff;
  padding: 16px 24px;
  width: 100%;
  border: none;
  outline: none;
  font-family: "eFont";
}

.wallet-new-design h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #48484d;
  font-family: "eFont";
}

.wallet-new-design p {
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 39px;
  letter-spacing: 0.01em;
  color: #5a50fe;
  font-family: "eFont";
}

.wallet-hide-bal {
  background: #f0f0f0;
  border-radius: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  font-family: "eFont";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.015em;
  color: #48484d;
  outline: none;
  border: none;
  margin-right: 15px;
}

.wallet-hide-bal span {
  margin-right: 5px;
}

.left-par {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.015em;
  color: #696779;
  font-family: "eFont";
}

/* .current-wallet-active {
  margin-top: 70px;
} */

.current-wallet-active .wallet-card {
  background: #ffffff;
  border-radius: 0px 0px 4px 4px;
  cursor: pointer;
  position: relative;
  /* border-radius: 4px; */
}

.current-wallet-active .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #5a50fe;
}

.current-wallet-active .ant-tabs-tab:hover {
  color: #5a50fe;
}

.current-wallet-active .wallet-card .pad-Wallet {
  padding: 40px 24px;
}

.current-wallet-active .wallet-card .more-wallet-option {
  background: #f6f6f7;
  border-radius: 4px 4px 0px 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  padding: 5px 20px;
  display: none;
}

.current-wallet-active .wallet-card .more-wallet-option .ms-auto img {
  margin-right: 10px;
  cursor: pointer;
}

.current-wallet-active .wallet-card a {
  text-decoration: none;
}

.current-wallet-active .wallet-card:hover {
  box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12),
    0px 12px 42px -4px rgba(24, 39, 75, 0.12);
}

.current-wallet-active .wallet-card .curImage {
  width: 35px;
  height: 35px;
  margin-right: 20px;
  border-radius: 50%;
  margin-top: -20px;
}

.current-wallet-active .wallet-card p {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  font-family: "eFont";
  color: #1c223c;
}

.current-wallet-active .wallet-card p span {
  font-weight: normal;
  color: #555555;
  line-height: 26px;
}

.current-wallet-active .wallet-card i {
  color: #5a50fe;
  opacity: 0;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.current-wallet-active .wallet-card:hover .showNow {
  opacity: 1;
}

.current-wallet-active .wallet-card:hover .more-wallet-option {
  display: block;
}

.deleteTooltip {
  position: relative;
  display: inline-block;
}

.deleteTooltip .tooltip-ex-text {
  /* This is for the tooltip text */
  visibility: hidden;
  background: rgba(9, 26, 47, 0.5);
  border-radius: 4px;
  width: 100px;
  text-align: center;
  padding: 2px;
  position: absolute;
  z-index: 1;
  top: 28px;
  left: -35px;
  font-style: normal;
  font-weight: normal;
  font-family: "eFont";
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.03em;
  color: #ffffff;
}

.deleteTooltip:hover .tooltip-ex-text {
  /* Makes tooltip text visible when text is hovered on */
  visibility: visible;
}

.deleteTooltip img {
  margin-right: 20px;
}

.singleWallet-h1 span {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #696779;
  cursor: pointer;
}

.singleWallet-h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  font-family: "eFont";
  color: #1c223c;
}

.single-wallet-buttons .btn-one {
  padding: 10px 24px;
  background: #5a50fe;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.01em;
  font-family: "eFont";
  color: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;
}

.single-wallet-buttons .btn-one:disabled {
  opacity: 0.5;
}

.single-wallet-buttons .btn-one img {
  margin-right: 10px;
}

.single-wallet-buttons button {
  margin-top: 10px;
  margin-right: 5px;
  margin-bottom: 20px;
  font-family: "eFont";
}

.single-wallet-buttons .btn-two {
  padding: 10px 24px;
  background: #f0f0f0;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  font-family: "eFont";
  letter-spacing: 0.01em;
  color: #5a50fe;
  outline: none;
  border: none;
  cursor: pointer;
}

.single-wallet-buttons .btn-two img {
  margin-right: 10px;
}

.single-wallet-financial {
  background: #ffffff;
  box-shadow: 0px 2px 10px -2px rgba(26, 42, 82, 0.04),
    0px 2px 4px -2px rgba(24, 39, 75, 0.06);
  border-radius: 4px;
  padding: 32px 8px;
}

.single-wallet-financial h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  font-family: "eFont";
  letter-spacing: 0.01em;
  color: #48484d;
}

.single-wallet-financial p {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  font-family: "eFont";
  letter-spacing: 0.01em;
  color: #1c223c;
}

.wallet-single-h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  font-family: "eFont";
  color: #48484d;
}

.wallet-activate .img-flag {
  margin-right: 15px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.wallet-activate .currency-type {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.01em;
  font-family: "eFont";
  color: #555555;
}

.wallet-activate .currency-type span {
  color: #1c223c;
  line-height: 30px;
  font-weight: bold;
}

.icon-direction {
  color: #4caf50;
  font-size: 24px;
}

.face-left {
  color: #fd66a3;
}

.add-money-h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  font-family: "eFont";
  color: #000000;
  margin-bottom: 10px;
}

.add-money-p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #48484d;
  max-width: 397px;
  margin: auto;
  font-family: "eFont";
  margin-bottom: 10px;
}

.transfer-delay {
  background: #f1f1fd;
  border-radius: 4px;
  padding: 10px;
  max-width: 350px;
  margin: auto;
  margin-bottom: 10px;
}

.transfer-delay p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  font-family: "eFont";
  color: #00004b;
  margin: 0;
  margin-bottom: 15px;
}

.transfer-delay img {
  margin-right: 15px;
  width: 15px;
  height: 15px;
  margin-top: 5px;
}

.transfer-delay-card {
  padding: 10px;
  max-width: 370px;
  margin: auto;
  margin-bottom: 10px;
}

.naija-account-number-h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.01em;
  font-family: "eFont";
  color: #000000;
}

.naija-account-number-p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #48484d;
  font-family: "eFont";
}

.other-countries-add-money {
  background: #ffffff;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

.other-countries-add-money img {
  margin-right: 10px;
}

.other-countries-add-money h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #1c223c;
  margin-top: 15px;
}

.other-countries-add-money p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #555555;
}

.banktransfer-others {
  margin: 0 auto 0 auto;
  width: 80%;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.tabs-for-add-money .ant-input-affix-wrapper {
  width: 100% !important;
}

.banktransfer-others h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #000000;
}

.banktransfer-others h3 {
  font-family: "DM Sans";
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1px;
  margin: 0px;
  cursor: pointer;
}

.banktransfer-others h4 {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  margin: 0px;
}

.custom-select-card {
  height: 72x;
  border-radius: 8px;
  border: 1px solid #e9ebed;
  padding: 10px;
}

.custom-select-card .ant-select-selector {
  background-color: #f8f9fa !important;
  border-radius: 40px !important;
}

.custom-select-card .ant-select-selector img {
  border-radius: 50%;
}

.custom-select-card .ant-select-selector .ant-select-selection-item {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0px;
  margin: 0px;
}

.custom-select-card .ant-input-number {
  background: transparent !important;
  height: 0px !important;
  width: 100% !important;
}

.custom-select-card .ant-input-number-input {
  height: 35px !important;
  text-align: right !important;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  color: #040052;
}

.custom-play textarea.ant-input {
  /* height: 72x; */
  border-radius: 8px !important;
  border: 1px solid #e9ebed !important;
  padding: 10px;
}

.remaining-time {
  background: #fcf6de;
  border: 1px solid #ffc700;
  padding: 10px 20px;
  max-width: 300px;
  margin: auto;
  border-radius: 8px;
}

.request-expire {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  color: #4b487a;
}

.request-expire-time {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  color: #4b487a;
}
/* .ant-input-number-focused {
  box-shadow: none !important;
} */

.wallet-request-title {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  margin: 0px;
}

.wallet-request-title-bold {
  font-family: "DM Sans";
  font-size: 23px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  margin: 0px;
}

.wallet-request-title-new {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  margin: 0px;
}

.small-request-money {
  font-family: "DM Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  margin: 0px;
}

.money-request-p {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  margin: 0px;
}

.greyed-out {
  background: #f6f6f7;
  padding: 8px;
}

.greyed-bank-transfer {
  background: #f6f6f7;
  border-radius: 4px;
  padding: 24px 8px;
}

.banktransfer-others p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #444052;
  margin: 0px;
}

.banktransfer-others h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #1c223c;
}

.add-money-crypto-div .btn-btn-btn {
  background: #5a50fe;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #ffffff;
  outline: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  width: 60%;
}

.add-money-crypto-div .btn-btn-btn:disabled {
  opacity: 0.5;
}

.banktransfer-others .btn-btn-btn {
  background: #5a50fe;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #ffffff;
  outline: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  width: 100%;
}

.banktransfer-others .request-search-btn {
  background: #5a50fe;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: #ffffff;
  outline: none;
  border: none;
  padding: 8px;
  cursor: pointer;
}

.banktransfer-others .request-search-btn:disabled {
  opacity: 0.5;
}

.banktransfer-others .grey-btn-btn {
  background: #f0f0f0;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  outline: none;
  border: none;
  padding: 10px;
  color: #5a50fe;
  width: 100%;
}

.banktransfer-others .btn-btn-btn:disabled {
  opacity: 0.5;
}

.banktransfer-others .btn-btn-play {
  background: #f0f0f0;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #5a50fe;
  outline: none;
  border: none;
  padding: 10px;
  width: 100%;
}

.banktransfer-others .btn-btn-playnothing {
  background: transparent;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #5a50fe;
  outline: none;
  border: none;
  padding: 10px;
  width: 100%;
}

.banktransfer-others .newBtnPPlay {
  background: #f0f0f0;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #5a50fe;
  outline: none;
  border: none;
  padding: 10px;
  width: 100%;
}

.continue-to-wallet .desc-p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: #48484d;
}

.continue-to-wallet .btn-btn-btn {
  background: #5a50fe;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #ffffff;
  outline: none;
  border: none;
  padding: 10px;
  width: 100%;
}

.btn-one-team {
  background: #f0f0f0;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #1c223c;
  padding: 16px 24px;
  outline: none;
  border: none;
}

.tBut {
  width: 130px;
  height: 40px;
  font-weight: bolder;
}

.payout-modal-text
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon {
  background: #5a50fe;
}

.payout-modal-text .ant-steps-item-process .ant-steps-item-icon {
  border-color: #5a50fe;
}

.payout-modal-text .ant-steps-item-finish .ant-steps-item-icon {
  border-color: #5a50fe;
}

.payout-modal-text
  .ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon {
  color: #5a50fe;
}

.payout-modal-text
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #5a50fe;
}

.payout-modal-text
  .ant-steps-vertical.ant-steps-small
  .ant-steps-item-container
  .ant-steps-item-title {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
}

.first-payout {
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.first-payout .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #5a50fe;
}

.first-payout .ant-tabs-tab-btn:hover {
  color: #5a50fe;
}

.first-payout .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn::selection {
  background: #5a50fe;
}

.payout-tabs .nav-tabs .nav-link {
  color: #555555;
  margin-left: 50px;
}

.payout-tabs .nav-tabs .nav-link:hover {
  border: none;
}

.payout-tabs .nav-tabs .nav-link.active {
  color: #5a50fe;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #5a50fe;
}

.paySomeone .tab-content {
  padding-top: 20px;
}

.payout-flex img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.payout-flex p {
  margin-top: 10px;
}

.paySomeone .grey-box {
  padding: 10px;
  cursor: pointer;
}

.paySomeone .grey-box:hover {
  background: #eff0f6;
  border-radius: 4px;
}

.paySomeone .wallet-card {
  background: #f6f6f7;
  border-radius: 4px;
  padding: 5px 40px;
  cursor: pointer;
}

.paySomeone .wallet-card img {
  width: 35px;
  height: 35px;
  margin-right: 20px;
}

.paySomeone .wallet-card p {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  font-family: "eFont";
  color: #1c223c;
}

.paySomeone .wallet-card p span {
  font-weight: normal;
  color: #555555;
  line-height: 26px;
}

.paySomeone .wallet-card i {
  opacity: 0;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.paySomeone .wallet-card:hover .showNow {
  opacity: 1;
}

.edited-form-details-pay {
  background: #f6f6f7;
  border-radius: 4px;
  padding: 15px;
}

.edited-form-details-pay h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #1c223c;
}

.pay-summary-h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #555555;
}

li.recharts-legend-item {
  display: inline-flex !important;
  margin-right: 32px !important;
}

li.recharts-legend-item:last-child {
  margin-right: 0 !important;
}

li.recharts-legend-item .recharts-surface {
  margin-top: 4px;
  margin-right: 8px !important;
}

.recharts-layer .recharts-bar-rectangle {
  opacity: 1;
}

/* 
.recharts-layer .recharts-bar-rectangle:hover {
  opacity: 1;
} */

.recharts-tooltip-cursor {
  opacity: 0.5;
}

.list-view {
  background-color: white;
  padding: 16px 40px;
  cursor: pointer;
}

.list-view a {
  text-decoration: none;
  color: black;
}

.list-view:hover {
  background: #eff0f6;
}

.list-view-image img {
  width: 35px;
  height: 35px;
  margin-right: 20px;
  border-radius: 50%;
}

.list-view-image p {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  font-family: "eFont";
  color: #1c223c;
}

.list-view p span {
  font-weight: normal;
  color: #555555;
  line-height: 26px;
}

.inline-list-items .p-tag {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #1c223c;
  margin-right: 20px;
}

.inline-list-items img {
  margin-top: -4px;
  width: 16px;
}

.hide-show-list {
  display: none;
}

.list-view:hover .hide-show-list {
  display: inline;
}

.flex-row-container {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 20px;
}

.transaction-filters {
  position: absolute;
  right: 12px;
  top: 15px;
  z-index: 99;
}

.transaction-filters .ant-dropdown-trigger {
  background-color: transparent;
  border: none;
}

.transaction-filters .ant-btn-default {
  background-color: #5a50fe;
  color: white;
}

.transaction-filters .ant-btn-icon-only {
  background-color: #e5e5e5 !important;
  border-radius: 0px 4px 4px 0px !important;
  color: #040052 !important;
}

.playground-filter {
  width: 400px;
}

.playground-filter h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #48484d;
}

.dateHandle {
  background: #f6f6f7;
}

.playground-filter .ant-radio-button-wrapper {
  min-width: 0px;
  padding: 2px 6px;
  /* min-height: 45px; */
  background-color: transparent;
  margin-right: 1rem;
  text-align: center;
  border: none;
  color: #1c223c;
  transition: none;
}

.playground-filter .ant-radio-button-wrapper-checked {
  background: #e5e5e5;
  border: 1px solid #5a50fe;
  border-radius: 4px;
  color: #5a50fe;
}

.playground-filter .ant-radio-button-wrapper-checked:hover {
  color: #5a50fe;
  border: 1px solid #5a50fe;
}

.single-transaction-financial {
  background: #ffffff;
  box-shadow: 0px 2px 10px -2px rgba(24, 39, 75, 0.12),
    0px 2px 4px -2px rgba(24, 39, 75, 0.08);
  border-radius: 4px;
  padding: 20px 0;
}

.single-transaction-financial a {
  color: white;
  background-color: #5a50fe;
  padding: 10px 15px;
  border-radius: 5px;
}

.internal-bg {
  box-shadow: inset 0px -1px 0px -0.5px rgba(196, 205, 213, 0.4);
  border-radius: 4px 4px 0px 0px;
  padding: 0px 20px 10px 20px;
}

.trans-details {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #000000;
}

.trans-details-h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 39px;
  letter-spacing: 0.01em;
  color: #000000;
}

.trans-details .div.ant-typography {
  margin-bottom: 0px !important;
}

.recepient-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #555555;
}

.pass-wrapper {
  position: relative;
}

.passwordVisible {
  position: absolute;
  top: 45px;
  z-index: 10;
  right: 15px;
}

.ant-input-affix-wrapper > input.ant-input {
  height: 30px !important;
}

/* .ant-input-affix-wrapper {
  height: 42px !important;
} */

.card-container-one .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  display: inline-block;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #5a50fe;
  border-color: #5a50fe;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #5a50fe;
}

.card-container-one .ant-tabs-nav {
  margin: 0;
}
/* 
.card-container-one .ant-tabs-nav-list {
  width: 100%;
} */

@media only screen and (max-width: 1024px) {
  .card-container-one .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    display: flex;
  }
}

.card-container-one .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  color: #55555e;
  background: #ecebff;
}

.card-container-one .ant-tabs-tab {
  width: 33.33333%;
  padding: 19px 59px;
  height: 64px;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  align-items: center;
  letter-spacing: 0.01em;
}

/* .card-container-one .ant-tabs-tab.ant-tabs-tab-disabled {
  margin-left: 0px !important;
} */

.card-container-one
  .ant-tabs-card.ant-tabs-top
  > .ant-tabs-nav
  .ant-tabs-tab
  + .ant-tabs-tab {
  margin-left: 4px !important;
}

.card-container-one .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white;
}

.card-container-one
  .ant-tabs-card.ant-tabs-top
  > .ant-tabs-nav
  .ant-tabs-tab-active {
  background: #5a50fe;
  /* border-radius: 4px 0px 0px 0px; */
}

.exchange-select
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #d9d9d9;
  background: #f6f6f7;
  border-radius: 4px 0px 0px 4px;
  height: 50px;
}

.exchange-select .ant-select-selection-item {
  line-height: 50px !important;
}

.dt-btn button {
  background: #5a50fe;
  color: white;
  height: 50px;
}

.dt-btn button:hover {
  background: #5a50fe;
  color: white;
}

.dt-btn button:hover span {
  background: #5a50fe;
  color: white;
}

.otp-css {
  border: 1.5px solid #35438e;
  border-radius: 4px;
  width: 230px;
  margin: auto;
  padding: 20px 0 20px 0;
}

.otp-css input {
  width: 30px !important;
  height: 20px !important;
}

.upload-form-item .ant-form-item-control-input-content > span {
  display: block;
}

.upload-form-item .ant-form-item-label {
  display: block !important;
}

.bank-transfer-modal {
  background: #ffffff;
  border: 1px solid #e9ebed;
  border-radius: 12px;
  padding: 20px;
}

.bank-transfer-modal .curImage {
  width: 35px;
  height: 35px;
  margin-right: 20px;
  border-radius: 50%;
  /* margin-top: -15px; */
}

.bank-transfer-modal:hover .showNow {
  opacity: 1;
}

.wallet-add {
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #e9ebed;
  margin-bottom: 15px;
  position: relative;
}

.wallet-add:hover {
  background: #ecebff;
  border-radius: 4px;
}

.wallet-add .img-flag {
  /* margin-top: -15px;
  margin-right: 15px; */
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.wallet-add .currency-type {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.01em;
  font-family: "eFont";
  color: #555555;
  margin: 0px;
}

.wallet-add .currency-type span {
  color: #1c223c;
  line-height: 30px;
  font-weight: bold;
}

.banktransfer-others .ant-space-item {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 10px 20px;
}

.selectedPaymentType {
  margin-right: 7px;
  width: 11px;
  margin-top: -4px;
}

.newBankAccount {
  border: 1px dashed #5a50fe;
  border-radius: 4px;
  padding: 20px;
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #5a50fe;
  margin-top: 20px;
}

.notify-ban-account {
  background: #fffbe5;
  border-radius: 4px;
  padding: 10px;
}
.tooltip-h1 {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: white;
  margin: 0px;
}

.notify-ban-account .header-bank {
  font-style: 400;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: #806900;
  font-family: "DM Sans";
  margin-left: 20px;
  /* margin-top: 15px; */
}

.notify-ban-account .header-bank-par {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #806900;
}

.notify-ban-account .header-bank-par::before {
  content: "•";
  margin-right: 5px;
}

.addmoney-title {
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 39px;
  letter-spacing: 0.01em;
  color: #1c223c;
  margin: 0px;
}

.request-money-title {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
}

.payment-modal-text .ant-steps-item-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #8a8a8a;
}

.wallet-add-money {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #1c223c;
}

.wallet-addmoney-title {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #1c223c;
}

.addmoney-form .ant-input-number {
  border-left: 1px solid white !important;
}

.addmoney-form .ant-select .ant-select-selector {
  border-right: 1px solid white !important;
  height: 50px;
  padding: 10px;
}

.addmoney-form-crypto .ant-select .ant-select-selector {
  height: 50px;
  padding: 10px;
  border: 1.5px solid #35438e !important;
  border-radius: 4px !important;
}

.notaBena {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.015em;
  color: #48484d;
  margin-top: 5px;
}

.some-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
}

.crypto-h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #1c223c;
  text-align: center;
  margin-bottom: 10px;
}

.crypto-p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #48484d;
  max-width: 260px;
  margin: auto;
}

.crypto-address {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #1c223c;
  word-wrap: break-word;
}

.qr-text {
  color: #48484d !important;
  max-width: 155px;
}

.qr-text-link {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  /* line-height: 26px; */
  letter-spacing: 0.01em;
  color: #2f80ed;
}

.add-money-ul li {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #48484d;
  margin-bottom: 10px;
}

.single-beneficial-header {
  background: #ffffff;
  box-shadow: 0px 2px 10px -2px rgb(26 42 82 / 4%),
    0px 2px 4px -2px rgb(24 39 75 / 6%);
  border-radius: 4px;
  padding: 10px 10px;
  display: flex;
  justify-content: flex-end;
}

.single-beneficiary-body {
  background: #ffffff;
  box-shadow: 0px 2px 10px -2px rgb(26 42 82 / 4%),
    0px 2px 4px -2px rgb(24 39 75 / 6%);
  border-radius: 4px;
  padding: 10px 10px;
}

.single-beneficiary-shadow {
  box-shadow: 0px 6px 38px -6px rgba(24, 39, 75, 0.12),
    0px 8px 16px -6px rgba(24, 39, 75, 0.08);
}

.btn-download {
  color: #4598f5;
  font-weight: bolder;
  height: 40px;
}

.icons-ben {
  font-size: larger;
}

.xxxLarger {
  height: 45px !important;
  width: 45px !important;
  font-size: 34px !important;
  line-height: 42px !important;
  background-color: #f6f6f7 !important;
  border: none !important;
  float: right;
  display: inline;
  margin-right: 0;
  font-weight: 600;
}

.xxxText {
  color: #a1acbc !important;
}

.remText {
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.01em;
  text-align: left;
}

.remTextH2 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
}

.users-filters {
  position: absolute;
  right: 12px;
  top: 8px;
  z-index: 99;
}

.drawer {
  position: absolute;
  z-index: 99;
  background-color: #fff;
  width: 100%;
  padding-left: 10%;
  background: #faf8f8;
  box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12),
    0px 12px 42px -4px rgba(24, 39, 75, 0.12);
  border-radius: 4px;
}

.text-sixteen {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.text-eighteen {
  font-size: 18px !important;
  font-weight: 400 !important;
}

.bene-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #5a50fe;
}

.ant-tabs-ink-bar {
  background: #5a50fe !important;
}

.bene-tabs .ant-tabs-tab .ant-tabs-tab-btn:hover {
  color: #5a50fe;
}

.beneficiary-progress .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #5a50fe;
}

.beneficiary-progress .ant-tabs-tab .ant-tabs-tab-btn {
  color: #5a50fe;
}

.beneficiary-progress-test
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon {
  background: #5a50fe;
}

.beneficiary-progress-test .ant-steps-item-process .ant-steps-item-icon {
  border-color: #5a50fe;
}

.beneficiary-progress-test .ant-steps-item-finish .ant-steps-item-icon {
  border-color: #5a50fe;
}

.beneficiary-progress-test .ant-steps-item-finish .ant-steps-item-icon:hover {
  border-color: #5a50fe;
}

.beneficiary-progress-test
  .ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon {
  color: #5a50fe;
}

.beneficiary-progress-test
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #5a50fe;
}

.beneficiary-progress-test
  .beneficiary-progress-test
  .ant-steps-item
  .ant-steps-item-finish:hover
  .ant-steps-item-title {
  color: #5a50fe;
}

.this-table .ant-typography {
  color: #4b487a;
}

.this-table .anticon-copy svg {
  color: #4b487a;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #5a50fe;
}

.ant-tabs-tab .ant-tabs-tab-btn:hover {
  color: #5a50fe;
}

.dashboard-header-container-head {
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: row;
}

.dashboard-header-container-head > div:first-child {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
}

.header-autocomple-container {
  width: 40%;
  margin-left: calc(var(--lgFont) * -1.3);
  background-color: var(--bgLightGrey);
}

@media only screen and (max-width: 1024px) {
  .header-autocomple-container {
    width: 85%;
  }
}

/* .header-autocomple-container .ant-input-affix-wrapper {
  height: calc(var(--inputHeight) * 0.85) !important;
} */

.header-autocomple-container input {
  margin: 0 calc(var(--xxxsFont) * 0.55);
  height: var(--inputHeight);
  font-size: var(--smFont);
}

.header-search-results-item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header-search-results-item-container > div:first-child {
  display: flex;
  flex-direction: column;
}

.header-avatar-container {
  margin-right: calc(var(--xxsFont) * 0.85);
}

.header-dropdown-user-container-action-buttons {
  width: 234px;
  padding: 8px 8px 8px 8px;
  border-radius: 8px;
}

.header-dropdown-user-container-action-buttons p {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  margin: 0px;
}

.header-dropdown-user-container-action-buttons .ant-dropdown-menu-item-active {
  background-color: transparent;
}

.header-dropdown-user-container {
  padding-bottom: calc(var(--xxxsFont) * 0.75);
}

.header-dropdown-user-container h1 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #444052;
}

.header-dropdown-user-container p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #4b487a;
  max-width: 353.53px;
}

.header-dropdown-user-container .ant-dropdown-menu-item-divider {
  margin: calc(var(--xxxsFont) * 0.85) 0;
}

.header-dropdown-user-container .ant-dropdown-menu-item {
  padding: calc(var(--xxxsFont) * 1.1) calc(var(--xxxsFont) * 1.5);
  font-size: var(--smFont);
}

.header-dropdown-user-container .ant-dropdown-menu-item a {
  display: flex;
  align-items: center;
}

.header-dropdown-user-container .ant-dropdown-menu-item svg {
  margin-right: var(--xxxsFont);
  font-size: var(--xlFont);
}

.header-dropdown-user-details-container {
  margin: var(--smFont);
  display: flex;
  align-items: center;
}

.header-dropdown-user-details-container > div:last-child {
  margin-left: var(--xxsFont);
}

.header-dropdown-user-details-container > div:last-child > div {
  margin-bottom: calc(var(--xxxsFont) * 0.5);
}

.header-dropdown-user-details-container-faq {
  margin: var(--smFont);
}

.header-dropdown-user-details-container-faq > div:last-child > div {
  margin-bottom: calc(var(--xxxsFont) * 0.5);
}

.settings-tabs-settings .ant-select .ant-select-selector {
  border-right: 1px solid white !important;
  height: 50px;
  padding: 10px;
}

.two-factor-div {
  background: #f0f0ff;
  border-radius: 4px;
  padding: 20px 40px;
  display: flex;
  /* align-items: center; */
  flex-direction: row;
  margin-bottom: 20px;
  box-shadow: 0px 2px 10px -2px rgba(24, 39, 75, 0.12),
    0px 2px 4px -2px rgba(24, 39, 75, 0.08);
}
.two-factor-div .ant-avatar-circle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.two-factor-div .ant-avatar > img {
  width: 50%;
  height: 50%;
  filter: invert(48%) sepia(76%) saturate(6739%) hue-rotate(234deg)
    brightness(99%) contrast(101%);
}

.two-factor-div .ant-avatar-image {
  background: white;
}

.two-factor-div h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #040052;
  margin-top: 10px;
}

.two-factor-div p {
  max-width: 395px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;

  color: #4b487a;
}

.last-child-factor {
  width: 70%;
}

.two-factor-div button {
  font-weight: 700;
  height: 2.5rem;
  line-height: 22px;
  padding: 6px 16px;
  font-size: 12px;
  box-shadow: none;
  border-radius: 4px;
  border: none;
  background-color: #5a50fe !important;
  color: #ffffff !important;
  margin-top: 20px;
}

@media only screen and (max-width: 1024px) {
  .two-factor-div {
    padding: 10px;
    flex-direction: column;
  }
  .two-factor-div h1 {
    text-align: center;
  }

  .two-factor-div p {
    text-align: center;
  }
  .two-factor-div button {
    margin-top: 0px;
  }
  .last-child-factor {
    width: 65%;
  }
}

.mobileShow {
  display: none;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .mobileShow {
    display: inline;
  }
}

.mobileHide {
  display: inline;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .mobileHide {
    display: none;
  }
}

.ant-modal-content {
  border-radius: 8px !important;
}

.ant-modal-content .ant-modal-header {
  border-radius: 8px !important;
}

.timer-class {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1.5px;
  color: #5a50fe;
  margin: 0px;
  padding: 0px;
}

.styled-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(4, 0, 82, 0.3);
  z-index: 1000;
}

.welcome-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  height: auto;
  max-height: 100%;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  background-color: rgba(4, 0, 82, 0.3);
  z-index: 1000;
}

.welcome-modal-parent {
  position: relative;
  width: 90%;
  max-width: 1000px;
  height: auto;
  /* max-height: 90%; */
  background-color: white;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  z-index: 1001;
}

.welcome-modal-close {
  position: absolute;
  right: 0px;
  top: -40px;
  padding: 4px;
  width: 32px;
  height: 32px;
  background: #ffffff;
  border-radius: 6px;
  cursor: pointer;
  z-index: 1002;
}

.welcome-modal-container {
  padding: 0 60px;
  /* overflow-y: scroll; */
}

.welcome-modal-container .welcome-modal-p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #4b487a;
  max-width: 310.15px;
  margin: auto;
  margin-bottom: 50px;
}

.welcome-modal-container .welcome-modal-p-footer {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #4b487a;
  max-width: 311px;
  margin: auto;
  margin-top: 50px;
}

.welcome-modal-container .welcome-modal-p-footer a {
  color: #5a50fe;
}

.welcome-modal-card {
  padding: 16px 16px 40px 16px;
  max-width: 383px;
  background: #ffffff;
  border: 1px solid #e9ebed;
  border-radius: 8px;
  margin-bottom: 20px;
  cursor: pointer;
}

.welcome-modal-card:hover {
  border: 1px solid #5a50fe;
}

.welcome-modal-image {
  height: 150px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  max-width: 100%;
}

.welcome-modal-image img {
  width: 100%;
  object-fit: contain;
}

.welcome-modal-card h2 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 24px;
  text-align: center;
  color: #444052;
  margin-top: 25px;
  margin-bottom: 30px;
}

.welcome-modal-card h3 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #4b487a;
  max-width: 351px;
  margin: auto;
}

@media only screen and (max-width: 1024px) {
  .welcome-modal-overlay {
    height: 100%;
    top: 0;
    left: 0;
  }
  .welcome-modal-parent {
    padding: 10px;
    max-height: 90%;
    overflow-y: auto; /* Enable scrolling for overflowing content */
  }
  .welcome-modal-close {
    top: 0px;
  }
  .welcome-modal-container {
    padding: 0 10px;
  }
  .welcome-modal-image {
    height: 110px;
  }
}

.tabs-docs {
  padding: 70px;
}

.custom-tabs {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  border-bottom: 1px solid #cbcae5;
}

.custom-tabs .tabs {
  flex-grow: 1;
  padding: 10px 15px;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #74748a;
}

.custom-tabs .tabs img {
  width: 25px;
  margin-right: 5px;
}

.custom-tabs .tabs:hover {
  background-color: transparent;
  color: #5a50fe;
}

.custom-tabs .tabs.active {
  background-color: transparent;
  color: #5a50fe;
  border-bottom: 2px solid #5a50fe;
}

.custom-tabs .tabs:hover img {
  filter: invert(15%) sepia(50%) saturate(2654%) hue-rotate(237deg)
    brightness(101%) contrast(99%);
}

.custom-tabs .tabs.active img {
  filter: invert(15%) sepia(50%) saturate(2654%) hue-rotate(237deg)
    brightness(101%) contrast(99%);
}

.custom-tab-pane {
  padding: 20px 0 0 0;
}

.custom-tab-pane {
  display: block;
}

@media only screen and (max-width: 1024px) {
  .tabs-docs {
    padding: 10px;
  }
  .custom-tabs {
    flex-direction: row;
  }
  .custom-tabs .tabs {
    flex: unset;
  }
}

.custom-step-card {
  padding: 32px 32px 32px 16px;
  /* height: 520px; */
  overflow-y: scroll;
  background: #f8f9fa;
  border-radius: 12px;
}

.custom-modal__tab p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #74748a;
  margin-left: 20px;
}

.custom-modal__tab {
  margin-bottom: 20px;
  /* padding-bottom:20px;
  border-bottom: 1px solid #E9EBED; */
}

.custom-modal__tab .p-count {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  background: #ffffff;
  border: 1px solid #e9ebed;
  border-radius: 47px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 1px;
  color: #74748a;
  margin-top: -8px;
}

.custom-modal__tab.active p {
  color: #040052;
}

.custom-modal__tab.active .p-count {
  background: #5a50fe;
  border: none;
  color: #ffffff;
}

.custom-modal__tab h3 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 1.5px;
  color: #74748a;
}

.custom-modal__tab.completed p {
  color: #74748a;
}

.push-to-right {
  width: 60px;
}

.custom__progress-bar {
  width: 60px;
  height: 3px;
  background-color: #e9ebed;
  border-radius: 10px;
  position: relative;
}

.custom__progress-bar .progress-step {
  position: absolute;
  width: 20px;
  height: 3px;
  border-radius: 10px;
  background-color: #5a50fe;
}

.on-top {
  position: absolute;
  z-index: 1000;
  right: 0px;
  width: 42px;
  height: 56px;
  padding: 17px 5px 5px 10px;
  cursor: pointer;
}

.custom__sub-tab::before {
  content: "\2022"; /* Bullet character */
  margin-right: 26px;
  margin-left: 10px;
  font-size: 1.5em;
}

.custom__sub-tab::before {
  color: #d8d6ff;
}

.custom__sub-tab {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #4b487a;
  margin-bottom: 10px;
}

.custom-hr {
  width: 367px;
  margin-left: 45px;
}

.custom__sub-tab.active {
  color: #5a50fe;
}

.custom__sub-tab.active::before {
  color: #5a50fe;
}

.custom__sub-tab.completed::before {
  color: #128a11;
}

.onboarding-card-form-details {
  height: 460px;
  overflow-y: scroll;
  border-bottom: 1px solid #e9ebed;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.onboarding-card-form-footer {
  text-align: end;
}

.custom-modal__close-button {
  padding: 12px 16px;
  background: #5a50fe;
  border-radius: 12px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 15px;
}

.custom-modal__close-button:focus {
  outline: none;
  border: none;
}

.custom-modal__close-button:disabled {
  background: #e2e0ff;
}

.meta-map-onboard {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.meta-map-onboard img {
  margin-bottom: 40px;
}

.meta-map-onboard h1 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 24px;
  color: #444052;
  margin-bottom: 25px;
}

.meta-map-onboard p {
  max-width: 282px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #4b487a;
  margin: auto;
}

.business-form-fields {
  padding: 0 100px;
}

.pin-code-div {
  padding: 0 250px 0 0;
}

.business-form-fields h1 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 24px;
  color: #444052;
  margin-bottom: 15px;
}

.business-form-fields h2 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1px;
  color: #444052;
  margin-top: -5px;
  margin-bottom: 10px;
}

.business-form-fields p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #55555e;
  margin-bottom: 20px;
}

.business-form-fields .label-form {
  display: block;
  margin-bottom: 5px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444052;
}

.business-form-fields .reg-input {
  margin-bottom: 10px;
  padding: 10px 20px;
  height: 50px;
  font-size: 16px;
  border: 1px solid #cbcae5;
  border-radius: 8px;
  width: 100%;
  color: #6f6e80;
}

.business-form-fields .reg-input:focus {
  outline: none;
  border: 1px solid #5a50fe;
  box-shadow: none;
}

.business-form-fields .reg-input-area {
  margin-bottom: 10px;
  padding: 10px;
  height: 112px;
  font-size: 14px;
  border: 1px solid #cbcae5;
  border-radius: 8px;
  width: 100%;
  color: #6f6e80;
}

.business-form-fields .reg-input-area:focus {
  outline: none;
  border: 1px solid #5a50fe;
  box-shadow: none;
}

.business-form-fields .note-business {
  background: #fcf6de;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 20px;
  max-width: 416px;
}

.business-form-fields .note-business h2 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #4b487a;
}

@media only screen and (max-width: 1024px) {
  .business-form-fields {
    padding: 0;
  }
  .pin-code-div {
    padding: 0 100px 0 0;
  }

  .custom-hr {
    width: 240px;
  }
  .custom-step-card {
    padding: 16px;
  }
}

.welcome-container-main {
  height: 450px;
  overflow-y: scroll;
  border-bottom: 1px solid #e9ebed;
}

.welcome-container-main .row {
  margin: 0 !important;
}

.welcome-container-main-h1 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 24px;
  color: #444052;
}

.welcome-container-main-h2 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4b487a;
  margin-bottom: 40px;
}

.welcome-container-footer p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444052;
  margin-top: 20px;
}

.docs-welcome p {
  max-width: 642.73px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4b487a;
  margin-bottom: 20px;
}

.docs-welcome h3 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #040052;
}

.welcome-container-footer p a {
  color: #5a50fe;
}

.accordion-item-other img {
  width: 20px;
  margin-top: -8px;
}

.accordion-item {
  margin-left: 20px;
}

.accordion-item img {
  width: 20px;
}

.accordion-item-title {
  margin-left: 20px;
}

.accordion-item-title-other {
  margin-left: 20px;
}

.accordion-item-title h1 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #444052;
}

.accordion-item-title-other h1 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444052;
}

.accordion-item-title p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #444052;
}

.accordion-item-content {
  margin-left: 40px;
}

.accordion-item-content p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444052;
}

.accordion-item-content-other {
  margin-left: 40px;
}

.accordion-item-content-other p {
  /* max-width: 548.59px; */
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4b487a;
}

.business-form-fields .reg-select {
  height: 50px !important;
}

.reg-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  background-image: url("../images/chevron_down_new.svg");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 10px;
  padding: 10px 20px;
  height: 48px;
  font-size: 14px;
  border: 1px solid #cbcae5;
  border-radius: 8px;
  width: 100%;
  color: #6f6e80;
}

.reg-select::-ms-expand {
  display: none;
}

.reg-select:focus {
  outline: none;
  border: 1px solid #5a50fe;
  box-shadow: none;
}

.form__select_flag button {
  height: 56px;
  border: 1px solid #cbcae5;
  border-radius: 8px;
  color: #6f6e80;
  outline: none;
  padding: 0 20px 0 20px;
}

.welcome-container-main .form__select_flag button {
  height: 48px;
}

.banktransfer-others .input-card {
  width: 100%;
}

.banktransfer-others .input-card .ant-input-affix-wrapper {
  padding: 4px 16px 4px 16px;
  border-radius: 8px;
  background-color: #f4f5f6;
  height: 40px;
}

.banktransfer-others .input-card .ant-input-affix-wrapper-focused {
  border-color: #5a50fe; /* Your desired border color */
  box-shadow: none;
}

.banktransfer-others .input-card .ant-input-affix-wrapper:hover {
  border-color: #5a50fe !important; /* Your desired border color */
  box-shadow: none;
}

.banktransfer-others .input-card .ant-input {
  background-color: #f4f5f6;
  height: 32px !important;
}

.cards-sub-title {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  color: #4b487a;
}

.cards-sub-title span {
  color: #5a50fe;
  font-weight: 700;
  font-size: 14px;
}

.card-wallet-card {
  padding: 24px 24px 20px 24px;
  border-radius: 12px;
  border: 1px solid #e9ebed;
  margin-top: 20px;
}

.card-wallet-card h1 {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  color: #444052;
}

.card-wallet-card p {
  font-family: "DM Sans";
  font-size: 23px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  color: #040052;
}

.card-table-h1 h1 {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  color: #55555e;
}

.general-table-filters .ant-dropdown-trigger {
  background-color: transparent;
  border: none;
}

.general-table-filters .ant-btn-default {
  background-color: #5a50fe;
  color: white;
}

.general-table-filters .ant-btn-icon-only {
  background-color: #e5e5e5 !important;
  border-radius: 0px 4px 4px 0px !important;
  color: #040052 !important;
}

.custom-table-component {
  border-radius: 8px;
  border: 1px solid #e9ebed;
}

.custom-table-component h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.custom-table-component .ant-table-thead > tr > th {
  border-bottom: none;
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  color: #444052;
}

.custom-table-component .ant-table-row:hover {
  background-color: #ecebff !important;
}

.custom-table-component .ant-table-row:nth-child(odd) {
  background-color: #f8f8f9;
}

.custom-table-component .ant-table-row > td {
  border-bottom: 1px solid #e9ebed;
}

.custom-table-component .ant-table-tbody > tr > td {
  font-family: "DM Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  color: #4b487a;
}

.custom-table-component .ant-table-row {
  height: 20px !important; /* Adjust the height as needed */
}

.general-btn {
  padding: 7px 16px;
  border: none;
  cursor: pointer;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  background: #5a50fe;
  border-radius: 8px;
}

.general-btn:disabled {
  background-color: #e2e0ff;
}

.general-btn:focus {
  outline: none;
  border: none;
}

.general-btn-grey {
  padding: 7px 16px;
  border: none;
  cursor: pointer;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #5a50fe;
  background: #e9ebed;
  border-radius: 8px;
}

.general-btn-grey:disabled {
  background-color: #e2e0ff;
}

.general-btn-grey:focus {
  outline: none;
  border: none;
}

.header-dropdown-user-container-action-card {
  width: 150px;
  padding: 8px 6px;
  border-radius: 6px;
}

.header-dropdown-user-container-action-card p {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  margin: 0px;
}

.header-dropdown-user-container-action-card .ant-dropdown-menu-item:hover {
  background-color: #ecebff;
  border-radius: 4px;
}

.header-dropdown-user-container-action-card .ant-dropdown-menu-item-active {
  background-color: transparent;
}

.virtual-card-design {
  /* padding:20px; */
  border-radius: 16px;
}

.v-card-header h1 {
  font-family: "DM Sans";
  font-size: 23px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  color: #040052;
  margin: 0;
}

.v-card-header p {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  color: #74748a;
  margin: 0;
}

.v-card-header h2 {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  color: #55555e;
  margin: 0;
}

.v-card-show h1 {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  color: #4b487a;
  margin: 0;
}

.virtual-card-design h2 {
  font-family: "DM Sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0px;
  color: white;
  margin: 0;
}

.virtual-card-design h1 {
  font-family: "DM Sans";
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0px;
  color: white;
  margin: 0;
}

.virtual-card-design p {
  font-family: "DM Sans";
  font-size: 15px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 1.2120000123977661px;
  color: #abcdf6;
  margin: 0;
}

.virtual-card-design h3 {
  font-family: "DM Sans";
  font-size: 17px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  margin: 0;
  color: white;
}

.virtual-card-design h4 {
  font-family: "DM Sans";
  font-size: 19px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0px;
  margin: 0;
  color: white;
}

.v-card-actions p {
  font-family: "DM Sans";
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1px;
  color: #444052;
  margin: 0;
}

.card-balances {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #e9ebed;
}

.card-balances h1 {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  color: #444052;
  margin: 0;
}

.card-balances p {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  color: #040052;
  margin: 0;
  margin-top: 5px;
}

.custom-padding {
  padding: 40px 80px;
}

@media only screen and (max-width: 1024px) {
  .custom-padding {
    padding: 10px;
  }
}

.card-creator {
  font-family: DM Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  margin: 0;
  color: #74748a;
}

.card-creator span::before {
  content: "• ";
  font-size: 16px; /* You can adjust the size as needed */
}

.card-numbers-details h1 {
  font-family: DM Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  margin: 0;
  color: #444052;
}

.card-numbers-details p {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  margin: 0;
  color: #4b487a;
}

.position-bottom-card {
  position: absolute;
  bottom: -7px;
  left: 37px;
}

.v-card-table {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  color: #444052;
}

.v-card-time {
  font-family: DM Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  color: #55555e;
}

.paginate-total {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  color: #4b487a;
}

.paginate-total span {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  color: #444052;
}

.custom-table-component .input-card .ant-input-affix-wrapper {
  padding: 4px 16px 4px 16px;
  border-radius: 8px;
  background-color: #f4f5f6;
  height: 32px;
}

.custom-table-component .input-card .ant-input-affix-wrapper-focused {
  border-color: #5a50fe; /* Your desired border color */
  box-shadow: none;
}

.custom-table-component .input-card .ant-input-affix-wrapper:hover {
  border-color: #5a50fe !important; /* Your desired border color */
  box-shadow: none;
}

.custom-table-component .input-card .ant-input {
  background-color: #f4f5f6;
  height: 25px !important;
}

.custom-table-component .ant-pagination-prev .ant-pagination-item-link,
.custom-table-component .ant-pagination-next .ant-pagination-item-link {
  background-color: #f4f6f7;
}

.custom-table-component .ant-pagination-prev,
.custom-table-component .ant-pagination-next {
  min-width: 24px;
  height: 24px;
  border-radius: 4px;
  line-height: 24px;
  color: #444052;
}

.custom-table-component .ant-pagination-item-active {
  border-color: #5a50fe !important; /* Your desired border color */
}

.custom-table-component .ant-pagination-item {
  font-family: DM Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 1px;
  min-width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #e9ebed;
}

.filter-btn-general {
  border: none;
  background-color: transparent;
  outline: none;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  color: #040052;
  cursor: pointer;
}

.filter-btn-general:focus {
  outline: none;
  border: none;
}

.filter-options-general {
  padding: 0px 8px 0px 8px;
  border-radius: 16px;
  border: 1px solid #cbcae5;
  background-color: #f4f6f7;

  font-family: DM Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 1px;
  color: #74748a;
  cursor: pointer;
}

.filter-dropdown-table {
  position: absolute;
  top: 22px;
  left: 0px;
  z-index: 999;
  padding: 8px;
  border-radius: 6px;
  box-shadow: 0px 2px 8px 0px #0000000a;
  background-color: white;
}

.filter-options-general-white {
  padding: 0px 8px 0px 8px;
  border-radius: 16px;
  border: 1px solid #cbcae5;
  background-color: white;

  font-family: DM Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 1px;
  color: #4b487a;
}

.div-card-details[disabled] {
  pointer-events: none;
  opacity: 0.7;
}

.fees-card-area {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 10px;
}

.fees-card-area p {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #55555e;
  margin-left: 5px;
}

.fees-card-area h1 {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  color: #040052;
}

.fees-card-area-fund .line-now {
  position: absolute;
  left: 30px;
}

.fees-card-area-fund .fee-now {
  position: absolute;
  bottom: -50px;
  left: 17px;
}

.fees-card-area-fund p {
  position: absolute;
  bottom: -48px;
  left: 54px;
  font-family: DM Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  color: #55555e;
}

.banktransfer-others .form-group .label-form {
  display: block;
  margin-bottom: 5px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444052;
}

.banktransfer-others .form-group .input-row {
  display: flex;
  width: 100%;
}

.banktransfer-others .form-group .input-row input {
  flex: 1;
  margin-bottom: 10px;
  padding: 10px 20px;
  height: 50px;
  font-size: 16px;
  border: 1px solid #cbcae5;
  border-radius: 8px 0 0 8px;
  width: 100%;
  color: #6f6e80;
}

.banktransfer-others .form-group .input-row input:last-child {
  border-radius: 0 8px 8px 0;
  border-left: 0px;
}

.banktransfer-others .form-group .input-row input:focus {
  outline: none;
  border: 1px solid #5a50fe;
  box-shadow: none;
}

.banktransfer-others .form-group .reg-input {
  margin-bottom: 10px;
  padding: 10px 20px;
  height: 50px;
  font-size: 16px;
  border: 1px solid #cbcae5;
  border-radius: 8px;
  width: 100%;
  color: #6f6e80;
}

.banktransfer-others .form-group .reg-input:focus {
  outline: none;
  border: 1px solid #5a50fe;
  box-shadow: none;
}

.banktransfer-others .form-group .country-phone-input .drpdown-button {
  height: 50px !important;
}

.banktransfer-others .form-group .country-phone-input .phone-input {
  height: 50px !important;
}

.banktransfer-others .form__select_flag button {
  height: 50px;
}

.banktransfer-others .form-group .country-phone-input .drpdown-menu {
  width: 366px !important;
}

.banktransfer-others .submit-reg {
  width: 100%;
  padding: 10px;
  border: none;
  cursor: pointer;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  height: 56px;
  background: #5a50fe;
  border-radius: 12px;
}

.banktransfer-others .submit-reg:disabled {
  background-color: #e2e0ff;
}

.fund-card-btn .btn-btn-btn {
  background: #5a50fe;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #ffffff;
  outline: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  width: 100%;
}

.fund-card-btn .btn-btn-btn:disabled {
  opacity: 0.5;
}

.success-card-fund .grey-btn-btn {
  background: #f0f0f0;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  outline: none;
  border: none;
  padding: 10px;
  color: #5a50fe;
  width: 100%;
}

.success-card-fund h1 {
  font-family: "DM Sans";
  font-size: 23px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  color: #444052;
}

.success-card-fund p {
  font-family: "DM Sans";
  font-size: 18px;
  font-weight: 350;
  line-height: 27px;
  letter-spacing: 0px;
  max-width: 480px;
  margin: auto;
}

.billing-details {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  color: #040052;
}

.card-custom-date {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 9999;
  background-color: white;
  /* box-shadow: 0px 2px 8px 0px #0000000A; */
  box-shadow: 0px 4px 12px -3px #11182621;
  border-radius: 8px;
}

.custom-date-filter-options {
  background-color: #f8f9fa;
  padding: 20px;
  width: 188px;
  height: 480px;
  border-radius: 8px 0px 0px 8px;
}

.custom-date-filter-options h1 {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  color: #4b487a;
  margin-bottom: 12px;
}

.custom-date-filter-table {
  background-color: white;
  padding: 20px;
  height: 480px;
  border-radius: 0px 8px 8px 0px;
}

.react-datepicker__header {
  background-color: white !important;
  border-bottom: none !important;
  border-top-left-radius: 0.3rem;
  position: relative;
}

.react-datepicker {
  font-size: 0.8rem;
  background-color: white !important;
  color: #000;
  border: 1px solid white !important;
  border-radius: 0.5rem !important;
  display: inline-block;
  position: relative;
  padding: 0px !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--in-selecting-range {
  background-color: #5a50fe !important;
  border-radius: 5px !important;
  color: white !important;
  border: none !important;
}

.react-datepicker__day--in-range {
  background-color: #5a50fe !important;
  border-radius: 5px !important;
}

.reg-select-date {
  font-family: "DM Sans";
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  color: #444052;
  border: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  padding-right: 30px; /* Adjust the value as needed to move the icon closer */

  background: transparent;
  background-image: url("../images/Chevron_down.svg");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 1px;
}

.reg-select-date:focus {
  outline: none;
  border: none;
}

.reg-select-year {
  padding: 4px 16px 4px 16px;
  border-radius: 8px;
  background-color: #f8f9fa;
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
}

.reg-select-year:focus {
  outline: none;
  border: none;
}

/* .default-avatar {
  display: flex;
  justify-content: center;
  align-items:center;
} */

.custom-styles {
  background-color: #ecebff;
  border: 1px solid #cbcae5;
}

.card-transaction-details-styling h1 {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  color: #444052;
  margin: 0;
}

.card-transaction-details-styling p {
  font-family: "DM Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  color: #444052;
  margin: 0;
}

.card-transaction-details-styling h2 {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  color: #74748a;
  margin-bottom: 10px;
}

.card-transaction-details-styling h3 {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  color: #040052;
  margin-bottom: 10px;
}

.card-transaction-details-styling a {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  color: #5a50fe;
}

.for-notifications {
  border: 1px solid #cbcae5;
  padding: 12px;
  border-radius: 8px;
  background-color: #f8f7ff;
}

.for-notifications p {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #5a50fe;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.for-notifications .cls-btn {
  width: 30px;
  cursor: pointer;
}

.ev-primary-btn {
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  background: #5a50fe;
  border-radius: 8px;
}

.ev-primary-btn:disabled {
  background-color: #e2e0ff;
}

.ev-primary-btn:focus {
  outline: none;
  border: none;
}

.add-ip-address {
  padding: 20px;
  max-width: 550px;
  margin: auto;
}

.add-ip-address img {
  width: 50px;
}

.add-ip-address h1 {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 700;
}

.add-ip-address p {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  max-width: 434px;
  margin: auto;
}

.add-ip-address h2 {
  color: #966602;
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.add-ip-address h2 span {
  font-weight: 700;
}

.custom-radio-group-general {
  display: flex;
  flex-direction: column;
}

.custom-radio-general {
  position: relative;
  padding-left: 54px;
  padding-top: 16px;
  cursor: pointer;
  user-select: none;
  height: 100px;
  font-size: 16px;
  border: 1px solid #cbcae5;
  border-radius: 8px;
  /* max-width: 550px; */
  margin: auto;
  /* margin-bottom: 15px; */
}

.custom-radio-general input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-radio-general .checkmark {
  position: absolute;
  top: 18px;
  left: 20px;
  height: 20px;
  width: 20px;
  /* background-color: #ccc; */
  border: 1px solid #444052;
  border-radius: 50%;
}

.custom-radio-general:hover input ~ .checkmark {
  border: 1px solid #5a50fe;
}

.custom-radio-general input:checked ~ .checkmark {
  border: 1px solid #5a50fe;
}

.custom-radio-general .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-radio-general input:checked ~ .checkmark:after {
  display: block;
}

.custom-radio-general .checkmark:after {
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #5a50fe;
}

.custom-radio-general .biz-title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #444052;
}

.custom-radio-general .biz-desc {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4b487a;
  max-width: 437px;
}

.custom-radio-group-general .ant-tag-green {
  color: #0f730e !important;
  background: #e2fccc !important;
  border-color: #99e558 !important;
  font-family: "DM Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  border-radius: 4px !important;
  width: 110px;
}

.tag-style .ant-tag-green {
  color: #0f730e !important;
  background: #e2fccc !important;
  border-color: #99e558 !important;
  font-family: "DM Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  border-radius: 24px !important;
  width: 55px;
}

.warning-alert {
  background-color: #fcf9ed;
  padding: 20px;
  border-radius: 4px;
  max-width: 361px;
  margin: auto;
}

.warning-icon {
  width: 20px !important;
}

.blink {
  animation: blinker 2s step-start infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.text-area-additional-info {
  height: 152px !important;
}

.capitalize-text {
  text-transform: capitalize;
  display: flex;
  align-items: center;
}

.create-card-container {
  width: 480px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.side-nav {
  @media screen and (max-width: 480px) {
    position: absolute;
    height: 100%;
  }
}

.modal-wrapper {
  display: flex;
  gap: 12px;
  /* width: 480px; */
  /* padding: 40px; */

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  .banktransfer-others {
    width: auto;
  }

  & > div {
    flex: none;
  }
}
.wallet-skeleton {
  width: 100px;

  ul {
    margin-bottom: 0;
  }
}

.clickable-row {
  cursor: pointer;
}
