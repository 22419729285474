@import "../../../scss/variables";

.pill {
  padding: 8px;
  border-radius: 24px;
  background-color: $success-green;
  font-size: 12px;
  color: $white;
  letter-spacing: 1px;
  height: 24px;
  display: flex;
  align-items: center;
}
