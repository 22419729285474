.dashboard-wallets-list-outside-container {
    position: relative;
    margin-top: -0.5rem;
}

.dashboard-wallets-list-outside-container>a {
    position: absolute;
    right: 0.25rem;
    top: 0.75rem;
    z-index: 10;
    color: var(--primaryColor);
    font-weight: 500;
    font-size: 0.95rem;
}

.dashboard-wallets-list-outside-container .ant-tabs-nav::before {
    border: none;
}

.dashboard-wallets-list-outside-container .ant-tabs-nav-wrap {
    margin-bottom: 0rem;
}

.dashboard-wallets-list-outside-container .ant-tabs-tab>div {
    font-size: 1rem;
    padding: 0 0.5rem;
    color: var(--subTitleColor);
}

.dashboard-wallets-list-outside-container .ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar {
    height: 0.25rem;
    background-color: var(--primaryColor);
}

.wallet-card-in-list {
    height: 100%;
    padding: 0;
    height: 4.25rem;
}

.wallet-card-in-list>div {
    border-radius: 0.25rem;
    position: relative;
    padding-top: 1rem;
    background-color: var(--whiteColor);
    z-index: 1;
    height: 6rem;
    overflow: hidden;
}

.wallet-card-in-list>div:hover {
    box-shadow: var(--walletInListHoverBoxShadow);
}

.wallet-card-in-list>div>div {
    padding: 0.25rem;
}

.wallet-card-in-list>div>div:first-child {
    padding: 0.5rem 1.5rem;
    background-color: var(--sidebarSubMenuBackgroundColor);
    flex-direction: row;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: none;
    border-radius: 0.25rem 0.25rem 0 0;
}

.wallet-card-in-list>div>div:first-child>div:first-child {
    flex-grow: 1;
}

.wallet-card-in-list>div>div:first-child>div:not(:first-child) {
    margin-left: 1rem;
}

.wallet-card-in-list>div>div:nth-child(2)>a>div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.wallet-card-in-list>div>div:nth-child(2)>a>div>div {
    flex-grow: 1;
    margin-left: 1rem;
}

.wallet-card-in-list>div>div:nth-child(2)>a>div>div h4 {
    font-weight: 600;
    color: var(--titleColor);
    font-size: 1rem;
    margin: 0 0 0.15rem 0;
}

.wallet-card-in-list>div>div:nth-child(2)>a>div>div p {
    color: var(--subTitleColor);
    margin: 0;
}

.wallet-card-in-list>div>div:last-child {
    flex-direction: column;
    padding-top: 0;
    margin-top: 0rem;
    display: none;
}

.wallet-card-in-list:hover>div>div:last-child h4 {
    margin: 0.25rem 0 1.25rem 0;
    font-size: 1rem;
    color: var(--blackColor);
}

.wallet-card-in-list:hover>div>div:last-child ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    flex-direction: column;
}

.wallet-card-in-list:hover>div>div:last-child ul li {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.wallet-card-in-list:hover>div>div:last-child ul li:first-child {
    margin-bottom: 1rem;
}

.wallet-card-in-list:hover>div>div:last-child ul li>div:first-child {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--bodyTextColor);
    font-size: 1rem;
}

.wallet-card-in-list:hover>div>div:last-child ul li>div:first-child img {
    margin-right: 0.5rem;
    width: 1.5rem;
}

.wallet-card-in-list:hover>div>div:last-child ul li>div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}

.wallet-card-in-list:hover>div>div:last-child ul li>div:last-child p {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    color: var(--titleColor);
}

.wallet-card-in-list:hover>div>div:last-child ul li>div:last-child span {
    font-size: 0.85rem;
    color: var(--positiveChangeColor);
}

.wallet-card-in-list:hover>div>div:last-child ul li>div:last-child span:not(.negative-percentage-change)::before {
    content: "+"
}

.wallet-card-in-list:hover>div>div:last-child ul li>div:last-child span.negative-percentage-change {
    color: var(--negativeChangeColor);
}

@media only screen and (min-width: 992px) {
    .wallet-card-in-list>div {
        padding-top: 1rem;
        height: 5.5rem;
    }
}

@media only screen and (min-width: 1200px) {
    dashboard-wallets-list-outside-container {
        margin-top: 0;
    }
    .dashboard-wallets-list-outside-container>a {
        right: 1rem;
        top: 1rem;
        font-size: 1rem;
        font-weight: 600;
    }
    .dashboard-wallets-list-outside-container .ant-tabs-nav-wrap {
        margin-bottom: 0.5rem;
    }
    .dashboard-wallets-list-outside-container .ant-tabs-tab>div {
        font-size: 1.05rem;
        padding: 0.15rem 0.5rem;
    }
    .dashboard-wallets-list-outside-container .ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar {
        height: 0.25rem;
    }
    .ant-tabs-content>.ant-tabs-tabpane>.ant-row {
        margin-left: -0.5rem !important;
        margin-right: -0.5rem !important;
    }
    .wallet-card-in-list>div>div {
        padding: 1.5rem;
    }
    .wallet-card-in-list {
        padding: 0 0.5rem;
        height: 8rem;
    }
    .wallet-card-in-list>div {
        padding-top: 2rem;
        height: 8rem;
    }
    .wallet-card-in-list:hover>div {
        height: 18.5rem;
        z-index: 2;
    }
    .wallet-card-in-list:hover>div>div:first-child,
    .wallet-card-in-list:hover>div>div:last-child {
        display: flex;
    }
    .wallet-card-in-list>div>div:nth-child(2)>a>div>div h4 {
        font-size: 1.15rem;
        margin: 0 0 0.25rem 0;
    }
}