@import "../../scss/mixins";
@import "../../scss/variables";

.body {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 24px;

  @include breakpoint(large) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: ". middle .";
    padding: 80px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: fit-content;

  @include breakpoint(large) {
    grid-area: middle;
  }
}

.button {
  margin-top: 40px;
  width: 100%;
}

.qRCodeContainer {
  width: 175px;
  height: 175px;
  padding: 20px;
}

.qRCode {
  width: 100%;
  height: 100%;
}

.description {
  p {
    font-size: 14px;
    padding: 0;
    margin: 0;

    &:first-of-type {
      font-weight: bold;
      margin-bottom: 8px;
      font-weight: 16px;
    }
  }
}

.title {
  font-weight: bold;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.input {
  flex: 1;
}

.download {
  color: $eversend-blue;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 4px;
  text-decoration: none;
}

.downloadIcon {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
}

.tag {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: fit-content;
}
