/* App.css */
.custom-date-input {
  display: flex;
  align-items: center;
  width: 240px; /* Adjust the width as needed */
  height: 45px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #cbcae5;
}

.custom-date-input input {
  border: none;
  outline: none;
  width: 100%;
}

.icon {
  margin-right: 8px; /* Add space between the icon and input field */
}
