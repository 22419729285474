@import "../../../scss/variables";

.button {
  padding: 4px 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border: none;

  &.primary {
    background-color: $eversend-blue;
    color: $white;

    &:active {
      background-color: darken($eversend-blue, 35%);
    }

    &:hover {
      background-color: darken($eversend-blue, 30%);
    }

    &:disabled:hover {
      background-color: $primary-inactive;
    }
  }

  &.transparent {
    background-color: transparent;
  }

  &.small {
    padding: 8px 16px;
  }

  &.medium {
    padding: 12px 16px;
  }

  &:disabled {
    background-color: $primary-inactive;
    cursor: not-allowed;
  }

  .link {
    background-color: transparent;
    color: $eversend-blue;
    // text-decoration: underline;
    padding: 0;
    border: none;

    &:hover {
      // color: darken($link-color, 10%);
    }
  }
}
