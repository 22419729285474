@media only screen and (max-width: 767px) {
  .content-wrap {
    padding-right: 20px;
    padding-left: 20px;
  }
  .account-balance--card,
  .account-volume--card {
    margin-top: 1rem !important;
  }
  .account-value--card {
    padding: 40px 27px;
  }
  .account-balance--text span {
    font-size: 0.8125rem;
  }
  .recovery-form-wrap {
    min-width: 36.04%;
    max-width: 36.04%;
  }
}

@media only screen and (min-width: 991px) {
  .account-overview--wrap {
    flex-wrap: nowrap;
  }
  .account-balance--card,
  .account-value--card {
    margin-right: 1rem !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
}

@media only screen and (max-width: 991px) {
  .analytics-wrap {
    width: 100;
    flex-wrap: wrap;
  }
  .left-analytics {
    width: 100%;
    margin-top: 1.5rem;
  }

  .right-analytics {
    width: 100%;
  }
  .analytics-divider--wrap {
    display: none;
  }
  .header {
    width: 100%;
  }
  .overview-radion--btn {
    width: 100%;
    max-width: 100%;
    margin-top: 1rem;
  }
}

/* @media only screen and  {
  
} */
