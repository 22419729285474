.ant-layout-sider {
    border: none !important;
    box-shadow: var(--sidebarBoxShadow);
    border-radius: 0 !important;
    z-index: 4;
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu:not(.ant-menu-submenu-open) .ant-menu-submenu-arrow {
    transform: rotate(-90deg);
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu:hover .ant-menu-submenu-arrow {
    color: var(--primaryColor);
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-item,
.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu>.ant-menu-submenu-title,
.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu ul li {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    height: 3rem;
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-item,
.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu>.ant-menu-submenu-title {
    height: 3.25rem;
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-item>span>a,
.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu>.ant-menu-submenu-title>span>a,
.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu>ul>li>span>a {
    color: var(--sidebarMenuColor);
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-item:active,
.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu>div:active,
.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu>ul>li:active {
    background-color: var(--dropdownMenuSelectedRadioButtonBackgroundColor);
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-item.ant-menu-item-selected {
    background-color: var(--dropdownMenuSelectedRadioButtonBackgroundColor);
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-item.ant-menu-item-selected>span>a {
    color: var(--titleColor);
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu ul li a {
    position: relative;
    margin-left: 1.5rem;
    color: var(--sidebarMenuColor);
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu ul li a.active {
    color: var(--primaryColor);
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu ul li a::after {
    content: "";
    height: 0.4rem;
    width: 0.4rem;
    border-radius: 0.7rem;
    background-color: var(--sidebarMenuColor);
    left: -0.985rem;
    top: 1.05rem;
    position: absolute;
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu ul li a.active::after {
    content: "";
    background-color: var(--primaryColor);
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-item.ant-menu-item-selected::after,
.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu ul li::after {
    content: "";
    left: 0;
    right: unset;
    border-left: 0.5rem solid var(--primaryColor);
    border-right: none;
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-item:nth-child(4),
.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-item:nth-child(6) {
    margin-top: 2.5rem;
    overflow: visible;
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-item:nth-child(4)::before,
.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-item:nth-child(6)::before {
    content: "";
    border-top: 1px solid var(--chartLoaderBarColor);
    position: absolute;
    top: -1.25rem;
    left: 1.75rem;
    right: 1.75rem;
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-item>svg,
.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu>div.ant-menu-submenu-title>svg {
    margin-right: 0.5rem;
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu.ant-menu-submenu-open ul {
    background-color: var(--sidebarSubMenuBackgroundColor);
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu ul li svg {
    margin-left: 0.5rem;
    margin-right: 0.35rem;
}

.sidebar-profile-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1.5rem 1.25rem -0.5rem 1.25rem;
}

.sidebar-profile-container>span:last-child {
    font-size: 1.1rem;
    font-weight: 500;
    margin-top: 0.5rem;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    color: var(--titleColor);
}

.sidebar-profile-container .ant-avatar-circle {
    background-color: var(--primaryColor) !important;
    height: 3.25rem;
    width: 3.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 0;
}