@import "../../../scss/mixins";

.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px;
  border-radius: 12px 12px 0 0;
  z-index: 1000;
  background-color: rgb(255, 255, 255);

  @include breakpoint(large) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 40px 64px;
  }
}

.header {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin-bottom: 16px;
  height: fit-content;

  @include breakpoint(large) {
    margin-bottom: 32px;
  }
}

.title {
  display: inline-block;
  padding: 16px 0;
  font-size: 23px;
  font-weight: bold;

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
}

.back {
  color: #74748a;
  display: flex;
  align-items: center;
  position: fixed;
  top: 24px;
  z-index: 1000000000000;
  isolation: isolate;
  padding: 0;
  height: 24px;
  margin-right: 0;

  &:hover,
  &:focus {
    color: inherit;
  }

  @include breakpoint(large) {
    position: static;
  }
}

.steps {
  margin: 24px 0;
}

.step {
  min-height: auto;
  &:not(:first-of-type) {
    margin: 8px 0;
  }
}

.content {
  background-color: #ffffff;
  overflow-y: auto;
  padding-top: 16px;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  margin-top: 16px;
  height: calc(100% - 128px);

  @include breakpoint(large) {
    grid-template-columns: repeat(2, 1fr);
    margin-right: 0;
    height: auto;
  }
}

.aside {
  box-shadow: none;
  background-color: #ffffff;
  position: relative;
  flex: none;
  padding: 0;
  width: auto;

  @include breakpoint(large) {
    width: 352px;
  }
}
