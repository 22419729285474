@import "../../../scss/mixins";

.wrapper {
  font-size: 14px;
  width: 100%;
  display: flex;
  flex-direction: row;

  @include breakpoint(large) {
    gap: 32px;
    flex-direction: column;
  }
}

.stepWrapper {
  display: flex;
  width: 100%;
  align-items: baseline;
  flex: 1;
  border: none;
  background: transparent;
  padding: 4px 0;
  position: relative;
  cursor: none;

  &:not(:first-of-type) {
    @include breakpoint(large) {
      position: static;
    }
  }

  &:last-of-type {
    flex: 0;
  }

  &:hover {
    .step:not(.active):not(.completed) {
      border-color: #5a50fe;
      color: #5a50fe;
    }

    .title:not(.activeTitle) {
      color: #5a50fe;
    }
  }
}

.stepWrapper:last-child .step::after {
  display: none;
}

.clickable {
  cursor: pointer;
}

.step {
  border: 1px solid #e9ebed;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint(large) {
    position: relative;
    margin-right: 16px;
  }
}

.step::after {
  position: absolute;
  content: "";
  right: 16px;
  height: 4px;
  width: calc(100% - 62px);
  display: block;
  top: 50%;
  border-radius: 2px;
  background: #e9ebed;

  @include breakpoint(large) {
    width: 4px;
    height: 16px;
    bottom: -30px;
    top: unset;
  }
}

.step:last-child {
  content: none;
}

.completed {
  background-color: #128a11;
  color: #ffffff;
}

.active {
  background-color: #5a50fe;
  color: #ffffff;
}

.activeTitle {
  font-weight: bold;
}

.title {
  text-overflow: ellipsis;
  margin: 0;
  display: none;

  @include breakpoint(large) {
    display: block;
  }
}
