.overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(4, 0, 82, 0.3);
  z-index: 1000;
  height: 100%;
  width: 100%;
}

.header {
  position: relative;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 10vh;
  z-index: 1000;

  @media screen and (max-width: 768px) {
    height: 0;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: white;
  }
}

.close {
  position: absolute;
  right: 10px;
  top: 40px;
  cursor: pointer;
  padding: 4px;
  width: 32px;
  height: 32px;
  background: #ffffff;
  border-radius: 6px;
  padding: 0;

  @media screen and (max-width: 768px) {
    position: absolute;
    top: 24px;
    height: 24px;
    width: 24px;
    right: 24px;
  }
}

.body {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 90vh;
  // height: -webkit-fill-available;
  border-radius: 12px 12px 0 0;
  z-index: 1000;
  background-color: #fff;
  overflow-y: auto;

  @media screen and (max-width: 768px) {
    border-radius: 0;
    height: calc(100% - 32px);
  }
}
