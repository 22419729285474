.card {
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #e4eaf2;
  background: #fbf0ff;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;

  p {
    color: #55555e;
    padding: 0;
    margin: 0 0 4px 0;
  }

  .button.button {
    padding: 4px 10px;
    width: fit-content;
  }
}

.container {
  display: flex;
  flex-direction: column;
}

.icon {
  width: 32px;
  height: 32px;
}
