$breakpoints: (
  small: 0,
  medium: 640px,
  smallDesktop: 860px,
  large: 1024px,
  xlarge: 1220px,
  xxlarge: 1440px,
);

@mixin breakpoint($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    /* stylelint-disable scss/operator-no-newline-before */
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
      + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
