@import "../../../scss/variables";

.container {
  position: relative;

  .copy {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.textContainer {
  padding: 6px 16px 8px 16px;
  background-color: $light-grey;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.copyIcon {
  path {
    fill: $eversend-blue;
  }
}
