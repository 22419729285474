.container {
  padding: 24px;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  gap: 8px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
