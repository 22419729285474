@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap);
body {
    margin: 0;
    font-family: "eFont", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ant-avatar-string {
    font-family: "eFont", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}
:root {
    --pageBackgroundColor: #ffffff;
    --primaryColor: #5a50fe;
    --blackColor: #000000;
    --secondaryButtonColor: #eeeeee;
    --successColor: #48d38a;
    --negativeColor: #f7685b;
    --textColor: #707070;
    --whiteColor: #ffffff;
    --offWhite: #f9f9f9;
    --offWhiteTrans: rgba(219, 220, 224, 0.3);
    --borderOff: rgba(219, 220, 224, 0.5);
    --offText: rgba(0, 0, 0, 0.5);
    --bgOffset: #f4f4f4;
    --bgGrey: #e5e5e5;
    --placeHolder: #979797;
    --offBlue: #eff1ff;
    --boxShadow: rgba(64, 25, 109, 0.07);
    --ctaOffBlue: rgba(64, 25, 109, 0.3);
    --overlay: rgba(64, 25, 109, 0.2);
    --successColorTrans: #dcffec;
    --lightBlue: #5a50fe;
    --textColorLightGrey: #cacaca;
    --menuColor: #5a50fe;
    --textColorTwo: #555555;
    --boldTextColor: #1c223c;
    --purpleLightColor: #ccccf9;
    --dashboardCardBoxShadow: rgba(50, 50, 93, 0.15) 0px 3px 4px -1px, rgba(0, 0, 0, 0.2) 0px 1.5px 3.5px -1.5px;
    --sidebarBoxShadow: rgba(50, 50, 93, 0.25) 0px 3px 6px -1px, rgba(0, 0, 0, 0.3) 0px 1.5px 3.5px -1.75px;
    --headerBoxShadow: rgba(50, 50, 93, 0.15) 0px 2px 5px -1px, rgba(0, 0, 0, 0.15) 0px 1px 3px -1px;
    --dashboardCardFooterbackgroundColor: #f5f5f6;
    --titleColor: #040052;
    --subTitleColor: #55555E;
    --bodyTextColor: #4B487A;
    --positiveChangeColor: #008A00;
    --negativeChangeColor: #FD66A3;
    --chartLoaderBarColor: #E5E5E5;
    --dropdownMenuBoxShadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    --dropdownMenuSectionBackgroundColor: #F4F6F7;
    --dropdownMenuSelectedRadioButtonBackgroundColor: #ECEBFF;
    --dropdownMenuTitleColor: #444052;
    --dropdownMenuListBorderColor: #D9DBDD;
    --lightPrimaryColor: #CBCAE5;
    --sidebarMenuColor: #616474;
    --sidebarSubMenuBackgroundColor: #F6F6F7;
    --walletInListHoverBoxShadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    --xxxsFont: 0.65rem;
    --xxsFont: 0.75rem;
    --xsFont: 0.85rem;
    --smFont: 0.95rem;
    --mdFont: 1.05rem;
    --lgFont: 1.15rem;
    --xlFont: 1.25rem;
    --xxlFont: 1.35rem;
    --xxxlFont: 1.45rem;
    --bgLightGrey: #f6f9fe;
    --inputHeight: 2.5rem;
}
/* Anti Alias */

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: none !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  font-size: 16px;
  color: var(--textColor);
  font-weight: 400;
  font-family: "eFont", sans-serif;
  background-color: #fbfaff;
  font-size: 1rem;
  min-height: 100vh;
  min-height: 100dvh;
}

a {
  text-decoration: none;
  color: inherit;
}

input {
  /* Remove First */
  -webkit-appearance: none;
  appearance: none;
}

input::placeholder,
input:placeholder-shown {
  color: var(--textColor);
  line-height: 29px;
  font-weight: 400;
}

img {
  border-style: none;
}

p,
li {
  font-size: 1rem;
  line-height: 1.5;
}

/* FONT TYPE */

@font-face {
  font-family: "eFont";
  font-style: normal;
  font-weight: 400;
  src: local("eFont"),
    url(/static/media/GTWalsheimPro-Regular.1668df1c.woff) format("woff"),
    url(/static/media/GTWalsheimPro-Regular.3579e898.eot) format("eot"),
    url(/static/media/GTWalsheimPro-Regular.1fd3cd7a.ttf) format("ttf"),
    url(/static/media/GTWalsheimPro-Regular.6aff6f11.woff2) format("woff2");
}

@font-face {
  font-family: "eFont";
  font-style: normal;
  font-weight: bold;
  src: local("eFont"), url(/static/media/GTWalsheimPro-Bold.6ead5fc9.woff) format("woff"),
    url(/static/media/GTWalsheimPro-Bold.2dbf49e9.ttf) format("ttf");
}

@font-face {
  font-family: "eFont";
  font-style: normal;
  font-weight: 500;
  src: local("eFont"),
    url(/static/media/GTWalsheimPro-Medium.56bd75a2.woff) format("woff"),
    url(/static/media/GTWalsheimPro-Medium.14369c3a.eot) format("eot"),
    url(/static/media/GTWalsheimPro-Medium.ad6b035f.woff2) format("woff2"),
    url(/static/media/GTWalsheimPro-Medium.bc56cfc1.ttf) format("ttf");
}

/* FONT SIZE */

.text-md {
  font-size: 1.625rem;
  line-height: 1.4;
}

.text-smd {
  font-size: 1.125rem !important;
}

.text-sm {
  font-size: 0.8125rem !important;
}

.text-xlxs {
  font-size: 0.75rem;
}

.text-xs {
  font-size: 0.69rem !important;
}

/* FONT WEIGHT */

.text-normal {
  font-weight: 400 !important;
}

.text-semi-bold {
  font-weight: 600 !important;
}

.text-bold {
  font-weight: 700 !important;
}

.text-xbold {
  font-weight: 800 !important;
}

.text-xlbold {
  font-weight: 900 !important;
}

.text-light {
  font-weight: 300 !important;
}

.text-normal {
  font-weight: 400 !important;
}

.no-box--shadow {
  box-shadow: none !important;
}

.text-underline {
  text-decoration: underline;
}

/* FONT COLOR */

.color-primary {
  color: #5a50fe;
}

.color-primary:hover {
  color: #5a50fe;
}

.color-negative {
  color: #f7685b;
}

.color-white {
  color: #fff;
}

.color-faded {
  color: rgb(0, 0, 0, 0.5);
}

.color-light {
  color: rgb(0, 0, 0, 0.7);
}

.color-green {
  color: #dcffec;
}

.color-green {
  color: #008a00;
}

.color-text {
  color: #555555;
}

.color-bold {
  color: #1c223c;
}

.color-inactive {
  color: #707070;
}

.color-grey-2 {
  color: #48484d;
}

.color-black {
  color: #000;
}

.text-center {
  text-align: center;
}

.password__check--text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 1px;
  color: #55555e !important;
}

.password__check--text-p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #55555e;
}

.text-subtitle {
  color: var(--subTitleColor) !important;
}

.bg-grey {
  background: #e5e5e5 !important;
}

.color-warning {
  color: #ffd200 !important;
}

.color-dark-green {
  color: #554600 !important;
}

.bg-white {
  background: var(--whiteColor) !important;
  border-radius: 4px !important;
}

.bg-light-blue {
  background: #f7fafe;
}

.bg-light-grey {
  background: #f7f7fa;
}

.bg-primary {
  color: white  !important;
  background: #5A50FE !important;
}

.bg-primary:hover {
  background: #5A50FE !important;
}

.sider.bg-white {
  background-color: var(--whiteColor);
}

.bg-pink {
  background: #efcfd6;
}

.text-error {
  color: #930d28;
}

.btn-light {
  background: #f0f0f0 !important;
  border-radius: 4px;
}

.btn-primary {
  color: #fff !important;
}

.bg-grey .ant-select-selector {
    background-color: #E5E5E5 !important;
}

.form-sm {
    max-width: 349px;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 349px;
}
@media only screen and (max-width: 767px) {
  .content-wrap {
    padding-right: 20px;
    padding-left: 20px;
  }
  .account-balance--card,
  .account-volume--card {
    margin-top: 1rem !important;
  }
  .account-value--card {
    padding: 40px 27px;
  }
  .account-balance--text span {
    font-size: 0.8125rem;
  }
  .recovery-form-wrap {
    min-width: 36.04%;
    max-width: 36.04%;
  }
}

@media only screen and (min-width: 991px) {
  .account-overview--wrap {
    flex-wrap: nowrap;
  }
  .account-balance--card,
  .account-value--card {
    margin-right: 1rem !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
}

@media only screen and (max-width: 991px) {
  .analytics-wrap {
    width: 100;
    flex-wrap: wrap;
  }
  .left-analytics {
    width: 100%;
    margin-top: 1.5rem;
  }

  .right-analytics {
    width: 100%;
  }
  .analytics-divider--wrap {
    display: none;
  }
  .header {
    width: 100%;
  }
  .overview-radion--btn {
    width: 100%;
    max-width: 100%;
    margin-top: 1rem;
  }
}

/* @media only screen and  {
  
} */


.dashboard-header-container {
  margin-bottom: 2.25rem;
}

.dashboard-header-container > div {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex: 1 1;
  min-width: 100%;
}

.dashboard-header-container > div > h4 {
  margin: 0;
  font-weight: bold;
  font-size: 1.25rem;
  color: var(--titleColor);
}

.dashboard-header-container > div > p {
  margin: 0.25rem 0 0 0;
  font-size: 1rem;
  color: var(--bodyTextColor);
}

.dashboard-overview-button-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.dashboard-overview-button-group > button {
  margin: 1.5rem 1rem 0 0;
  font-weight: 700;
  height: 2.5rem;
  padding: 0 0.675rem;
  font-size: 0.925rem;
  box-shadow: none;
  border-radius: 0.25rem !important;

  @media screen and (max-width: 768px) {
    &:last-of-type {
      margin-right: 0;
    }
  }
}

.dashboard-overview-button-group > button:not([disabled]) {
  color: var(--primaryColor);
  background-color: var(--secondaryButtonColor);
  border-color: var(--secondaryButtonColor);
}

.dashboard-overview-button-group > button:not([disabled]):hover {
  border-color: var(--primaryColor);
  background-color: var(--secondaryButtonColor);
  color: var(--primaryColor);
}

.dashboard-overview-button-group > button:not([disabled]).ant-btn-primary {
  color: var(--whiteColor);
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
}

.dashboard-overview-container {
  margin-bottom: 1.5rem;
}

.dashboard-overview-container > .ant-col > .ant-card,
.dashboard-wallets-container {
  height: 100%;
  box-shadow: var(--dashboardCardBoxShadow);
  border-radius: 0.25rem;
}

.dashboard-overview-summary-card > .ant-card-body {
  padding: 0;
  height: 100%;
}

.dashboard-overview-summary-card > .ant-card-body > div {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.dashboard-overview-summary-card > .ant-card-body > div > div {
  padding: 1.25rem;
}

.dashboard-overview-summary-card > .ant-card-body > div > div:first-child {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1rem;
  color: var(--subTitleColor);
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.dashboard-overview-summary-card
  > .ant-card-body
  > div
  > div:first-child
  .anticon {
  font-size: 1.2rem;
}

.dashboard-overview-summary-card
  > .ant-card-body
  > div
  > div:first-child
  > div {
  width: 100%;
  margin-top: 0.5rem;
  color: var(--titleColor);
  font-size: 1.25rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-overview-summary-card
  > .ant-card-body
  > div
  > div:first-child
  > div
  > span {
  display: inline-block;
  margin-right: 0.5rem;
}

.dashboard-overview-summary-card
  > .ant-card-body
  > div
  > div:first-child
  > div
  > span:nth-child(2) {
  margin-right: 1rem;
}

.dashboard-overview-summary-card
  > .ant-card-body
  > div
  > div:first-child
  > div
  .ant-avatar-image {
  height: 1.15rem;
  width: 1.15rem;
}

.dashboard-overview-summary-card > .ant-card-body > div > div:last-child {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  font-size: 1rem;
  background-color: var(--dashboardCardFooterbackgroundColor);
  color: var(--subTitleColor);
}

.dashboard-overview-summary-card > .ant-card-body > div > div:last-child > div {
  width: 100%;
  margin-top: 0.25rem;
  color: var(--titleColor);
  font-size: 1.1rem;
  font-weight: 500;
}

.dashboard-overview-summary-card
  > .ant-card-body
  > div
  > div:last-child
  > div
  > span {
  display: inline-block;
  margin-right: 0.5rem;
}

.dashboard-analytics-container > .ant-card-body,
.dashboard-wallets-container > .ant-card-body {
  padding: 1.25rem 1.25rem 0.75rem 1.25rem;
}

.dashboard-analytics-summary-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.dashboard-analytics-summary-container > div {
  display: flex;
}

.dashboard-analytics-summary-container > div img {
  margin: 0 0.5rem 0 0;
  height: 1.5rem;
  width: 1.5rem;
}

.dashboard-analytics-summary-container > div h5 {
  font-weight: normal;
  font-size: 1rem;
  color: var(--subTitleColor);
  margin: 0.15rem 0 0 0;
}

.dashboard-analytics-summary-container > div p {
  color: var(--titleColor);
  font-size: 1.05rem;
  margin: 0.25rem 0 0.1rem 0;
}

.dashboard-analytics-summary-container > div span {
  color: var(--titleColor);
  font-size: 0.85rem;
  margin: 0;
  display: block;
  color: var(--negativeChangeColor);
}

.dashboard-analytics-summary-container
  > div
  span.negative-percentage-change::before {
  content: "";
}

.dashboard-analytics-summary-container > div span.postive-percentage-change {
  color: var(--positiveChangeColor);
}

.dashboard-analytics-summary-container
  > div
  span.postive-percentage-change::before {
  content: "+";
}

.dashboard-analytics-graph {
  max-height: 15rem;
}

.dashboard-chart-loader-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dashboard-chart-loader-container > div:first-child {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  font-size: 1rem;
}

.dashboard-chart-loader-container > div:first-child > *:first-child {
  margin-right: 1rem;
  color: var(--subTitleColor);
}

.dashboard-chart-loader-container > div:first-child > *:last-child {
  border-bottom: 1px solid var(--bgGrey);
  flex: 1 1;
  padding: 0 0 1rem 0;
  margin-bottom: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--bodyTextColor);
  font-size: 1.025rem;
  text-align: center;
  height: 10rem;
}

.dashboard-chart-loader-container > div:first-child > *:last-child ul {
  list-style: none;
  display: flex;
  flex-grow: 1;
  margin: 0 0 1rem 0;
  align-items: flex-end;
}

.dashboard-chart-loader-container > div:first-child > *:last-child ul li {
  background-color: var(--chartLoaderBarColor);
  width: 1.5rem;
  margin: 0 0.5rem;
  height: 100%;
  border-radius: 0.25rem;
}

.dashboard-chart-loader-container
  > div:first-child
  > *:last-child
  ul
  li:nth-child(1) {
  height: 50%;
  opacity: 0.4;
}

.dashboard-chart-loader-container
  > div:first-child
  > *:last-child
  ul
  li:nth-child(3) {
  height: 75%;
  opacity: 0.85;
}

.dashboard-chart-loader-container
  > div:first-child
  > *:last-child
  ul
  li:nth-child(4) {
  height: 55%;
  opacity: 0.4;
}

.dashboard-chart-loader-legend-container {
  padding: 0.75rem 0 0.25rem 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: var(--subTitleColor);
  font-size: 0.95rem;
}

.dashboard-chart-loader-legend-container > span {
  margin-left: 2.25rem;
  position: relative;
}

.dashboard-chart-loader-legend-container > span::before {
  content: "\00B7";
  font-size: 3.5rem;
  line-height: 0rem;
  position: absolute;
  top: 0.7rem;
  left: -1.15rem;
}

.dashboard-analytics-header-container,
.dashboard-wallets-header-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
}

.dashboard-analytics-header-container h3,
.dashboard-wallets-header-container h3 {
  font-size: 1.1rem;
  font-weight: 700;
  color: var(--subTitleColor);
  margin: 0;
}

.dashboard-analytics-header-container > div:first-child,
.dashboard-wallets-header-container > div:first-child {
  flex: 1 1;
}

.dashboard-analytics-header-container .filter-analytics button,
.dashboard-analytics-header-container .wallet-selector button {
  padding: 0;
  border: none;
  width: 6rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 1rem;
  margin-left: 2rem;
  background-color: var(--whiteColor);
  color: var(--subTitleColor);
  margin-top: -0.25rem;
}

.dashboard-analytics-header-container .filter-analytics button {
  width: 2.5rem;
}

.dashboard-analytics-header-container .filter-analytics span:nth-child(2) {
  display: none;
}

.dashboard-analytics-header-container .filter-analytics img {
  height: 1rem;
  margin: 0 0.75rem 0 0;
}

.dashboard-analytics-header-container .wallet-selector .ant-avatar {
  height: 1.75rem;
  width: 1.75rem;
  margin-right: 0.5rem;
}

.dashboard-analytics-header-container .filter-analytics .anticon,
.dashboard-analytics-header-container .wallet-selector .anticon {
  margin-left: 0.5rem;
}

.dashboard-analytics-header-container
  .filter-analytics
  .ant-dropdown-open
  .anticon,
.dashboard-analytics-header-container
  .wallet-selector
  .ant-dropdown-open
  .anticon {
  transform: rotate(180deg);
  margin-bottom: 0.15rem;
}

.dashboard-dropdown-wallet-list {
  max-height: 25vh;
  overflow-y: auto;
  margin: 0 -0.25rem 0 0;
  overflow-x: hidden;
}

.dashboard-dropdown-wallet-list li {
  border-bottom: 1px solid var(--dropdownMenuSectionBackgroundColor);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem 0 0.75rem 0.75rem;
  min-width: 7rem;
  font-size: 0.95rem;
}

.dashboard-dropdown-wallet-list li > span:first-child {
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 0.5rem;
}

.dashboard-dropdown-wallet-list li:last-child {
  border-bottom-width: 0;
}

.ant-dropdown.filter-dropdown-menu.mobile {
  left: 7.5vw !important;
}

.dashboard-dropdown-container {
  max-width: 85vw;
}

.dashboard-dropdown-container > h3 {
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--dropdownMenuTitleColor);
  padding: 1rem 1.5rem 1rem 1.5rem;
  border-bottom: 1px solid var(--bgGrey);
  margin: 0;
}

.dashboard-dropdown-section > h4 {
  font-size: 1rem;
  font-weight: 500;
  color: var(--dropdownMenuTitleColor);
  padding: 1rem 1.25rem;
  margin: 0;
}

.dashboard-dropdown-section > div h5 {
  font-size: 1rem;
  font-weight: 500;
  color: var(--dropdownMenuTitleColor);
  padding: 0 0 1rem 0;
  margin: 0;
}

.dashboard-dropdown-section > div {
  background-color: var(--dropdownMenuSectionBackgroundColor);
  padding: 0.85rem 1.25rem;
}

.dashboard-dropdown-section > div ul {
  list-style: none;
  padding-left: 1.25rem;
  position: relative;
}

.dashboard-dropdown-section > div ul::before {
  content: "";
  border-left: 3px solid var(--dropdownMenuListBorderColor);
  position: absolute;
  top: -0.85rem;
  left: 0;
  bottom: 3rem;
}

.dashboard-dropdown-section > div ul li {
  position: relative;
}

.dashboard-dropdown-section > div ul li::before {
  content: "";
  border: 3px solid var(--dropdownMenuListBorderColor);
  border-width: 0 0 3px 3px;
  border-radius: 0 0 0 0.5rem;
  top: 0;
  bottom: 45%;
  left: -1.25rem;
  width: 0.75rem;
  position: absolute;
}

.dashboard-dropdown-section > div ul li:first-child {
  margin-bottom: 1rem;
}

.dashboard-dropdown-section .ant-radio-group {
  display: flex;
  flex-wrap: wrap;
}

.dashboard-filter-type-options > label:last-child,
.dashboard-filter-period-options > label:first-child,
.dashboard-filter-period-options > label:last-child {
  display: none;
}

.dashboard-dropdown-section .ant-radio-group .ant-radio-button-wrapper {
  padding: 0rem 0.65rem;
  min-width: auto;
  background-color: transparent;
  margin: 0.5rem 0.75rem 0.5rem 0;
  border-color: transparent;
  box-shadow: none;
  color: var(--subTitleColor);
}

.dashboard-dropdown-section .ant-radio-group .ant-radio-button-wrapper::before {
  background: none;
}

.dashboard-dropdown-section
  .ant-radio-group
  .ant-radio-button-wrapper:last-child {
  margin-right: 0;
}

.dashboard-dropdown-section
  .ant-radio-group
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  border-color: var(--primaryColor);
  background-color: var(--dropdownMenuSelectedRadioButtonBackgroundColor);
  color: var(--dropdownMenuTitleColor);
}

.dashboard-dropdown-section .ant-picker-range {
  height: 2.5rem;
  border-radius: 0.25rem;
  box-shadow: none !important;
  border-color: var(--lightPrimaryColor);
  margin-bottom: 0.5rem;
  width: 100%;
}

.dashboard-dropdown-section .ant-picker-range.ant-picker-focused {
  border-color: var(--primaryColor);
}

.dashboard-dropdown-section
  .ant-picker-range.ant-picker-focused
  .ant-picker-active-bar {
  background-color: var(--primaryColor);
}

.dashboard-dropdown-footer-container {
  padding: 1.25rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.dashboard-dropdown-footer-container button {
  margin-left: 1rem;
  border-color: var(--dropdownMenuSectionBackgroundColor);
  background-color: var(--dropdownMenuSectionBackgroundColor);
  color: var(--primaryColor);
  font-weight: 500;
  font-size: 0.9rem;
  text-shadow: none !important;
  padding: 0.25rem 1rem;
  height: 2.25rem;
  border-radius: 0.25rem;
  box-shadow: none !important;
}

.dashboard-dropdown-footer-container button:hover,
.dashboard-dropdown-footer-container button:focus {
  border-color: var(--primaryColor);
  color: var(--primaryColor);
}

.dashboard-dropdown-footer-container button.ant-btn-primary {
  border-color: var(--primaryColor);
  background-color: var(--primaryColor);
  color: var(--whiteColor);
}

.dashboard-wallets-container {
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .dashboard-header-container > div > h4 {
    font-size: 1.6rem;
  }
  .dashboard-header-container > div > p {
    font-size: 1.05rem;
  }
  .dashboard-overview-button-group > button {
    padding: 0 1.25rem;
    font-size: 0.95rem;
  }
  .dashboard-analytics-summary-container {
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 0rem;
  }
  .dashboard-analytics-summary-container > div img {
    margin: 0 0.75rem 0 0;
    height: 1.75rem;
    width: 1.75rem;
  }
  .dashboard-analytics-summary-container > div h5 {
    font-size: 1rem;
    margin: 0.25rem 0 0 0;
  }
  .dashboard-analytics-summary-container > div p {
    font-size: 1.15rem;
    margin: 0.35rem 0 0.2rem 0;
  }
  .dashboard-chart-loader-container > div:first-child > *:first-child {
    margin-right: 2rem;
  }
  .dashboard-chart-loader-container > div:first-child > *:last-child {
    padding: 0 4rem 1rem 4rem;
    height: 11.75rem;
  }
  .dashboard-dropdown-container {
    max-width: 29rem;
  }
  .dashboard-filter-type-options > label:last-child,
  .dashboard-filter-period-options > label:first-child,
  .dashboard-filter-period-options > label:last-child {
    display: block;
  }
  .dashboard-dropdown-section .ant-picker-range {
    width: 17.5rem;
  }
  .dashboard-analytics-header-container .filter-analytics span:nth-child(2) {
    display: block;
  }
  .dashboard-analytics-header-container .filter-analytics button {
    width: 6rem;
  }
}

@media only screen and (min-width: 992px) {
  .dashboard-header-container > div {
    min-width: auto;
  }
  .dashboard-header-container > div > h4 {
    font-size: 1.65rem;
  }
  .dashboard-header-container > div > p {
    font-size: 0.95rem;
  }
  .dashboard-overview-button-group {
    justify-content: flex-end;
  }
  .dashboard-overview-button-group > button {
    margin: 0 0 0 0.75rem;
    margin-right: 0;
    height: 2.25rem;
    padding: 0 1rem;
  }
  .dashboard-overview-summary-card > .ant-card-body > div > div {
    padding: 1.5rem;
  }
  .dashboard-overview-summary-card > .ant-card-body > div > div:first-child {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .dashboard-overview-summary-card
    > .ant-card-body
    > div
    > div:first-child
    > div {
    margin-top: 0.75rem;
    font-size: 1.4rem;
  }
  .dashboard-overview-summary-card > .ant-card-body > div > div:last-child,
  .dashboard-overview-summary-card > .ant-card-body > div > div:first-child {
    font-size: 0.95rem;
  }
  .dashboard-overview-summary-card
    > .ant-card-body
    > div
    > div:first-child
    > div
    .ant-avatar-image {
    height: 1.25rem;
    width: 1.25rem;
  }
  .dashboard-analytics-summary-container {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }
  .dashboard-chart-loader-container > div:first-child > *:last-child {
    padding: 0 1rem 1rem 1rem;
    height: 9.25rem;
  }
}

@media only screen and (min-width: 1200px) {
  .dashboard-header-container > div > p {
    font-size: 1rem;
  }
  .dashboard-overview-button-group > button {
    margin: 0 0 0 1rem;
    height: 2.5rem;
    padding: 0 1.25rem;
  }
  .dashboard-overview-summary-card > .ant-card-body > div > div {
    padding: 1.25rem 3rem;
  }
  .dashboard-overview-summary-card
    > .ant-card-body
    > div
    > div:first-child
    > div {
    margin-top: 1rem;
    font-size: 1.5rem;
  }
  .dashboard-overview-summary-card > .ant-card-body > div > div:last-child,
  .dashboard-overview-summary-card > .ant-card-body > div > div:first-child {
    font-size: 1rem;
  }
  .dashboard-overview-summary-card
    > .ant-card-body
    > div
    > div:first-child
    > div
    .ant-avatar-image {
    height: 1.5rem;
    width: 1.5rem;
  }
  .dashboard-analytics-summary-container {
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 0rem;
  }
  .dashboard-chart-loader-container > div:first-child > *:last-child {
    padding: 0 3rem 1rem 3rem;
    height: 11.5rem;
  }
  .dashboard-wallets-container {
    margin-bottom: 12rem;
  }
  .dashboard-wallets-list-outside-container,
  .dashboard-wallets-list-outside-container .ant-tabs {
    margin-bottom: -13rem;
    padding-bottom: 13rem;
  }
}

@media screen and (min-width: 1400px) {
  .dashboard-chart-loader-container > div:first-child > *:last-child {
    padding: 0 5.5rem 1rem 5.5rem;
    height: 11.75rem;
  }
}

@media screen and (min-width: 1600px) {
  .dashboard-chart-loader-container > div:first-child > *:last-child {
    padding: 0 16rem 1rem 16rem;
  }
}

.ant-layout-sider {
    border: none !important;
    box-shadow: var(--sidebarBoxShadow);
    border-radius: 0 !important;
    z-index: 4;
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu:not(.ant-menu-submenu-open) .ant-menu-submenu-arrow {
    transform: rotate(-90deg);
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu:hover .ant-menu-submenu-arrow {
    color: var(--primaryColor);
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-item,
.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu>.ant-menu-submenu-title,
.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu ul li {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    height: 3rem;
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-item,
.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu>.ant-menu-submenu-title {
    height: 3.25rem;
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-item>span>a,
.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu>.ant-menu-submenu-title>span>a,
.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu>ul>li>span>a {
    color: var(--sidebarMenuColor);
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-item:active,
.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu>div:active,
.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu>ul>li:active {
    background-color: var(--dropdownMenuSelectedRadioButtonBackgroundColor);
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-item.ant-menu-item-selected {
    background-color: var(--dropdownMenuSelectedRadioButtonBackgroundColor);
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-item.ant-menu-item-selected>span>a {
    color: var(--titleColor);
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu ul li a {
    position: relative;
    margin-left: 1.5rem;
    color: var(--sidebarMenuColor);
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu ul li a.active {
    color: var(--primaryColor);
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu ul li a::after {
    content: "";
    height: 0.4rem;
    width: 0.4rem;
    border-radius: 0.7rem;
    background-color: var(--sidebarMenuColor);
    left: -0.985rem;
    top: 1.05rem;
    position: absolute;
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu ul li a.active::after {
    content: "";
    background-color: var(--primaryColor);
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-item.ant-menu-item-selected::after,
.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu ul li::after {
    content: "";
    left: 0;
    right: unset;
    border-left: 0.5rem solid var(--primaryColor);
    border-right: none;
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-item:nth-child(4),
.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-item:nth-child(6) {
    margin-top: 2.5rem;
    overflow: visible;
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-item:nth-child(4)::before,
.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-item:nth-child(6)::before {
    content: "";
    border-top: 1px solid var(--chartLoaderBarColor);
    position: absolute;
    top: -1.25rem;
    left: 1.75rem;
    right: 1.75rem;
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-item>svg,
.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu>div.ant-menu-submenu-title>svg {
    margin-right: 0.5rem;
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu.ant-menu-submenu-open ul {
    background-color: var(--sidebarSubMenuBackgroundColor);
}

.ant-layout-sider .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu ul li svg {
    margin-left: 0.5rem;
    margin-right: 0.35rem;
}

.sidebar-profile-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1.5rem 1.25rem -0.5rem 1.25rem;
}

.sidebar-profile-container>span:last-child {
    font-size: 1.1rem;
    font-weight: 500;
    margin-top: 0.5rem;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    color: var(--titleColor);
}

.sidebar-profile-container .ant-avatar-circle {
    background-color: var(--primaryColor) !important;
    height: 3.25rem;
    width: 3.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 0;
}
.site-header {
  background-color: var(--whiteColor);
  padding: 0;
  box-shadow: var(--headerBoxShadow);
  z-index: 2;
}

/* .site-header>div {
    max-width: 1720px;
    padding: 0 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
} */

/* .site-header>div>div:first-child {
    flex: 1;
    display: flex;
    justify-content: center;
} */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-right: 2.5rem;
  flex-flow: row;
}

.header-search {
  width: 563px;
  max-width: 563px;
  max-height: 37px;
  background: #f4f6f8;
  align-items: center;
}

.header-search input::placeholder {
  color: #707070;
}

.header-search input {
  font-size: 18px;
  margin-left: 8px;
}

.dashboard-wallets-list-outside-container {
    position: relative;
    margin-top: -0.5rem;
}

.dashboard-wallets-list-outside-container>a {
    position: absolute;
    right: 0.25rem;
    top: 0.75rem;
    z-index: 10;
    color: var(--primaryColor);
    font-weight: 500;
    font-size: 0.95rem;
}

.dashboard-wallets-list-outside-container .ant-tabs-nav::before {
    border: none;
}

.dashboard-wallets-list-outside-container .ant-tabs-nav-wrap {
    margin-bottom: 0rem;
}

.dashboard-wallets-list-outside-container .ant-tabs-tab>div {
    font-size: 1rem;
    padding: 0 0.5rem;
    color: var(--subTitleColor);
}

.dashboard-wallets-list-outside-container .ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar {
    height: 0.25rem;
    background-color: var(--primaryColor);
}

.wallet-card-in-list {
    height: 100%;
    padding: 0;
    height: 4.25rem;
}

.wallet-card-in-list>div {
    border-radius: 0.25rem;
    position: relative;
    padding-top: 1rem;
    background-color: var(--whiteColor);
    z-index: 1;
    height: 6rem;
    overflow: hidden;
}

.wallet-card-in-list>div:hover {
    box-shadow: var(--walletInListHoverBoxShadow);
}

.wallet-card-in-list>div>div {
    padding: 0.25rem;
}

.wallet-card-in-list>div>div:first-child {
    padding: 0.5rem 1.5rem;
    background-color: var(--sidebarSubMenuBackgroundColor);
    flex-direction: row;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: none;
    border-radius: 0.25rem 0.25rem 0 0;
}

.wallet-card-in-list>div>div:first-child>div:first-child {
    flex-grow: 1;
}

.wallet-card-in-list>div>div:first-child>div:not(:first-child) {
    margin-left: 1rem;
}

.wallet-card-in-list>div>div:nth-child(2)>a>div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.wallet-card-in-list>div>div:nth-child(2)>a>div>div {
    flex-grow: 1;
    margin-left: 1rem;
}

.wallet-card-in-list>div>div:nth-child(2)>a>div>div h4 {
    font-weight: 600;
    color: var(--titleColor);
    font-size: 1rem;
    margin: 0 0 0.15rem 0;
}

.wallet-card-in-list>div>div:nth-child(2)>a>div>div p {
    color: var(--subTitleColor);
    margin: 0;
}

.wallet-card-in-list>div>div:last-child {
    flex-direction: column;
    padding-top: 0;
    margin-top: 0rem;
    display: none;
}

.wallet-card-in-list:hover>div>div:last-child h4 {
    margin: 0.25rem 0 1.25rem 0;
    font-size: 1rem;
    color: var(--blackColor);
}

.wallet-card-in-list:hover>div>div:last-child ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    flex-direction: column;
}

.wallet-card-in-list:hover>div>div:last-child ul li {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.wallet-card-in-list:hover>div>div:last-child ul li:first-child {
    margin-bottom: 1rem;
}

.wallet-card-in-list:hover>div>div:last-child ul li>div:first-child {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--bodyTextColor);
    font-size: 1rem;
}

.wallet-card-in-list:hover>div>div:last-child ul li>div:first-child img {
    margin-right: 0.5rem;
    width: 1.5rem;
}

.wallet-card-in-list:hover>div>div:last-child ul li>div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}

.wallet-card-in-list:hover>div>div:last-child ul li>div:last-child p {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    color: var(--titleColor);
}

.wallet-card-in-list:hover>div>div:last-child ul li>div:last-child span {
    font-size: 0.85rem;
    color: var(--positiveChangeColor);
}

.wallet-card-in-list:hover>div>div:last-child ul li>div:last-child span:not(.negative-percentage-change)::before {
    content: "+"
}

.wallet-card-in-list:hover>div>div:last-child ul li>div:last-child span.negative-percentage-change {
    color: var(--negativeChangeColor);
}

@media only screen and (min-width: 992px) {
    .wallet-card-in-list>div {
        padding-top: 1rem;
        height: 5.5rem;
    }
}

@media only screen and (min-width: 1200px) {
    dashboard-wallets-list-outside-container {
        margin-top: 0;
    }
    .dashboard-wallets-list-outside-container>a {
        right: 1rem;
        top: 1rem;
        font-size: 1rem;
        font-weight: 600;
    }
    .dashboard-wallets-list-outside-container .ant-tabs-nav-wrap {
        margin-bottom: 0.5rem;
    }
    .dashboard-wallets-list-outside-container .ant-tabs-tab>div {
        font-size: 1.05rem;
        padding: 0.15rem 0.5rem;
    }
    .dashboard-wallets-list-outside-container .ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar {
        height: 0.25rem;
    }
    .ant-tabs-content>.ant-tabs-tabpane>.ant-row {
        margin-left: -0.5rem !important;
        margin-right: -0.5rem !important;
    }
    .wallet-card-in-list>div>div {
        padding: 1.5rem;
    }
    .wallet-card-in-list {
        padding: 0 0.5rem;
        height: 8rem;
    }
    .wallet-card-in-list>div {
        padding-top: 2rem;
        height: 8rem;
    }
    .wallet-card-in-list:hover>div {
        height: 18.5rem;
        z-index: 2;
    }
    .wallet-card-in-list:hover>div>div:first-child,
    .wallet-card-in-list:hover>div>div:last-child {
        display: flex;
    }
    .wallet-card-in-list>div>div:nth-child(2)>a>div>div h4 {
        font-size: 1.15rem;
        margin: 0 0 0.25rem 0;
    }
}
.card-virtual-create {
  background-color: var(--cardBackgroundColor);
  border-radius: 0.5rem;
  box-shadow: var(--cardBoxShadow);
  width: 100%;
  height: 200px;
  border-radius: 12px;
}

.colors-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.colors-container .color {
  width: 50px;
  height: 50px;
  border: 2px;
  padding: 8px;
  border-radius: 50%;
  border: 4px solid #fff;
}

.colors-container .color.blue {
  background: #5a50fe;
}

.colors-container .color.black {
  background: #040052;
}

.colors-container .color.purple {
  /* border: 2px solid var(--dangerColor); */
  background: #FF3599;
}

.colors-container .color.orange {
  /* border: 2px solid var(--dangerColor); */
  background: #00C279;
}

.colors-container .color.yellow {
  /* border: 2px solid var(--dangerColor); */
  background: #EAA5FF;
}

.site-layout {
  margin-left: 0;
}

.layout-changes {
  position: relative;
}

.layout-changes .ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 2px;
  left: 2px;
  cursor: pointer;
}

.layout-changes .ant-layout-content {
  background-color: #ffffff;
  overflow-x: scroll;
  height: 100vh;
}

.layout-changes .ant-layout-content::-webkit-scrollbar {
  display: none;
}

.layout-changes .ant-layout-content {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.site-layout-background {
  background-color: var(--pageBackgroundColor) !important;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.site-layout-background > div {
  width: 100%;
  max-width: calc(1720px - 4rem);
}

.ant-dropdown-menu {
  box-shadow: var(--dropdownMenuBoxShadow);
  border-radius: 0.25rem;
}

@media only screen and (min-width: 768px) {
  .site-layout-background {
    padding: 0.5rem 1.5rem;
  }
}

/* @media only screen and (min-width: 992px) {
    .site-layout {
        margin-left: 200px;
    }
} */

@media only screen and (min-width: 1200px) {
  .site-layout-background {
    padding: 1rem 2rem;
  }
}

/* BORDER RADIUS */

.br-2 {
  border-radius: 8px;
}

.no-border {
  border: none !important;
}

.menu.m-top-10 {
  margin-top: 3.5em;
}

.e-header--wrap {
  box-shadow: 0px 2px 3px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  background: var(--whiteColor);
  width: 100%;
}

.e-logo {
  width: 100%;
  padding: 0 2.75rem;
  margin-top: 1.875rem;
}

.e-input {
  height: 53px;
}

div.e-input div.ant-select-selector,
.auth-login-wrap,
.header-search input {
  height: 100% !important;
}

.faded {
  color: var(--textColor);
}

.ant-input.ant-input-lg {
  background: transparent;
}

.content-wrap {
  padding: 40px;

  @media screen and (max-width: 768px) {
    padding: 20px;
  }
}

/* OVERVIEW RADIO BUTTONS */

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #000;
  background: #2f80ed;
  border-color: #2f80ed;
}

.ant-radio-button-wrapper {
  min-width: 154px;
  padding: 12px;
  /* min-height: 45px; */
  height: unset;
  margin-right: 1rem;
  text-align: center;
  border: 1px solid #1c223c;
  color: #1c223c;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  background: transparent;
}

.ant-radio-button-wrapper:first-child {
  border-left: 1px solid #1c223c;
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover {
  /* color: var(--whiteColor) !important; */
  color: #000;
}

.avatar-normal {
  width: 30px;
  height: 30px;
}

.avatar-medium {
  width: 32px;
  height: 32px;
}

.account-overview--card,
.account-analytics--card {
  width: 100% !important;
  max-width: 100%;
  box-shadow: 0px 2px 10px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
}

.account-balance--card,
.bulk__description--text {
  padding: 12px 24px;
}

.account-value--card {
  align-items: center;
  display: flex;
  justify-content: center;
}

.ant-card-body {
  padding: 20px;
}

.account-overview--card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.welcome-name {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 24px;
  color: #040052;
}

.welcome-paragraph {
  max-width: 565px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4b487a;
}

.link-btn {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #5a50fe;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  /* width:100%; */
}

.link-btn img {
  width: 30px;
}

.link-btn:focus {
  border: none;
  outline: none;
}

@media only screen and (max-width: 1024px) {
  .welcome-paragraph {
    font-size: 14px;
  }

  .link-btn {
    font-size: 12px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.badge-purple > .ant-badge-count {
  background: var(--purpleLightColor);
  color: #0000e0;
  padding: 5px;
  height: 27px;
  border-radius: 100px;
  width: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner-card {
  max-width: 100%;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 10px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  padding: 12px 18px;
}

.line-card {
  max-width: 100%;
  width: 100%;
  background: #ffffff;
  padding: 12px 18px;
  border-bottom: solid 1px rgba(24, 39, 75, 0.12);
}

.line-card:hover {
  background: #fafafa;
  cursor: pointer;
}

.account-volume--card {
  padding: 21px 23px;
}

.account-overview--wrap {
  width: 100%;
  max-width: 100%;
  flex-wrap: wrap;
}

.analytics-divider--wrap {
  max-width: 10%;
  padding: 79px 50px;
}

.analytics-divider--wrap > div {
  width: 1px;
  background: #ececef;
  border-radius: 8px;
  height: 94px;
}

.recharts-surface,
.recharts-wrapper,
.recharts-legend-wrapper {
  width: 100%;
  max-width: 100%;
}

.ant-scroll-number-only {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-scroll-number-only > p.ant-scroll-number-only-unit {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.auth-login-preview {
  transition: transform 0.8s;
  transform: translateY(0px) !important;
  min-height: calc(100vh - 120px);
  min-height: calc(100vh - 120px);
  padding: 63px 40px;
  min-height: 100vh;
  padding-top: 130px;
}

.auth-login-wrap {
  max-width: 429px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.form-card {
  box-shadow: 0px 2px 10px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  padding: 60px 40px;
}

.form-card .ant-card-body {
  justify-content: unset;
  align-items: unset;
}

.form-card .ant-btn-primary {
  width: 100%;
  max-width: 100%;
  min-height: 50px;
}

.recovery-form-wrap {
  min-width: 519px;
  max-width: 519px;
}

.recovery-card.active {
  border: 2px solid #2f80ed;
}

.recovery-card {
  padding: 20px 24px;
  cursor: pointer;
}

.recovery-btn {
  min-width: 77.2%;
  max-width: 77.2%;
  min-height: 57px;
}

.btn-link {
  color: #5a50fe;
}

.signup-preview {
  width: 100%;
  min-height: 100vh;
}

.signup-wrap {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 4.5rem;
  padding-bottom: 4rem;
}

.register-info-wrap {
  padding-right: 2rem;
}

/* modal overlay */

.ant-modal-mask {
  background-color: rgba(53, 67, 142, 0.35);
}

.resend-modal-btn,
.welcome-modal-btn {
  width: 100%;
  height: 57px;
  background-color: #5a50fe !important;
  border-color: #5a50fe !important;
}

.welcome-modal-btn {
  height: 48px;
}

.simple-block-btn {
  width: 100%;
  height: 50px;
  background: #5a50fe;
}

.simple-block-btn:focus {
  background: #5a50fe;
}

.simple-block-btn:hover {
  background: #5a50fe;
}

.formInput {
  background: #fff !important;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 50px;
  font-weight: 400;
  margin-bottom: 10px;
  height: 56px;
  font-size: 14px;
  border: 1px solid #cbcae5;
  border-radius: 8px;
  width: 100%;
}

.formInput:focus {
  outline: none !important;
  border: 1px solid #5a50fe !important;
  box-shadow: none !important;
}

.ant-input-affix-wrapper-lg {
  padding: 0px 11px;
}

.mobile-input .formInput {
  border-bottom: 1px solid var(--offWhiteTrans);
}

.react-tel-input:focus {
  outline: none;
  border: 1px solid #5a50fe;
  box-shadow: none;
}

.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: transparent !important;
  bottom: 10px !important;
  border-radius: 0 !important;
}

.input-error-border {
  border: 2px solid #b11030;
}

.input-error-border:focus {
  border: 2px solid #b11030;
}

.header-dropdown-item:hover {
  background: transparent;
  color: #2f80ed;
}

.e-sidebar-logo {
  margin-top: 4rem;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
}

.onboarding-card {
  box-shadow: 0px 2px 10px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
}

.test-onboarding .ant-card-body {
  padding: 100px 0;
}

.test-onboarding .identity-image {
  width: 50px;
  margin-bottom: 20px;
}

.ant-input,
.ant-input-number-input,
.form__select {
  height: 50px !important;
}

.ant-input-number-input {
  height: 48px !important;
}

/* .ant-select-selection-item {
  line-height: 50px !important;
} */

.ant-select-selector img,
.ant-select-item-option-content img {
  width: 20px;
  margin-right: 15px;
}

.form__select {
  width: 100% !important;
}

.form__select .ant-select-selector {
  height: 100% !important;
  align-items: center;
}

.ant-select-selection-search input {
  height: 100% !important;
}

.btn {
  border-radius: 4px !important;
}

.auth-btn {
  height: auto;
  background: #5a50fe;
}

.auth-btn:hover {
  background: #5a50fe;
}

.btn-primary {
  background: #5a50fe;
  border: none;
  color: #fff;
}

.btn-primary:disabled {
  background-color: #d1cfff;
}

.btn-primary:focus {
  background-color: #5a50fe !important;
}

.btn-primary:hover {
  background-color: #5a50fe;
}

.auth-btn > span {
  padding: 9.5px 16.5px;
}

.forced-title {
  font-family: "eFont";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #040052;
}

.auth-btn[disabled],
.auth-btn[disabled]:hover,
.auth-btn[disabled]:focus,
.auth-btn[disabled]:active,
.e__btn--primary[disabled],
.e__btn--primary[disabled]:hover,
.e__btn[--primarydisabled]:focus,
.e__btn--primary[disabled]:active {
  color: white !important;
  background: #d1cfff !important;
  border-color: transparent !important;
}

.password__checker div div {
  height: 4px !important;
  border-radius: 30px;
}

.auth__card--wrap {
  background: #fff;
  padding: 40px;
}

.section__icon--md {
  width: 91px;
  height: 91px;
  margin-left: auto;
  margin-right: auto;
}

.section__icon--md svg {
  width: 100%;
  height: 100%;
}

.section__text--md-2 {
  font-size: 26px;
  color: #1c223c;
}

.bg__overlay--verify {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(60 73 121 / 40%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-input-affix-wrapper,
.header-search,
.ant-radio-button-wrapper,
.ant-radio-button-wrapper:first-child,
.inner-card,
.form-card,
.email-recovery-card,
.recovery-btn,
.ant-btn-primary {
  border-radius: 4px !important;
}

.ant-input-affix-wrapper {
  padding: 4px 11px;
}

.tab-header {
  display: flex;
  width: 100%;
  list-style-type: none;
  margin-bottom: 0;
}

.tab-header li {
  width: 33.33333%;
  padding: 19px 59px;
  background: #e3eefd;
  color: #707070;
  margin-right: 1px;
  cursor: pointer;
}

.tab-header li.active {
  background: #2f80ed;
  color: #fff;
  font-weight: 700;
}

.pin-input input {
  background: white;
  border-radius: 3px;
  border: 0;
  width: calc(25% - 5px);
  height: 44px;
  max-width: 44px;
  outline: none;
  text-align: center;
}

.pin-input {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  max-width: 196px;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid #35438e;
  border-radius: 4px;
  padding: 1px 10px;
}

.beneficiary--header {
  padding: 0.5rem 1.5rem 0.5rem 1.8rem;
  border: 1px solid #eee;
}

.empty__wrap--center {
  max-width: 359px;
  margin-left: auto;
  margin-right: auto;
}

.empty__button--wrap {
  max-width: 236px;
}

.empty__button {
  background: #e5e5e5;
  color: #1c223c;
  border: transparent;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.empty__button span:first-of-type {
  padding: 5px 5px 5px 1rem;
}

.empty__button span:nth-of-type(2) {
  padding-left: 0px;
}

.empty__description--beneficiary {
  max-width: 359px;
}

.empty__body--wrap {
  padding-top: 130px;
  padding-bottom: 130px;
}

.empty__body--wrap .btn-primary:hover {
  background: #5a50fe;
  color: #fff;
}

.bg__box--shadow {
  box-shadow: inset 0px 1px 0px -0.5px #c4cdd5;
}

.e__tabs {
  min-height: 450px;
}

.e__tabs .ant-tabs-nav-wrap {
  justify-content: center;
}

.e__tabs .ant-tabs-nav::before {
  border: none;
}

.card__account--verify {
  background: #f3f3f4;
  padding: 24px;
}

.group__btn--beneficiary {
  height: 45px;
}

.group__btn--beneficiary.first {
  margin-right: 32px;
}

.upload__card {
  background-color: #fffbe6;
  border: 1px solid #fff9dd;
  border-radius: 4px 4px 0px 0px;
  box-sizing: border-box;
  margin: 0;
  color: #000000d9;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  /* display: flex;
    align-items: center; */
  padding: 8px 15px;
  word-wrap: break-word;
}

.alert-icon {
  width: 45px;
  height: 45px;
}

.alert-icon span,
.alert-icon span svg {
  width: 100%;
  height: 100%;
}

.drag__card--info {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: auto;
}

.drag__card--info p {
  max-width: 300px;
}

.beneficiary__header--preview {
  padding: 12px 1.5rem;
  box-shadow: inset 0px -1px 0px -0.5px #c4cdd5;
  border-radius: 4px 4px 0px 0px;
}

.beneficiary__header--closeBtn {
  background: #f1f6fe;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.beneficiary__body--preview {
  flex-direction: row;
}

.beneficiary__body--preview-left {
  width: 40%;
  box-shadow: 0px 2px 0px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
}

.beneficiary__body--preview-right {
  width: 60%;
}

.beneficiary__avatar {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #ff84bf;
  position: relative;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.beneficiary__country {
  position: absolute;
  width: 17px;
  height: 17px;
  background: chocolate;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  border: 1px solid #fff;
}

.beneficiaries__list {
  overflow-x: scroll;
  max-height: 356px;
}

.beneficiary__item--details {
  box-shadow: inset 0px -1px 0px -0.5px rgba(196, 205, 213, 0.5);
  cursor: pointer;
}

.beneficiary__item--details:hover {
  background: #ecebff;
}

::-webkit-scrollbar {
  width: 4px;
}

/* Track */

::-webkit-scrollbar-track {
  border-radius: 16px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #2f80ed;
  border-radius: 16px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #2f80ed;
}

.beneficiary--preview-right-header,
.beneficiary--preview-right-content {
  padding: 50px 1.5rem 10px;
  display: flex;
}

.beneficiary__country-relative {
  width: 32px;
  height: 32px;
  background: chocolate;
  border-radius: 50%;
  border: 1px solid #fff;
  display: flex;
  margin-right: 14.1px;
}

.beneficiary-details-wrap {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
}

.beneficiary-details-wrap p:first-of-type,
.beneficiary-details-wrap div.flex {
  margin-bottom: 10px !important;
}

.beneficiary__body--preview-footer {
  box-shadow: inset 0px 1px 0px -0.5px #c4cdd5;
  padding: 19px 32px;
  border-radius: 0px 0px 4px 4px;
  display: flex;
  justify-content: space-between;
}

.beneficiary__body--preview-footer button {
  padding-left: 60px;
  padding-right: 60px;
}

.account-balance--text.blur {
  filter: blur(10.5px);
}

.account__dropdown--value {
  display: flex;
  align-items: center;
}

.ant-table-wrapper {
  background-color: rgb(255, 255, 255);
}

thead[class*="ant-table-thead"] th {
  background-color: rgb(255, 255, 255);
  border-top: 1px solid #f0f0f0;
}

.ant-table-pagination {
  padding-right: 10px;
}

.card-btn {
  padding: 20px 64px 20px 64px;
  border: 1px solid #ffffff;
  cursor: pointer;
}

.card-btn:hover {
  border: 1px solid #dae2f1;
}

.completed-kyc-state {
  background: #ffffff;
  box-shadow: 0px 2px 10px -2px rgba(24, 39, 75, 0.12),
    0px 2px 4px -2px rgba(24, 39, 75, 0.08);
  border-radius: 4px;
  padding: 100px 0;
}

.completed-kyc-state h1 {
  font-family: "eFont";
  margin: 10px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #1c223c;
}

.completed-kyc-state p {
  font-family: "eFont";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #48484d;
  max-width: 433px;
  margin: auto;
  margin-bottom: 10px;
}

.completed-kyc-state button {
  font-family: "eFont";
  background: #5a50fe;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #ffffff;
  outline: none;
  border: none;
  padding: 10px 30px;
  margin-top: 20px;
}

.item-flex {
  position: relative;
}

.item-flex h1 {
  margin-top: 10px;
  margin-bottom: 10px !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 24px;
}

.input-fields-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.input-fields-flex > div {
  flex: 50% 1;
  margin-bottom: 10px;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  padding: 10px;
  cursor: pointer;
  width: 100%;
  /* margin-bottom: 30px; */

  height: 56px;
  background: #ffffff;
  border: 1px solid #cbcae5;
  border-radius: 8px;
  position: relative;
}

.upload-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background: rgb(216, 214, 255, 0.6);
  border-radius: 8px 0px 0px 8px;
}

.custom-file-upload.rejected-border {
  border: 1px solid #ea0016 !important;
}

.centered-items {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.custom-file-upload img {
  margin: 0 10px;
  width: 20px;
}

.check-svg-required svg {
  width: 25px;
}

.rejected-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #ea0016;
}

.custom-file-upload p {
  font-size: 16px !important;
  line-height: 24px !important;
  color: #6f6e80 !important;
  margin: 0 !important;
}

.custome-styling .nav-tabs {
  border-bottom: 1px solid transparent;
  flex-wrap: nowrap;
  align-items: stretch;
  margin: 0;
  padding: 0;
}

.custome-styling .nav-tabs .nav-link {
  /* width: 373px; */
  height: 64px;
  font-family: "eFont";
  background: #e3eefd;
  border-radius: 0px;
  margin: 0px 0.5px;
  padding: 19px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #707070;
  text-align: center;
  flex: 1 1;
}

.custome-styling .nav-tabs .nav-link.active {
  background: #2f80ed;
  border-color: transparent;
  color: #ffffff;
}

.settings-bg {
  background: #ffffff;
  box-shadow: 0px 2px 10px -2px rgba(24, 39, 75, 0.12),
    0px 2px 4px -2px rgba(24, 39, 75, 0.08);
  border-radius: 4px;
  height: 50vh;
}

.main-settings {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  font-family: "eFont";
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #1c223c;
  cursor: pointer;
}

.main-settings span {
  font-weight: bold;
  font-size: 16px;
  color: #1c223c;
}

.title-settings {
  background: #ffffff;
  box-shadow: inset 0px -1px 0px -0.5px rgba(196, 205, 213, 0.4);
  border-radius: 4px 4px 0px 0px;
  margin: 9px 0px;
  padding: 10px 30px;
}

.title-settings h1 {
  font-style: normal;
  font-weight: bold;
  font-family: "eFont";
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #555555;
}

.content-settings {
  padding: 0px 30px 20px 30px;
}

.content-settings h1 {
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  font-family: "eFont";
  color: #48484d;
  margin-bottom: 25px;
  cursor: pointer;
}

.content-settings h1 span {
  background: rgba(9, 26, 47, 0.5);
  border-radius: 4px;
  padding: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.03em;
  color: #ffffff;
  margin-left: 10px;
}

.content-settings > .h1:hover {
  background: #d5e5fb;
}

.spans:hover {
  color: #000 !important;
}

.content-settings h1 span {
  background: none;
}

.settings-tabs-settings .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #5a50fe;
}

.settings-tabs-settings .ant-tabs-tab .ant-tabs-tab-btn:hover {
  color: #5a50fe;
}

.settings-tabs-settings .nav-tabs {
  width: 100%;
}

.settings-tabs-settings .nav-tabs .nav-link {
  padding: 0 40px 10px 0;
  font-style: normal;
  font-weight: normal;
  font-family: "eFont";
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #555555;
}

.settings-tabs-settings .nav-tabs .nav-link span {
  background: rgba(9, 26, 47, 0.5);
  border-radius: 4px;
  padding: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.03em;
  color: #f7fafe;
  margin-left: 10px;
}

.settings-tabs-settings .nav-tabs .nav-link:hover {
  background-color: transparent;
  border: none;
}

.settings-tabs-settings .nav-tabs .nav-link.active {
  background-color: transparent;
  border-color: transparent;
  color: #5a50fe;
  border-bottom: 2px solid #5a50fe;
}

.settings-tabs-settings .tab-content {
  padding-top: 10px;
}

.business-settings-card {
  background: #ffffff;
  box-shadow: 0px 2px 10px -2px rgba(24, 39, 75, 0.12),
    0px 2px 4px -2px rgba(24, 39, 75, 0.08);
  border-radius: 4px;
  padding: 20px 0;
}

.business-settings-card .card-link:after {
  content: "\f107";
  font-family: "FontAwesome";
  font-weight: 900;
  float: right;
}

.business-settings-card .card-link.collapsed:after {
  content: "\f105";
  font-family: "FontAwesome";
}

.business-settings-card .card {
  border: none;
}

.business-settings-card .card-header {
  background: #ffffff;
  padding: 10px 40px;
  border: none;
}

.business-settings-card .card-header .card-link {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  font-family: "eFont";
  letter-spacing: 0.01em;
  text-decoration: none;
  color: #48484d;
}

.business-settings-card .card-header .card-link .verified {
  background: #dbefdc;
  border-radius: 8px;
  padding: 8px 8px;
  font-family: "eFont";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.015em;
  /* Eversend Blue/Semantics/Success/Dark/10 */
  color: #265828;
}

.business-settings-card .card-header .card-link .unverified {
  background: #fffbe6;
  border-radius: 8px;
  padding: 8px 8px;
  font-family: "eFont";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.015em;
  /* Eversend Blue/Semantics/Success/Dark/10 */
  color: #806900;
}

.business-settings-card .card-body {
  background: #f6f6f7;
  padding: 20px 40px;
}

.business-settings-card .card-body h1 {
  padding: 8px 16px;
  background: #e5e5e5;
  border-radius: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: #00004b;
  font-family: "eFont";
  width: 198px;
}

.business-settings-card .card-body h1 img {
  width: 15px;
}

.settings-picture .deleteIcon {
  width: 15px;
  height: 18px;
  margin-top: -12px;
  cursor: pointer;
}

.settings-picture .no-uploads {
  border-radius: 50%;
  border: 1px dashed #5a50fe;
  width: 80px;
  height: 80px;
  padding: 4px;
  margin-right: 10px;
}

.settings-picture .label-profile {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #2f80ed;
  margin-right: 10px;
  cursor: pointer;
}

.settings-picture .no-uploads .no-uploads-child {
  width: 70px;
  height: 70px;
  background: #f6f6f7;
  border-radius: 50%;
  margin: auto;
  color: #a1acbc;
  font-size: 40px;
  font-weight: 500;
  padding: 5px 10px 5px 23px;
  font-style: normal;
}

.business-settings-card .card-body input {
  background: #ffffff;
  border: 2px solid #35438e;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 56px;
  padding: 10px 20px;
  font-style: normal;
  font-weight: normal;
  font-family: "eFont";
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #707070;
}

.business-settings-card .card-body input:disabled {
  background: #e5e5e5;
  border: 2px solid #bdbdbd;
}

.business-settings-card .card-body button {
  background: #5a50fe;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.015em;
  color: #ffffff;
  padding: 16px 24px;
  width: 100%;
  border: none;
  outline: none;
}

.business-settings-card.business-settings-card .business-settings-card-edit-btn,
.business-settings-card-clip-board.business-settings-card-clip-board button {
  width: auto;
  background-color: transparent;
  color: #5a50fe;
  padding: 0;
}

.label-auth {
  font-family: "eFont";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #1c223c;
}

.profile-tab-settings {
  background: #ffffff;
  box-shadow: 0px 2px 10px -2px rgba(24, 39, 75, 0.12),
    0px 2px 4px -2px rgba(24, 39, 75, 0.08);
  border-radius: 4px;
  padding: 20px 30px;
}

.profile-tab-settings h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.015em;
  color: #0000e0;
  font-family: "eFont";
  background: #cdcdf9;
  border-radius: 8px;
  padding: 4px 8px;
  width: 98px;
  text-transform: capitalize;
}

.profile-tab-settings .role-title {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.015em;
  color: #0000e0;
  font-family: "eFont";
  background: #cdcdf9;
  border-radius: 8px;
  padding: 4px 8px;
  /* width: 98px; */
  text-transform: capitalize;
}

.profile-tab-settings h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #1c223c;
}

.profile-tab-settings input {
  background: #ffffff;
  border: 2px solid #35438e;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 56px;
  padding: 10px 20px;
  font-style: normal;
  font-weight: normal;
  font-family: "eFont";
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #707070;
}

.profile-tab-settings input:disabled {
  background: #e5e5e5;
  border: 2px solid #bdbdbd;
}

.profile-tab-settings button {
  background: #5a50fe;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.015em;
  color: #ffffff;
  padding: 16px 24px;
  width: 100%;
  border: none;
  outline: none;
  font-family: "eFont";
}

.wallet-new-design h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #48484d;
  font-family: "eFont";
}

.wallet-new-design p {
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 39px;
  letter-spacing: 0.01em;
  color: #5a50fe;
  font-family: "eFont";
}

.wallet-hide-bal {
  background: #f0f0f0;
  border-radius: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  font-family: "eFont";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.015em;
  color: #48484d;
  outline: none;
  border: none;
  margin-right: 15px;
}

.wallet-hide-bal span {
  margin-right: 5px;
}

.left-par {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.015em;
  color: #696779;
  font-family: "eFont";
}

/* .current-wallet-active {
  margin-top: 70px;
} */

.current-wallet-active .wallet-card {
  background: #ffffff;
  border-radius: 0px 0px 4px 4px;
  cursor: pointer;
  position: relative;
  /* border-radius: 4px; */
}

.current-wallet-active .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #5a50fe;
}

.current-wallet-active .ant-tabs-tab:hover {
  color: #5a50fe;
}

.current-wallet-active .wallet-card .pad-Wallet {
  padding: 40px 24px;
}

.current-wallet-active .wallet-card .more-wallet-option {
  background: #f6f6f7;
  border-radius: 4px 4px 0px 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  padding: 5px 20px;
  display: none;
}

.current-wallet-active .wallet-card .more-wallet-option .ms-auto img {
  margin-right: 10px;
  cursor: pointer;
}

.current-wallet-active .wallet-card a {
  text-decoration: none;
}

.current-wallet-active .wallet-card:hover {
  box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12),
    0px 12px 42px -4px rgba(24, 39, 75, 0.12);
}

.current-wallet-active .wallet-card .curImage {
  width: 35px;
  height: 35px;
  margin-right: 20px;
  border-radius: 50%;
  margin-top: -20px;
}

.current-wallet-active .wallet-card p {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  font-family: "eFont";
  color: #1c223c;
}

.current-wallet-active .wallet-card p span {
  font-weight: normal;
  color: #555555;
  line-height: 26px;
}

.current-wallet-active .wallet-card i {
  color: #5a50fe;
  opacity: 0;
  transition: all 500ms ease-in-out;
}

.current-wallet-active .wallet-card:hover .showNow {
  opacity: 1;
}

.current-wallet-active .wallet-card:hover .more-wallet-option {
  display: block;
}

.deleteTooltip {
  position: relative;
  display: inline-block;
}

.deleteTooltip .tooltip-ex-text {
  /* This is for the tooltip text */
  visibility: hidden;
  background: rgba(9, 26, 47, 0.5);
  border-radius: 4px;
  width: 100px;
  text-align: center;
  padding: 2px;
  position: absolute;
  z-index: 1;
  top: 28px;
  left: -35px;
  font-style: normal;
  font-weight: normal;
  font-family: "eFont";
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.03em;
  color: #ffffff;
}

.deleteTooltip:hover .tooltip-ex-text {
  /* Makes tooltip text visible when text is hovered on */
  visibility: visible;
}

.deleteTooltip img {
  margin-right: 20px;
}

.singleWallet-h1 span {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #696779;
  cursor: pointer;
}

.singleWallet-h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  font-family: "eFont";
  color: #1c223c;
}

.single-wallet-buttons .btn-one {
  padding: 10px 24px;
  background: #5a50fe;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.01em;
  font-family: "eFont";
  color: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;
}

.single-wallet-buttons .btn-one:disabled {
  opacity: 0.5;
}

.single-wallet-buttons .btn-one img {
  margin-right: 10px;
}

.single-wallet-buttons button {
  margin-top: 10px;
  margin-right: 5px;
  margin-bottom: 20px;
  font-family: "eFont";
}

.single-wallet-buttons .btn-two {
  padding: 10px 24px;
  background: #f0f0f0;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  font-family: "eFont";
  letter-spacing: 0.01em;
  color: #5a50fe;
  outline: none;
  border: none;
  cursor: pointer;
}

.single-wallet-buttons .btn-two img {
  margin-right: 10px;
}

.single-wallet-financial {
  background: #ffffff;
  box-shadow: 0px 2px 10px -2px rgba(26, 42, 82, 0.04),
    0px 2px 4px -2px rgba(24, 39, 75, 0.06);
  border-radius: 4px;
  padding: 32px 8px;
}

.single-wallet-financial h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  font-family: "eFont";
  letter-spacing: 0.01em;
  color: #48484d;
}

.single-wallet-financial p {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  font-family: "eFont";
  letter-spacing: 0.01em;
  color: #1c223c;
}

.wallet-single-h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  font-family: "eFont";
  color: #48484d;
}

.wallet-activate .img-flag {
  margin-right: 15px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.wallet-activate .currency-type {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.01em;
  font-family: "eFont";
  color: #555555;
}

.wallet-activate .currency-type span {
  color: #1c223c;
  line-height: 30px;
  font-weight: bold;
}

.icon-direction {
  color: #4caf50;
  font-size: 24px;
}

.face-left {
  color: #fd66a3;
}

.add-money-h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  font-family: "eFont";
  color: #000000;
  margin-bottom: 10px;
}

.add-money-p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #48484d;
  max-width: 397px;
  margin: auto;
  font-family: "eFont";
  margin-bottom: 10px;
}

.transfer-delay {
  background: #f1f1fd;
  border-radius: 4px;
  padding: 10px;
  max-width: 350px;
  margin: auto;
  margin-bottom: 10px;
}

.transfer-delay p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  font-family: "eFont";
  color: #00004b;
  margin: 0;
  margin-bottom: 15px;
}

.transfer-delay img {
  margin-right: 15px;
  width: 15px;
  height: 15px;
  margin-top: 5px;
}

.transfer-delay-card {
  padding: 10px;
  max-width: 370px;
  margin: auto;
  margin-bottom: 10px;
}

.naija-account-number-h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.01em;
  font-family: "eFont";
  color: #000000;
}

.naija-account-number-p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #48484d;
  font-family: "eFont";
}

.other-countries-add-money {
  background: #ffffff;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

.other-countries-add-money img {
  margin-right: 10px;
}

.other-countries-add-money h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #1c223c;
  margin-top: 15px;
}

.other-countries-add-money p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #555555;
}

.banktransfer-others {
  margin: 0 auto 0 auto;
  width: 80%;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.tabs-for-add-money .ant-input-affix-wrapper {
  width: 100% !important;
}

.banktransfer-others h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #000000;
}

.banktransfer-others h3 {
  font-family: "DM Sans";
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1px;
  margin: 0px;
  cursor: pointer;
}

.banktransfer-others h4 {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  margin: 0px;
}

.custom-select-card {
  height: 72x;
  border-radius: 8px;
  border: 1px solid #e9ebed;
  padding: 10px;
}

.custom-select-card .ant-select-selector {
  background-color: #f8f9fa !important;
  border-radius: 40px !important;
}

.custom-select-card .ant-select-selector img {
  border-radius: 50%;
}

.custom-select-card .ant-select-selector .ant-select-selection-item {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0px;
  margin: 0px;
}

.custom-select-card .ant-input-number {
  background: transparent !important;
  height: 0px !important;
  width: 100% !important;
}

.custom-select-card .ant-input-number-input {
  height: 35px !important;
  text-align: right !important;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  color: #040052;
}

.custom-play textarea.ant-input {
  /* height: 72x; */
  border-radius: 8px !important;
  border: 1px solid #e9ebed !important;
  padding: 10px;
}

.remaining-time {
  background: #fcf6de;
  border: 1px solid #ffc700;
  padding: 10px 20px;
  max-width: 300px;
  margin: auto;
  border-radius: 8px;
}

.request-expire {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  color: #4b487a;
}

.request-expire-time {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  color: #4b487a;
}
/* .ant-input-number-focused {
  box-shadow: none !important;
} */

.wallet-request-title {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  margin: 0px;
}

.wallet-request-title-bold {
  font-family: "DM Sans";
  font-size: 23px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  margin: 0px;
}

.wallet-request-title-new {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  margin: 0px;
}

.small-request-money {
  font-family: "DM Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  margin: 0px;
}

.money-request-p {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  margin: 0px;
}

.greyed-out {
  background: #f6f6f7;
  padding: 8px;
}

.greyed-bank-transfer {
  background: #f6f6f7;
  border-radius: 4px;
  padding: 24px 8px;
}

.banktransfer-others p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #444052;
  margin: 0px;
}

.banktransfer-others h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #1c223c;
}

.add-money-crypto-div .btn-btn-btn {
  background: #5a50fe;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #ffffff;
  outline: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  width: 60%;
}

.add-money-crypto-div .btn-btn-btn:disabled {
  opacity: 0.5;
}

.banktransfer-others .btn-btn-btn {
  background: #5a50fe;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #ffffff;
  outline: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  width: 100%;
}

.banktransfer-others .request-search-btn {
  background: #5a50fe;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: #ffffff;
  outline: none;
  border: none;
  padding: 8px;
  cursor: pointer;
}

.banktransfer-others .request-search-btn:disabled {
  opacity: 0.5;
}

.banktransfer-others .grey-btn-btn {
  background: #f0f0f0;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  outline: none;
  border: none;
  padding: 10px;
  color: #5a50fe;
  width: 100%;
}

.banktransfer-others .btn-btn-btn:disabled {
  opacity: 0.5;
}

.banktransfer-others .btn-btn-play {
  background: #f0f0f0;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #5a50fe;
  outline: none;
  border: none;
  padding: 10px;
  width: 100%;
}

.banktransfer-others .btn-btn-playnothing {
  background: transparent;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #5a50fe;
  outline: none;
  border: none;
  padding: 10px;
  width: 100%;
}

.banktransfer-others .newBtnPPlay {
  background: #f0f0f0;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #5a50fe;
  outline: none;
  border: none;
  padding: 10px;
  width: 100%;
}

.continue-to-wallet .desc-p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: #48484d;
}

.continue-to-wallet .btn-btn-btn {
  background: #5a50fe;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #ffffff;
  outline: none;
  border: none;
  padding: 10px;
  width: 100%;
}

.btn-one-team {
  background: #f0f0f0;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #1c223c;
  padding: 16px 24px;
  outline: none;
  border: none;
}

.tBut {
  width: 130px;
  height: 40px;
  font-weight: bolder;
}

.payout-modal-text
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon {
  background: #5a50fe;
}

.payout-modal-text .ant-steps-item-process .ant-steps-item-icon {
  border-color: #5a50fe;
}

.payout-modal-text .ant-steps-item-finish .ant-steps-item-icon {
  border-color: #5a50fe;
}

.payout-modal-text
  .ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon {
  color: #5a50fe;
}

.payout-modal-text
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #5a50fe;
}

.payout-modal-text
  .ant-steps-vertical.ant-steps-small
  .ant-steps-item-container
  .ant-steps-item-title {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
}

.first-payout {
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.first-payout .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #5a50fe;
}

.first-payout .ant-tabs-tab-btn:hover {
  color: #5a50fe;
}

.first-payout .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn::selection {
  background: #5a50fe;
}

.payout-tabs .nav-tabs .nav-link {
  color: #555555;
  margin-left: 50px;
}

.payout-tabs .nav-tabs .nav-link:hover {
  border: none;
}

.payout-tabs .nav-tabs .nav-link.active {
  color: #5a50fe;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #5a50fe;
}

.paySomeone .tab-content {
  padding-top: 20px;
}

.payout-flex img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.payout-flex p {
  margin-top: 10px;
}

.paySomeone .grey-box {
  padding: 10px;
  cursor: pointer;
}

.paySomeone .grey-box:hover {
  background: #eff0f6;
  border-radius: 4px;
}

.paySomeone .wallet-card {
  background: #f6f6f7;
  border-radius: 4px;
  padding: 5px 40px;
  cursor: pointer;
}

.paySomeone .wallet-card img {
  width: 35px;
  height: 35px;
  margin-right: 20px;
}

.paySomeone .wallet-card p {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  font-family: "eFont";
  color: #1c223c;
}

.paySomeone .wallet-card p span {
  font-weight: normal;
  color: #555555;
  line-height: 26px;
}

.paySomeone .wallet-card i {
  opacity: 0;
  transition: all 500ms ease-in-out;
}

.paySomeone .wallet-card:hover .showNow {
  opacity: 1;
}

.edited-form-details-pay {
  background: #f6f6f7;
  border-radius: 4px;
  padding: 15px;
}

.edited-form-details-pay h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #1c223c;
}

.pay-summary-h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #555555;
}

li.recharts-legend-item {
  display: inline-flex !important;
  margin-right: 32px !important;
}

li.recharts-legend-item:last-child {
  margin-right: 0 !important;
}

li.recharts-legend-item .recharts-surface {
  margin-top: 4px;
  margin-right: 8px !important;
}

.recharts-layer .recharts-bar-rectangle {
  opacity: 1;
}

/* 
.recharts-layer .recharts-bar-rectangle:hover {
  opacity: 1;
} */

.recharts-tooltip-cursor {
  opacity: 0.5;
}

.list-view {
  background-color: white;
  padding: 16px 40px;
  cursor: pointer;
}

.list-view a {
  text-decoration: none;
  color: black;
}

.list-view:hover {
  background: #eff0f6;
}

.list-view-image img {
  width: 35px;
  height: 35px;
  margin-right: 20px;
  border-radius: 50%;
}

.list-view-image p {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  font-family: "eFont";
  color: #1c223c;
}

.list-view p span {
  font-weight: normal;
  color: #555555;
  line-height: 26px;
}

.inline-list-items .p-tag {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #1c223c;
  margin-right: 20px;
}

.inline-list-items img {
  margin-top: -4px;
  width: 16px;
}

.hide-show-list {
  display: none;
}

.list-view:hover .hide-show-list {
  display: inline;
}

.flex-row-container {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 20px;
  gap: 20px;
}

.transaction-filters {
  position: absolute;
  right: 12px;
  top: 15px;
  z-index: 99;
}

.transaction-filters .ant-dropdown-trigger {
  background-color: transparent;
  border: none;
}

.transaction-filters .ant-btn-default {
  background-color: #5a50fe;
  color: white;
}

.transaction-filters .ant-btn-icon-only {
  background-color: #e5e5e5 !important;
  border-radius: 0px 4px 4px 0px !important;
  color: #040052 !important;
}

.playground-filter {
  width: 400px;
}

.playground-filter h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #48484d;
}

.dateHandle {
  background: #f6f6f7;
}

.playground-filter .ant-radio-button-wrapper {
  min-width: 0px;
  padding: 2px 6px;
  /* min-height: 45px; */
  background-color: transparent;
  margin-right: 1rem;
  text-align: center;
  border: none;
  color: #1c223c;
  transition: none;
}

.playground-filter .ant-radio-button-wrapper-checked {
  background: #e5e5e5;
  border: 1px solid #5a50fe;
  border-radius: 4px;
  color: #5a50fe;
}

.playground-filter .ant-radio-button-wrapper-checked:hover {
  color: #5a50fe;
  border: 1px solid #5a50fe;
}

.single-transaction-financial {
  background: #ffffff;
  box-shadow: 0px 2px 10px -2px rgba(24, 39, 75, 0.12),
    0px 2px 4px -2px rgba(24, 39, 75, 0.08);
  border-radius: 4px;
  padding: 20px 0;
}

.single-transaction-financial a {
  color: white;
  background-color: #5a50fe;
  padding: 10px 15px;
  border-radius: 5px;
}

.internal-bg {
  box-shadow: inset 0px -1px 0px -0.5px rgba(196, 205, 213, 0.4);
  border-radius: 4px 4px 0px 0px;
  padding: 0px 20px 10px 20px;
}

.trans-details {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #000000;
}

.trans-details-h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 39px;
  letter-spacing: 0.01em;
  color: #000000;
}

.trans-details .div.ant-typography {
  margin-bottom: 0px !important;
}

.recepient-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #555555;
}

.pass-wrapper {
  position: relative;
}

.passwordVisible {
  position: absolute;
  top: 45px;
  z-index: 10;
  right: 15px;
}

.ant-input-affix-wrapper > input.ant-input {
  height: 30px !important;
}

/* .ant-input-affix-wrapper {
  height: 42px !important;
} */

.card-container-one .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  display: inline-block;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #5a50fe;
  border-color: #5a50fe;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #5a50fe;
}

.card-container-one .ant-tabs-nav {
  margin: 0;
}
/* 
.card-container-one .ant-tabs-nav-list {
  width: 100%;
} */

@media only screen and (max-width: 1024px) {
  .card-container-one .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    display: flex;
  }
}

.card-container-one .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  color: #55555e;
  background: #ecebff;
}

.card-container-one .ant-tabs-tab {
  width: 33.33333%;
  padding: 19px 59px;
  height: 64px;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  align-items: center;
  letter-spacing: 0.01em;
}

/* .card-container-one .ant-tabs-tab.ant-tabs-tab-disabled {
  margin-left: 0px !important;
} */

.card-container-one
  .ant-tabs-card.ant-tabs-top
  > .ant-tabs-nav
  .ant-tabs-tab
  + .ant-tabs-tab {
  margin-left: 4px !important;
}

.card-container-one .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white;
}

.card-container-one
  .ant-tabs-card.ant-tabs-top
  > .ant-tabs-nav
  .ant-tabs-tab-active {
  background: #5a50fe;
  /* border-radius: 4px 0px 0px 0px; */
}

.exchange-select
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #d9d9d9;
  background: #f6f6f7;
  border-radius: 4px 0px 0px 4px;
  height: 50px;
}

.exchange-select .ant-select-selection-item {
  line-height: 50px !important;
}

.dt-btn button {
  background: #5a50fe;
  color: white;
  height: 50px;
}

.dt-btn button:hover {
  background: #5a50fe;
  color: white;
}

.dt-btn button:hover span {
  background: #5a50fe;
  color: white;
}

.otp-css {
  border: 1.5px solid #35438e;
  border-radius: 4px;
  width: 230px;
  margin: auto;
  padding: 20px 0 20px 0;
}

.otp-css input {
  width: 30px !important;
  height: 20px !important;
}

.upload-form-item .ant-form-item-control-input-content > span {
  display: block;
}

.upload-form-item .ant-form-item-label {
  display: block !important;
}

.bank-transfer-modal {
  background: #ffffff;
  border: 1px solid #e9ebed;
  border-radius: 12px;
  padding: 20px;
}

.bank-transfer-modal .curImage {
  width: 35px;
  height: 35px;
  margin-right: 20px;
  border-radius: 50%;
  /* margin-top: -15px; */
}

.bank-transfer-modal:hover .showNow {
  opacity: 1;
}

.wallet-add {
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #e9ebed;
  margin-bottom: 15px;
  position: relative;
}

.wallet-add:hover {
  background: #ecebff;
  border-radius: 4px;
}

.wallet-add .img-flag {
  /* margin-top: -15px;
  margin-right: 15px; */
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.wallet-add .currency-type {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.01em;
  font-family: "eFont";
  color: #555555;
  margin: 0px;
}

.wallet-add .currency-type span {
  color: #1c223c;
  line-height: 30px;
  font-weight: bold;
}

.banktransfer-others .ant-space-item {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 10px 20px;
}

.selectedPaymentType {
  margin-right: 7px;
  width: 11px;
  margin-top: -4px;
}

.newBankAccount {
  border: 1px dashed #5a50fe;
  border-radius: 4px;
  padding: 20px;
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #5a50fe;
  margin-top: 20px;
}

.notify-ban-account {
  background: #fffbe5;
  border-radius: 4px;
  padding: 10px;
}
.tooltip-h1 {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: white;
  margin: 0px;
}

.notify-ban-account .header-bank {
  font-style: 400;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: #806900;
  font-family: "DM Sans";
  margin-left: 20px;
  /* margin-top: 15px; */
}

.notify-ban-account .header-bank-par {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #806900;
}

.notify-ban-account .header-bank-par::before {
  content: "•";
  margin-right: 5px;
}

.addmoney-title {
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 39px;
  letter-spacing: 0.01em;
  color: #1c223c;
  margin: 0px;
}

.request-money-title {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
}

.payment-modal-text .ant-steps-item-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #8a8a8a;
}

.wallet-add-money {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #1c223c;
}

.wallet-addmoney-title {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #1c223c;
}

.addmoney-form .ant-input-number {
  border-left: 1px solid white !important;
}

.addmoney-form .ant-select .ant-select-selector {
  border-right: 1px solid white !important;
  height: 50px;
  padding: 10px;
}

.addmoney-form-crypto .ant-select .ant-select-selector {
  height: 50px;
  padding: 10px;
  border: 1.5px solid #35438e !important;
  border-radius: 4px !important;
}

.notaBena {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.015em;
  color: #48484d;
  margin-top: 5px;
}

.some-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
}

.crypto-h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #1c223c;
  text-align: center;
  margin-bottom: 10px;
}

.crypto-p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #48484d;
  max-width: 260px;
  margin: auto;
}

.crypto-address {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #1c223c;
  word-wrap: break-word;
}

.qr-text {
  color: #48484d !important;
  max-width: 155px;
}

.qr-text-link {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  /* line-height: 26px; */
  letter-spacing: 0.01em;
  color: #2f80ed;
}

.add-money-ul li {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #48484d;
  margin-bottom: 10px;
}

.single-beneficial-header {
  background: #ffffff;
  box-shadow: 0px 2px 10px -2px rgb(26 42 82 / 4%),
    0px 2px 4px -2px rgb(24 39 75 / 6%);
  border-radius: 4px;
  padding: 10px 10px;
  display: flex;
  justify-content: flex-end;
}

.single-beneficiary-body {
  background: #ffffff;
  box-shadow: 0px 2px 10px -2px rgb(26 42 82 / 4%),
    0px 2px 4px -2px rgb(24 39 75 / 6%);
  border-radius: 4px;
  padding: 10px 10px;
}

.single-beneficiary-shadow {
  box-shadow: 0px 6px 38px -6px rgba(24, 39, 75, 0.12),
    0px 8px 16px -6px rgba(24, 39, 75, 0.08);
}

.btn-download {
  color: #4598f5;
  font-weight: bolder;
  height: 40px;
}

.icons-ben {
  font-size: larger;
}

.xxxLarger {
  height: 45px !important;
  width: 45px !important;
  font-size: 34px !important;
  line-height: 42px !important;
  background-color: #f6f6f7 !important;
  border: none !important;
  float: right;
  display: inline;
  margin-right: 0;
  font-weight: 600;
}

.xxxText {
  color: #a1acbc !important;
}

.remText {
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.01em;
  text-align: left;
}

.remTextH2 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
}

.users-filters {
  position: absolute;
  right: 12px;
  top: 8px;
  z-index: 99;
}

.drawer {
  position: absolute;
  z-index: 99;
  background-color: #fff;
  width: 100%;
  padding-left: 10%;
  background: #faf8f8;
  box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12),
    0px 12px 42px -4px rgba(24, 39, 75, 0.12);
  border-radius: 4px;
}

.text-sixteen {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.text-eighteen {
  font-size: 18px !important;
  font-weight: 400 !important;
}

.bene-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #5a50fe;
}

.ant-tabs-ink-bar {
  background: #5a50fe !important;
}

.bene-tabs .ant-tabs-tab .ant-tabs-tab-btn:hover {
  color: #5a50fe;
}

.beneficiary-progress .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #5a50fe;
}

.beneficiary-progress .ant-tabs-tab .ant-tabs-tab-btn {
  color: #5a50fe;
}

.beneficiary-progress-test
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon {
  background: #5a50fe;
}

.beneficiary-progress-test .ant-steps-item-process .ant-steps-item-icon {
  border-color: #5a50fe;
}

.beneficiary-progress-test .ant-steps-item-finish .ant-steps-item-icon {
  border-color: #5a50fe;
}

.beneficiary-progress-test .ant-steps-item-finish .ant-steps-item-icon:hover {
  border-color: #5a50fe;
}

.beneficiary-progress-test
  .ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon {
  color: #5a50fe;
}

.beneficiary-progress-test
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #5a50fe;
}

.beneficiary-progress-test
  .beneficiary-progress-test
  .ant-steps-item
  .ant-steps-item-finish:hover
  .ant-steps-item-title {
  color: #5a50fe;
}

.this-table .ant-typography {
  color: #4b487a;
}

.this-table .anticon-copy svg {
  color: #4b487a;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #5a50fe;
}

.ant-tabs-tab .ant-tabs-tab-btn:hover {
  color: #5a50fe;
}

.dashboard-header-container-head {
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: row;
}

.dashboard-header-container-head > div:first-child {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
}

.header-autocomple-container {
  width: 40%;
  margin-left: calc(var(--lgFont) * -1.3);
  background-color: var(--bgLightGrey);
}

@media only screen and (max-width: 1024px) {
  .header-autocomple-container {
    width: 85%;
  }
}

/* .header-autocomple-container .ant-input-affix-wrapper {
  height: calc(var(--inputHeight) * 0.85) !important;
} */

.header-autocomple-container input {
  margin: 0 calc(var(--xxxsFont) * 0.55);
  height: var(--inputHeight);
  font-size: var(--smFont);
}

.header-search-results-item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header-search-results-item-container > div:first-child {
  display: flex;
  flex-direction: column;
}

.header-avatar-container {
  margin-right: calc(var(--xxsFont) * 0.85);
}

.header-dropdown-user-container-action-buttons {
  width: 234px;
  padding: 8px 8px 8px 8px;
  border-radius: 8px;
}

.header-dropdown-user-container-action-buttons p {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  margin: 0px;
}

.header-dropdown-user-container-action-buttons .ant-dropdown-menu-item-active {
  background-color: transparent;
}

.header-dropdown-user-container {
  padding-bottom: calc(var(--xxxsFont) * 0.75);
}

.header-dropdown-user-container h1 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #444052;
}

.header-dropdown-user-container p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #4b487a;
  max-width: 353.53px;
}

.header-dropdown-user-container .ant-dropdown-menu-item-divider {
  margin: calc(var(--xxxsFont) * 0.85) 0;
}

.header-dropdown-user-container .ant-dropdown-menu-item {
  padding: calc(var(--xxxsFont) * 1.1) calc(var(--xxxsFont) * 1.5);
  font-size: var(--smFont);
}

.header-dropdown-user-container .ant-dropdown-menu-item a {
  display: flex;
  align-items: center;
}

.header-dropdown-user-container .ant-dropdown-menu-item svg {
  margin-right: var(--xxxsFont);
  font-size: var(--xlFont);
}

.header-dropdown-user-details-container {
  margin: var(--smFont);
  display: flex;
  align-items: center;
}

.header-dropdown-user-details-container > div:last-child {
  margin-left: var(--xxsFont);
}

.header-dropdown-user-details-container > div:last-child > div {
  margin-bottom: calc(var(--xxxsFont) * 0.5);
}

.header-dropdown-user-details-container-faq {
  margin: var(--smFont);
}

.header-dropdown-user-details-container-faq > div:last-child > div {
  margin-bottom: calc(var(--xxxsFont) * 0.5);
}

.settings-tabs-settings .ant-select .ant-select-selector {
  border-right: 1px solid white !important;
  height: 50px;
  padding: 10px;
}

.two-factor-div {
  background: #f0f0ff;
  border-radius: 4px;
  padding: 20px 40px;
  display: flex;
  /* align-items: center; */
  flex-direction: row;
  margin-bottom: 20px;
  box-shadow: 0px 2px 10px -2px rgba(24, 39, 75, 0.12),
    0px 2px 4px -2px rgba(24, 39, 75, 0.08);
}
.two-factor-div .ant-avatar-circle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.two-factor-div .ant-avatar > img {
  width: 50%;
  height: 50%;
  filter: invert(48%) sepia(76%) saturate(6739%) hue-rotate(234deg)
    brightness(99%) contrast(101%);
}

.two-factor-div .ant-avatar-image {
  background: white;
}

.two-factor-div h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #040052;
  margin-top: 10px;
}

.two-factor-div p {
  max-width: 395px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;

  color: #4b487a;
}

.last-child-factor {
  width: 70%;
}

.two-factor-div button {
  font-weight: 700;
  height: 2.5rem;
  line-height: 22px;
  padding: 6px 16px;
  font-size: 12px;
  box-shadow: none;
  border-radius: 4px;
  border: none;
  background-color: #5a50fe !important;
  color: #ffffff !important;
  margin-top: 20px;
}

@media only screen and (max-width: 1024px) {
  .two-factor-div {
    padding: 10px;
    flex-direction: column;
  }
  .two-factor-div h1 {
    text-align: center;
  }

  .two-factor-div p {
    text-align: center;
  }
  .two-factor-div button {
    margin-top: 0px;
  }
  .last-child-factor {
    width: 65%;
  }
}

.mobileShow {
  display: none;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .mobileShow {
    display: inline;
  }
}

.mobileHide {
  display: inline;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .mobileHide {
    display: none;
  }
}

.ant-modal-content {
  border-radius: 8px !important;
}

.ant-modal-content .ant-modal-header {
  border-radius: 8px !important;
}

.timer-class {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1.5px;
  color: #5a50fe;
  margin: 0px;
  padding: 0px;
}

.styled-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(4, 0, 82, 0.3);
  z-index: 1000;
}

.welcome-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  height: auto;
  max-height: 100%;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  background-color: rgba(4, 0, 82, 0.3);
  z-index: 1000;
}

.welcome-modal-parent {
  position: relative;
  width: 90%;
  max-width: 1000px;
  height: auto;
  /* max-height: 90%; */
  background-color: white;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  z-index: 1001;
}

.welcome-modal-close {
  position: absolute;
  right: 0px;
  top: -40px;
  padding: 4px;
  width: 32px;
  height: 32px;
  background: #ffffff;
  border-radius: 6px;
  cursor: pointer;
  z-index: 1002;
}

.welcome-modal-container {
  padding: 0 60px;
  /* overflow-y: scroll; */
}

.welcome-modal-container .welcome-modal-p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #4b487a;
  max-width: 310.15px;
  margin: auto;
  margin-bottom: 50px;
}

.welcome-modal-container .welcome-modal-p-footer {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #4b487a;
  max-width: 311px;
  margin: auto;
  margin-top: 50px;
}

.welcome-modal-container .welcome-modal-p-footer a {
  color: #5a50fe;
}

.welcome-modal-card {
  padding: 16px 16px 40px 16px;
  max-width: 383px;
  background: #ffffff;
  border: 1px solid #e9ebed;
  border-radius: 8px;
  margin-bottom: 20px;
  cursor: pointer;
}

.welcome-modal-card:hover {
  border: 1px solid #5a50fe;
}

.welcome-modal-image {
  height: 150px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  max-width: 100%;
}

.welcome-modal-image img {
  width: 100%;
  object-fit: contain;
}

.welcome-modal-card h2 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 24px;
  text-align: center;
  color: #444052;
  margin-top: 25px;
  margin-bottom: 30px;
}

.welcome-modal-card h3 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #4b487a;
  max-width: 351px;
  margin: auto;
}

@media only screen and (max-width: 1024px) {
  .welcome-modal-overlay {
    height: 100%;
    top: 0;
    left: 0;
  }
  .welcome-modal-parent {
    padding: 10px;
    max-height: 90%;
    overflow-y: auto; /* Enable scrolling for overflowing content */
  }
  .welcome-modal-close {
    top: 0px;
  }
  .welcome-modal-container {
    padding: 0 10px;
  }
  .welcome-modal-image {
    height: 110px;
  }
}

.tabs-docs {
  padding: 70px;
}

.custom-tabs {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  border-bottom: 1px solid #cbcae5;
}

.custom-tabs .tabs {
  flex-grow: 1;
  padding: 10px 15px;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #74748a;
}

.custom-tabs .tabs img {
  width: 25px;
  margin-right: 5px;
}

.custom-tabs .tabs:hover {
  background-color: transparent;
  color: #5a50fe;
}

.custom-tabs .tabs.active {
  background-color: transparent;
  color: #5a50fe;
  border-bottom: 2px solid #5a50fe;
}

.custom-tabs .tabs:hover img {
  filter: invert(15%) sepia(50%) saturate(2654%) hue-rotate(237deg)
    brightness(101%) contrast(99%);
}

.custom-tabs .tabs.active img {
  filter: invert(15%) sepia(50%) saturate(2654%) hue-rotate(237deg)
    brightness(101%) contrast(99%);
}

.custom-tab-pane {
  padding: 20px 0 0 0;
}

.custom-tab-pane {
  display: block;
}

@media only screen and (max-width: 1024px) {
  .tabs-docs {
    padding: 10px;
  }
  .custom-tabs {
    flex-direction: row;
  }
  .custom-tabs .tabs {
    flex: unset;
  }
}

.custom-step-card {
  padding: 32px 32px 32px 16px;
  /* height: 520px; */
  overflow-y: scroll;
  background: #f8f9fa;
  border-radius: 12px;
}

.custom-modal__tab p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #74748a;
  margin-left: 20px;
}

.custom-modal__tab {
  margin-bottom: 20px;
  /* padding-bottom:20px;
  border-bottom: 1px solid #E9EBED; */
}

.custom-modal__tab .p-count {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  background: #ffffff;
  border: 1px solid #e9ebed;
  border-radius: 47px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 1px;
  color: #74748a;
  margin-top: -8px;
}

.custom-modal__tab.active p {
  color: #040052;
}

.custom-modal__tab.active .p-count {
  background: #5a50fe;
  border: none;
  color: #ffffff;
}

.custom-modal__tab h3 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 1.5px;
  color: #74748a;
}

.custom-modal__tab.completed p {
  color: #74748a;
}

.push-to-right {
  width: 60px;
}

.custom__progress-bar {
  width: 60px;
  height: 3px;
  background-color: #e9ebed;
  border-radius: 10px;
  position: relative;
}

.custom__progress-bar .progress-step {
  position: absolute;
  width: 20px;
  height: 3px;
  border-radius: 10px;
  background-color: #5a50fe;
}

.on-top {
  position: absolute;
  z-index: 1000;
  right: 0px;
  width: 42px;
  height: 56px;
  padding: 17px 5px 5px 10px;
  cursor: pointer;
}

.custom__sub-tab::before {
  content: "\2022"; /* Bullet character */
  margin-right: 26px;
  margin-left: 10px;
  font-size: 1.5em;
}

.custom__sub-tab::before {
  color: #d8d6ff;
}

.custom__sub-tab {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #4b487a;
  margin-bottom: 10px;
}

.custom-hr {
  width: 367px;
  margin-left: 45px;
}

.custom__sub-tab.active {
  color: #5a50fe;
}

.custom__sub-tab.active::before {
  color: #5a50fe;
}

.custom__sub-tab.completed::before {
  color: #128a11;
}

.onboarding-card-form-details {
  height: 460px;
  overflow-y: scroll;
  border-bottom: 1px solid #e9ebed;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.onboarding-card-form-footer {
  text-align: end;
}

.custom-modal__close-button {
  padding: 12px 16px;
  background: #5a50fe;
  border-radius: 12px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 15px;
}

.custom-modal__close-button:focus {
  outline: none;
  border: none;
}

.custom-modal__close-button:disabled {
  background: #e2e0ff;
}

.meta-map-onboard {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.meta-map-onboard img {
  margin-bottom: 40px;
}

.meta-map-onboard h1 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 24px;
  color: #444052;
  margin-bottom: 25px;
}

.meta-map-onboard p {
  max-width: 282px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #4b487a;
  margin: auto;
}

.business-form-fields {
  padding: 0 100px;
}

.pin-code-div {
  padding: 0 250px 0 0;
}

.business-form-fields h1 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 24px;
  color: #444052;
  margin-bottom: 15px;
}

.business-form-fields h2 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1px;
  color: #444052;
  margin-top: -5px;
  margin-bottom: 10px;
}

.business-form-fields p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #55555e;
  margin-bottom: 20px;
}

.business-form-fields .label-form {
  display: block;
  margin-bottom: 5px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444052;
}

.business-form-fields .reg-input {
  margin-bottom: 10px;
  padding: 10px 20px;
  height: 50px;
  font-size: 16px;
  border: 1px solid #cbcae5;
  border-radius: 8px;
  width: 100%;
  color: #6f6e80;
}

.business-form-fields .reg-input:focus {
  outline: none;
  border: 1px solid #5a50fe;
  box-shadow: none;
}

.business-form-fields .reg-input-area {
  margin-bottom: 10px;
  padding: 10px;
  height: 112px;
  font-size: 14px;
  border: 1px solid #cbcae5;
  border-radius: 8px;
  width: 100%;
  color: #6f6e80;
}

.business-form-fields .reg-input-area:focus {
  outline: none;
  border: 1px solid #5a50fe;
  box-shadow: none;
}

.business-form-fields .note-business {
  background: #fcf6de;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 20px;
  max-width: 416px;
}

.business-form-fields .note-business h2 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #4b487a;
}

@media only screen and (max-width: 1024px) {
  .business-form-fields {
    padding: 0;
  }
  .pin-code-div {
    padding: 0 100px 0 0;
  }

  .custom-hr {
    width: 240px;
  }
  .custom-step-card {
    padding: 16px;
  }
}

.welcome-container-main {
  height: 450px;
  overflow-y: scroll;
  border-bottom: 1px solid #e9ebed;
}

.welcome-container-main .row {
  margin: 0 !important;
}

.welcome-container-main-h1 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 24px;
  color: #444052;
}

.welcome-container-main-h2 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4b487a;
  margin-bottom: 40px;
}

.welcome-container-footer p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444052;
  margin-top: 20px;
}

.docs-welcome p {
  max-width: 642.73px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4b487a;
  margin-bottom: 20px;
}

.docs-welcome h3 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #040052;
}

.welcome-container-footer p a {
  color: #5a50fe;
}

.accordion-item-other img {
  width: 20px;
  margin-top: -8px;
}

.accordion-item {
  margin-left: 20px;
}

.accordion-item img {
  width: 20px;
}

.accordion-item-title {
  margin-left: 20px;
}

.accordion-item-title-other {
  margin-left: 20px;
}

.accordion-item-title h1 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #444052;
}

.accordion-item-title-other h1 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444052;
}

.accordion-item-title p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #444052;
}

.accordion-item-content {
  margin-left: 40px;
}

.accordion-item-content p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444052;
}

.accordion-item-content-other {
  margin-left: 40px;
}

.accordion-item-content-other p {
  /* max-width: 548.59px; */
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4b487a;
}

.business-form-fields .reg-select {
  height: 50px !important;
}

.reg-select {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  background-image: url(/static/media/chevron_down_new.1b590a50.svg);
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 10px;
  padding: 10px 20px;
  height: 48px;
  font-size: 14px;
  border: 1px solid #cbcae5;
  border-radius: 8px;
  width: 100%;
  color: #6f6e80;
}

.reg-select::-ms-expand {
  display: none;
}

.reg-select:focus {
  outline: none;
  border: 1px solid #5a50fe;
  box-shadow: none;
}

.form__select_flag button {
  height: 56px;
  border: 1px solid #cbcae5;
  border-radius: 8px;
  color: #6f6e80;
  outline: none;
  padding: 0 20px 0 20px;
}

.welcome-container-main .form__select_flag button {
  height: 48px;
}

.banktransfer-others .input-card {
  width: 100%;
}

.banktransfer-others .input-card .ant-input-affix-wrapper {
  padding: 4px 16px 4px 16px;
  border-radius: 8px;
  background-color: #f4f5f6;
  height: 40px;
}

.banktransfer-others .input-card .ant-input-affix-wrapper-focused {
  border-color: #5a50fe; /* Your desired border color */
  box-shadow: none;
}

.banktransfer-others .input-card .ant-input-affix-wrapper:hover {
  border-color: #5a50fe !important; /* Your desired border color */
  box-shadow: none;
}

.banktransfer-others .input-card .ant-input {
  background-color: #f4f5f6;
  height: 32px !important;
}

.cards-sub-title {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  color: #4b487a;
}

.cards-sub-title span {
  color: #5a50fe;
  font-weight: 700;
  font-size: 14px;
}

.card-wallet-card {
  padding: 24px 24px 20px 24px;
  border-radius: 12px;
  border: 1px solid #e9ebed;
  margin-top: 20px;
}

.card-wallet-card h1 {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  color: #444052;
}

.card-wallet-card p {
  font-family: "DM Sans";
  font-size: 23px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  color: #040052;
}

.card-table-h1 h1 {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  color: #55555e;
}

.general-table-filters .ant-dropdown-trigger {
  background-color: transparent;
  border: none;
}

.general-table-filters .ant-btn-default {
  background-color: #5a50fe;
  color: white;
}

.general-table-filters .ant-btn-icon-only {
  background-color: #e5e5e5 !important;
  border-radius: 0px 4px 4px 0px !important;
  color: #040052 !important;
}

.custom-table-component {
  border-radius: 8px;
  border: 1px solid #e9ebed;
}

.custom-table-component h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.custom-table-component .ant-table-thead > tr > th {
  border-bottom: none;
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  color: #444052;
}

.custom-table-component .ant-table-row:hover {
  background-color: #ecebff !important;
}

.custom-table-component .ant-table-row:nth-child(odd) {
  background-color: #f8f8f9;
}

.custom-table-component .ant-table-row > td {
  border-bottom: 1px solid #e9ebed;
}

.custom-table-component .ant-table-tbody > tr > td {
  font-family: "DM Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  color: #4b487a;
}

.custom-table-component .ant-table-row {
  height: 20px !important; /* Adjust the height as needed */
}

.general-btn {
  padding: 7px 16px;
  border: none;
  cursor: pointer;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  background: #5a50fe;
  border-radius: 8px;
}

.general-btn:disabled {
  background-color: #e2e0ff;
}

.general-btn:focus {
  outline: none;
  border: none;
}

.general-btn-grey {
  padding: 7px 16px;
  border: none;
  cursor: pointer;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #5a50fe;
  background: #e9ebed;
  border-radius: 8px;
}

.general-btn-grey:disabled {
  background-color: #e2e0ff;
}

.general-btn-grey:focus {
  outline: none;
  border: none;
}

.header-dropdown-user-container-action-card {
  width: 150px;
  padding: 8px 6px;
  border-radius: 6px;
}

.header-dropdown-user-container-action-card p {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  margin: 0px;
}

.header-dropdown-user-container-action-card .ant-dropdown-menu-item:hover {
  background-color: #ecebff;
  border-radius: 4px;
}

.header-dropdown-user-container-action-card .ant-dropdown-menu-item-active {
  background-color: transparent;
}

.virtual-card-design {
  /* padding:20px; */
  border-radius: 16px;
}

.v-card-header h1 {
  font-family: "DM Sans";
  font-size: 23px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  color: #040052;
  margin: 0;
}

.v-card-header p {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  color: #74748a;
  margin: 0;
}

.v-card-header h2 {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  color: #55555e;
  margin: 0;
}

.v-card-show h1 {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  color: #4b487a;
  margin: 0;
}

.virtual-card-design h2 {
  font-family: "DM Sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0px;
  color: white;
  margin: 0;
}

.virtual-card-design h1 {
  font-family: "DM Sans";
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0px;
  color: white;
  margin: 0;
}

.virtual-card-design p {
  font-family: "DM Sans";
  font-size: 15px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 1.2120000123977661px;
  color: #abcdf6;
  margin: 0;
}

.virtual-card-design h3 {
  font-family: "DM Sans";
  font-size: 17px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  margin: 0;
  color: white;
}

.virtual-card-design h4 {
  font-family: "DM Sans";
  font-size: 19px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0px;
  margin: 0;
  color: white;
}

.v-card-actions p {
  font-family: "DM Sans";
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1px;
  color: #444052;
  margin: 0;
}

.card-balances {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #e9ebed;
}

.card-balances h1 {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  color: #444052;
  margin: 0;
}

.card-balances p {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  color: #040052;
  margin: 0;
  margin-top: 5px;
}

.custom-padding {
  padding: 40px 80px;
}

@media only screen and (max-width: 1024px) {
  .custom-padding {
    padding: 10px;
  }
}

.card-creator {
  font-family: DM Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  margin: 0;
  color: #74748a;
}

.card-creator span::before {
  content: "• ";
  font-size: 16px; /* You can adjust the size as needed */
}

.card-numbers-details h1 {
  font-family: DM Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  margin: 0;
  color: #444052;
}

.card-numbers-details p {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  margin: 0;
  color: #4b487a;
}

.position-bottom-card {
  position: absolute;
  bottom: -7px;
  left: 37px;
}

.v-card-table {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  color: #444052;
}

.v-card-time {
  font-family: DM Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  color: #55555e;
}

.paginate-total {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  color: #4b487a;
}

.paginate-total span {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  color: #444052;
}

.custom-table-component .input-card .ant-input-affix-wrapper {
  padding: 4px 16px 4px 16px;
  border-radius: 8px;
  background-color: #f4f5f6;
  height: 32px;
}

.custom-table-component .input-card .ant-input-affix-wrapper-focused {
  border-color: #5a50fe; /* Your desired border color */
  box-shadow: none;
}

.custom-table-component .input-card .ant-input-affix-wrapper:hover {
  border-color: #5a50fe !important; /* Your desired border color */
  box-shadow: none;
}

.custom-table-component .input-card .ant-input {
  background-color: #f4f5f6;
  height: 25px !important;
}

.custom-table-component .ant-pagination-prev .ant-pagination-item-link,
.custom-table-component .ant-pagination-next .ant-pagination-item-link {
  background-color: #f4f6f7;
}

.custom-table-component .ant-pagination-prev,
.custom-table-component .ant-pagination-next {
  min-width: 24px;
  height: 24px;
  border-radius: 4px;
  line-height: 24px;
  color: #444052;
}

.custom-table-component .ant-pagination-item-active {
  border-color: #5a50fe !important; /* Your desired border color */
}

.custom-table-component .ant-pagination-item {
  font-family: DM Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 1px;
  min-width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #e9ebed;
}

.filter-btn-general {
  border: none;
  background-color: transparent;
  outline: none;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  color: #040052;
  cursor: pointer;
}

.filter-btn-general:focus {
  outline: none;
  border: none;
}

.filter-options-general {
  padding: 0px 8px 0px 8px;
  border-radius: 16px;
  border: 1px solid #cbcae5;
  background-color: #f4f6f7;

  font-family: DM Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 1px;
  color: #74748a;
  cursor: pointer;
}

.filter-dropdown-table {
  position: absolute;
  top: 22px;
  left: 0px;
  z-index: 999;
  padding: 8px;
  border-radius: 6px;
  box-shadow: 0px 2px 8px 0px #0000000a;
  background-color: white;
}

.filter-options-general-white {
  padding: 0px 8px 0px 8px;
  border-radius: 16px;
  border: 1px solid #cbcae5;
  background-color: white;

  font-family: DM Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 1px;
  color: #4b487a;
}

.div-card-details[disabled] {
  pointer-events: none;
  opacity: 0.7;
}

.fees-card-area {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 10px;
}

.fees-card-area p {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #55555e;
  margin-left: 5px;
}

.fees-card-area h1 {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  color: #040052;
}

.fees-card-area-fund .line-now {
  position: absolute;
  left: 30px;
}

.fees-card-area-fund .fee-now {
  position: absolute;
  bottom: -50px;
  left: 17px;
}

.fees-card-area-fund p {
  position: absolute;
  bottom: -48px;
  left: 54px;
  font-family: DM Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  color: #55555e;
}

.banktransfer-others .form-group .label-form {
  display: block;
  margin-bottom: 5px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444052;
}

.banktransfer-others .form-group .input-row {
  display: flex;
  width: 100%;
}

.banktransfer-others .form-group .input-row input {
  flex: 1 1;
  margin-bottom: 10px;
  padding: 10px 20px;
  height: 50px;
  font-size: 16px;
  border: 1px solid #cbcae5;
  border-radius: 8px 0 0 8px;
  width: 100%;
  color: #6f6e80;
}

.banktransfer-others .form-group .input-row input:last-child {
  border-radius: 0 8px 8px 0;
  border-left: 0px;
}

.banktransfer-others .form-group .input-row input:focus {
  outline: none;
  border: 1px solid #5a50fe;
  box-shadow: none;
}

.banktransfer-others .form-group .reg-input {
  margin-bottom: 10px;
  padding: 10px 20px;
  height: 50px;
  font-size: 16px;
  border: 1px solid #cbcae5;
  border-radius: 8px;
  width: 100%;
  color: #6f6e80;
}

.banktransfer-others .form-group .reg-input:focus {
  outline: none;
  border: 1px solid #5a50fe;
  box-shadow: none;
}

.banktransfer-others .form-group .country-phone-input .drpdown-button {
  height: 50px !important;
}

.banktransfer-others .form-group .country-phone-input .phone-input {
  height: 50px !important;
}

.banktransfer-others .form__select_flag button {
  height: 50px;
}

.banktransfer-others .form-group .country-phone-input .drpdown-menu {
  width: 366px !important;
}

.banktransfer-others .submit-reg {
  width: 100%;
  padding: 10px;
  border: none;
  cursor: pointer;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  height: 56px;
  background: #5a50fe;
  border-radius: 12px;
}

.banktransfer-others .submit-reg:disabled {
  background-color: #e2e0ff;
}

.fund-card-btn .btn-btn-btn {
  background: #5a50fe;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #ffffff;
  outline: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  width: 100%;
}

.fund-card-btn .btn-btn-btn:disabled {
  opacity: 0.5;
}

.success-card-fund .grey-btn-btn {
  background: #f0f0f0;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  outline: none;
  border: none;
  padding: 10px;
  color: #5a50fe;
  width: 100%;
}

.success-card-fund h1 {
  font-family: "DM Sans";
  font-size: 23px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  color: #444052;
}

.success-card-fund p {
  font-family: "DM Sans";
  font-size: 18px;
  font-weight: 350;
  line-height: 27px;
  letter-spacing: 0px;
  max-width: 480px;
  margin: auto;
}

.billing-details {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  color: #040052;
}

.card-custom-date {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 9999;
  background-color: white;
  /* box-shadow: 0px 2px 8px 0px #0000000A; */
  box-shadow: 0px 4px 12px -3px #11182621;
  border-radius: 8px;
}

.custom-date-filter-options {
  background-color: #f8f9fa;
  padding: 20px;
  width: 188px;
  height: 480px;
  border-radius: 8px 0px 0px 8px;
}

.custom-date-filter-options h1 {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  color: #4b487a;
  margin-bottom: 12px;
}

.custom-date-filter-table {
  background-color: white;
  padding: 20px;
  height: 480px;
  border-radius: 0px 8px 8px 0px;
}

.react-datepicker__header {
  background-color: white !important;
  border-bottom: none !important;
  border-top-left-radius: 0.3rem;
  position: relative;
}

.react-datepicker {
  font-size: 0.8rem;
  background-color: white !important;
  color: #000;
  border: 1px solid white !important;
  border-radius: 0.5rem !important;
  display: inline-block;
  position: relative;
  padding: 0px !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--in-selecting-range {
  background-color: #5a50fe !important;
  border-radius: 5px !important;
  color: white !important;
  border: none !important;
}

.react-datepicker__day--in-range {
  background-color: #5a50fe !important;
  border-radius: 5px !important;
}

.reg-select-date {
  font-family: "DM Sans";
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  color: #444052;
  border: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  padding-right: 30px; /* Adjust the value as needed to move the icon closer */

  background: transparent;
  background-image: url(/static/media/Chevron_down.6a7a666b.svg);
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 1px;
}

.reg-select-date:focus {
  outline: none;
  border: none;
}

.reg-select-year {
  padding: 4px 16px 4px 16px;
  border-radius: 8px;
  background-color: #f8f9fa;
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
}

.reg-select-year:focus {
  outline: none;
  border: none;
}

/* .default-avatar {
  display: flex;
  justify-content: center;
  align-items:center;
} */

.custom-styles {
  background-color: #ecebff;
  border: 1px solid #cbcae5;
}

.card-transaction-details-styling h1 {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  color: #444052;
  margin: 0;
}

.card-transaction-details-styling p {
  font-family: "DM Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  color: #444052;
  margin: 0;
}

.card-transaction-details-styling h2 {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  color: #74748a;
  margin-bottom: 10px;
}

.card-transaction-details-styling h3 {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  color: #040052;
  margin-bottom: 10px;
}

.card-transaction-details-styling a {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  color: #5a50fe;
}

.for-notifications {
  border: 1px solid #cbcae5;
  padding: 12px;
  border-radius: 8px;
  background-color: #f8f7ff;
}

.for-notifications p {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #5a50fe;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.for-notifications .cls-btn {
  width: 30px;
  cursor: pointer;
}

.ev-primary-btn {
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  background: #5a50fe;
  border-radius: 8px;
}

.ev-primary-btn:disabled {
  background-color: #e2e0ff;
}

.ev-primary-btn:focus {
  outline: none;
  border: none;
}

.add-ip-address {
  padding: 20px;
  max-width: 550px;
  margin: auto;
}

.add-ip-address img {
  width: 50px;
}

.add-ip-address h1 {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 700;
}

.add-ip-address p {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  max-width: 434px;
  margin: auto;
}

.add-ip-address h2 {
  color: #966602;
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.add-ip-address h2 span {
  font-weight: 700;
}

.custom-radio-group-general {
  display: flex;
  flex-direction: column;
}

.custom-radio-general {
  position: relative;
  padding-left: 54px;
  padding-top: 16px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  height: 100px;
  font-size: 16px;
  border: 1px solid #cbcae5;
  border-radius: 8px;
  /* max-width: 550px; */
  margin: auto;
  /* margin-bottom: 15px; */
}

.custom-radio-general input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-radio-general .checkmark {
  position: absolute;
  top: 18px;
  left: 20px;
  height: 20px;
  width: 20px;
  /* background-color: #ccc; */
  border: 1px solid #444052;
  border-radius: 50%;
}

.custom-radio-general:hover input ~ .checkmark {
  border: 1px solid #5a50fe;
}

.custom-radio-general input:checked ~ .checkmark {
  border: 1px solid #5a50fe;
}

.custom-radio-general .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-radio-general input:checked ~ .checkmark:after {
  display: block;
}

.custom-radio-general .checkmark:after {
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #5a50fe;
}

.custom-radio-general .biz-title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #444052;
}

.custom-radio-general .biz-desc {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4b487a;
  max-width: 437px;
}

.custom-radio-group-general .ant-tag-green {
  color: #0f730e !important;
  background: #e2fccc !important;
  border-color: #99e558 !important;
  font-family: "DM Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  border-radius: 4px !important;
  width: 110px;
}

.tag-style .ant-tag-green {
  color: #0f730e !important;
  background: #e2fccc !important;
  border-color: #99e558 !important;
  font-family: "DM Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  border-radius: 24px !important;
  width: 55px;
}

.warning-alert {
  background-color: #fcf9ed;
  padding: 20px;
  border-radius: 4px;
  max-width: 361px;
  margin: auto;
}

.warning-icon {
  width: 20px !important;
}

.blink {
  animation: blinker 2s step-start infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.text-area-additional-info {
  height: 152px !important;
}

.capitalize-text {
  text-transform: capitalize;
  display: flex;
  align-items: center;
}

.create-card-container {
  width: 480px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.side-nav {
  @media screen and (max-width: 480px) {
    position: absolute;
    height: 100%;
  }
}

.modal-wrapper {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
  /* width: 480px; */
  /* padding: 40px; */

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  .banktransfer-others {
    width: auto;
  }

  & > div {
    flex: none;
  }
}
.wallet-skeleton {
  width: 100px;

  ul {
    margin-bottom: 0;
  }
}

.clickable-row {
  cursor: pointer;
}

.body-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
}
.body-div .logo {
  position: absolute;
  top: 20px;
  left: 60px;
  width: 200px;
}

.body-div .reg-link {
  margin-top:20px;
}

.body-div .reg-link p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #4b487a;
}

.body-div .reg-link p a {
  color: #5a50fe;
  font-weight: 700;
}

.body-div .title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 24px;
  color: #040052;
  text-align: center;
  margin-bottom: 20px;
}

.body-div .paragraph {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #4b487a;
  margin-bottom: 30px;
}

.body-div .login-form {
  width: 100%;
  max-width: 400px;
  padding: 10px;
}

.body-div .form-group {
  margin-bottom: 15px;
}

.body-div .label-form {
  display: block;
  margin-bottom: 5px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444052;
}

.body-div .ant-checkbox-wrapper {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #74748a;
}

.body-div .auth-input {
  width: 100%;
  padding: 10px 20px;
  height: 56px;
  font-size: 16px;
  background: transparent;
  border: 1px solid #cbcae5;
  border-radius: 8px !important;
}

.body-div .auth-input:focus {
  outline: none !important;
  border: 1px solid #5a50fe !important;
  box-shadow: none;
}

/* .ant-input-focused,
.ant-input-focused:hover,
.ant-input-focused:active {
    background-color: transparent !important;
} */

.body-div .external-link {
  background: transparent;
  border: none;
  outline: none;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #5a50fe;
  cursor: pointer;
}

.no-otp {
  border: none;
  outline: none;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: black;
}

.body-div .submit-btn {
  width: 100%;
  padding: 10px;
  border: none;
  cursor: pointer;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  height: 56px;
  background: #5a50fe;
  border-radius: 12px;
}

.body-div .submit-btn:disabled {
  background-color:#E2E0FF;
  cursor: not-allowed;
}

.body-div .submit-btn:focus {
  outline: none;
  border:none;
}

.body-div .auth-footer {
  position: absolute;
  bottom: 30px;
  /* width: 30%; */
}

.body-div .auth-footer .under-auth-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 45px;
  gap: 45px;
  padding: 20px;
}

.body-div .auth-footer p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #55555e;
}

.body-div .auth-footer p a:hover {
  color: #5a50fe;
}

@media only screen and (max-width: 1024px) {
  .body-div .logo {
    left: 20px;
    width: 100px;
  }

  .body-div .auth-footer {
    bottom: 10px;
  }
}

.no-business-hero-section {
  display: flex;
  height: 100vh;
  align-items: center;
  padding: 0 150px;
}

.no-business-hero-section .text-column {
  flex: 1 1;
  padding: 20px;
}
.no-business-hero-section .text-column h1 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 28px;
  color: #444052;
}

.no-business-hero-section .text-column h2 {
  max-width: 452.96px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 40px;
  color: #040052;
}

.no-business-hero-section .text-column h3 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #4b487a;
}

.no-business-hero-section .text-column h3 span {
  color: #5a50fe;
  cursor: pointer;
}

.no-business-hero-section .text-column p {
  max-width: 363.76px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #4b487a;
}

.no-business-hero-section .text-column p a {
  color: #5a50fe;
}

.no-business-hero-section .text-column .visit-site-second {
  background: #5a50fe;
  border-radius: 12px;
  /* width: 100%; */
  padding: 15px 70px;
  border: none;
  cursor: pointer;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  height: 54px;
  max-width: 285px;
  margin-bottom: 20px;
}

.no-business-hero-section .text-column button {
  background: #5a50fe;
  border-radius: 12px;
  /* width: 100%; */
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  height: 54px;
  max-width: 285px;
  margin-bottom: 20px;
}

.no-business-hero-section .text-column .visit-site {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #5a50fe;
}

.no-business-hero-section .text-column .visit-site  img {
  width: 20px;
}

.no-business-hero-section .image-column {
  flex: 1 1;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-business-hero-section .image-column img {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .no-business-hero-section {
    flex-direction: column;
    justify-content: center;
    padding: 20px 20px 0 20px;
  }

  .no-business-hero-section .text-column,
  .no-business-hero-section .image-column {
    flex: 1 1;
    padding: 10px;
  }

  .no-business-hero-section .image-column img {
    max-height: 50%;
  }
}

.sign-container {
  display: flex;
  flex-direction: column;
}

.sign-container .logo {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 200px;
  z-index: 1;
}

.sign-container .login-link {
  position: absolute;
  top: 20px;
  right: 60px;
}

.sign-container .login-link p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4b487a;
}

.sign-container .login-link p a {
  color: #5a50fe;
  font-weight: 700;
}

.sign-container .content {
  display: flex;
  height: 100vh;
}

.sign-container .image-container {
  flex: 0 0 40%; /* Change the width percentage here */
  position: relative;
  background-color: #f0f0f0;
  overflow: hidden;
}

.sign-container .image-container .image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.sign-container .image-container .image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}

/* .sign-container .image-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  );
} */

.sign-container .form-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #ffffff;
}

.sign-container .form-container h2 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  color: #040052;
  max-width: 359px;
  margin-bottom: 20px;
}

.sign-container .form-container .form-group {
  margin-bottom: 15px;
}

.sign-container .form-container .form-group .label-form {
  display: block;
  margin-bottom: 5px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444052;
}
.sign-container .form-details {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
}

.sign-container .form-details .custom-width {
  width: 100%;
  max-width: 280px;
}

.sign-container .form-details .terms-cond {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1px;
  color: #4b487a;
  margin-top: 15px;
}

.sign-container .form-details .input-row {
  display: flex;
  width: 100%;
}

.sign-container .form-details .input-row input {
  flex: 1 1;
  margin-bottom: 10px;
  padding: 10px 20px;
  height: 56px;
  font-size: 16px;
  border: 1px solid #cbcae5;
  border-radius: 8px 0 0 8px;
  width: 100%;
  color: #6f6e80;
}

.sign-container .form-details .input-row input:last-child {
  border-radius: 0 8px 8px 0;
  border-left: 0px;
}

.sign-container .form-details .input-row input:focus {
  outline: none;
  border: 1px solid #5a50fe;
  box-shadow: none;
}

.sign-container .form-details .reg-input {
  margin-bottom: 10px;
  padding: 10px 20px;
  height: 56px;
  font-size: 16px;
  border: 1px solid #cbcae5;
  border-radius: 8px;
  width: 100%;
  color: #6f6e80;
}

.sign-container .form-details .reg-input:focus {
  outline: none;
  border: 1px solid #5a50fe;
  box-shadow: none;
}

.sign-container .form-details .auth-input {
  width: 100%;
  padding: 10px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #cbcae5;
  border-radius: 8px !important;
  color: #6f6e80;
}

.sign-container .form-details .auth-input:focus {
  outline: none !important;
  border: 1px solid #5a50fe !important;
  box-shadow: none;
}

.verify-edit .country-phone-input .drpdown-button {
  height: 40px !important;
}
.verify-edit .country-phone-input .phone-input {
  height: 40px !important;
  width: 52% !important;
}

.sign-container .form-details .submit-reg {
  width: 100%;
  padding: 10px;
  border: none;
  cursor: pointer;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  height: 56px;
  background: #5a50fe;
  border-radius: 12px;
}

.sign-container .form-details .submit-reg:disabled {
  background-color: #e2e0ff;
}

.body-div .submit-btn:focus {
  outline: none;
  border:none;
}

.custom-radio-group {
  display: flex;
  flex-direction: column;
}

.custom-radio {
  position: relative;
  padding-left: 54px;
  padding-top: 16px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;

  margin-bottom: 15px;
  height: 56px;
  font-size: 16px;
  border: 1px solid #cbcae5;
  border-radius: 8px;
}

.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-radio .checkmark {
  position: absolute;
  top: 18px;
  left: 20px;
  height: 20px;
  width: 20px;
  /* background-color: #ccc; */
  border: 1px solid #444052;
  border-radius: 50%;
}

.custom-radio:hover input ~ .checkmark {
  border: 1px solid #5a50fe;
}

.custom-radio input:checked ~ .checkmark {
  border: 1px solid #5a50fe;
}

.custom-radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-radio input:checked ~ .checkmark:after {
  display: block;
}

.custom-radio .checkmark:after {
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #5a50fe;
}

.sign-container .form-container .progress-bar {
  width: 100px;
  height: 8px;
  background-color: #e9ebed;
  border-radius: 10px;
  position: relative;
}

.sign-container .form-container .progress-step {
  position: absolute;
  width: 20px;
  height: 8px;
  border-radius: 10px;
  background-color: #5a50fe;
}

.sign-container .form-container .back-button {
  background: transparent;
  border: none;
  outline: none;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #74748a;
  cursor: pointer;
}

.custom-radio-group-two {
  display: flex;
  flex-direction: column;
}

.custom-radio-two {
  position: relative;
  padding-left: 50px;
  padding-top: 17px;
  padding-bottom: 90px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;

  margin-bottom: 10px;
  height: 56px;
  font-size: 14px;
  border: 1px solid #cbcae5;
  border-radius: 8px;
}

.custom-radio-two input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-radio-two .checkmarktwo {
  position: absolute;
  top: 42px;
  left: 17px;
  height: 20px;
  width: 20px;
  /* background-color: #ccc; */
  border: 1px solid #444052;
  border-radius: 50%;
}

.custom-radio-two:hover input ~ .checkmarktwo {
  border: 1px solid #5a50fe;
}

.custom-radio-two input:checked ~ .checkmarktwo {
  border: 1px solid #5a50fe;
}

.custom-radio-two .checkmarktwo:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-radio-two input:checked ~ .checkmarktwo:after {
  display: block;
}

.custom-radio-two .checkmarktwo:after {
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #5a50fe;
}

.biz-title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #040052;
}

.biz-desc {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4b487a;
  max-width: 312px;
}

.sign-container .form-details .verify-box {
  padding: 16px;
  max-width: 400px;
  background: #ffffff;
  border: 1px solid #e9ebed;
  border-radius: 8px;
  margin: 15px 0;
}

.sign-container .form-details .verify-box .verify-title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #444052;
}

.sign-container .form-details .verify-box .verify-btn {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #ffffff;
  padding: 8px 20px;
  background: #5a50fe;
  border-radius: 8px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 8px;
}

.sign-container .form-details .verify-box .verify-btn:focus {
  outline: none;
  border: none;
}

.sign-container .form-details .verify-box .verify-btn:disabled {
  background-color: #e2e0ff;
  cursor: not-allowed;
}

.email-edit-box {
  background: #f8f9fa;
  border-radius: 4px;
  padding: 8px;
  height: 44px;
}

.verify-edit-btn::before {
  content: "|";
  margin-right: 8px;
  color: #cbcae5;
}

.verify-edit-btn {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #5a50fe;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.verify-edit-btn:disabled {
  color: #e2e0ff;
  cursor: not-allowed;
}

.verify-confirm-btn {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #5a50fe;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.re-enter-email-input {
  border: 1px solid #cbcae5;
  border-radius: 8px;

  padding: 8px 16px;
  height: 40px;
  width: 75%;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6f6e80;
}

.re-enter-email-input:focus {
  border: 1px solid #5a50fe;
  outline: none;
}

.verify-box .formInput {
  width: 90% !important;
  height: 40px !important;
}

.verify-otp-view-p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #4b487a;
  max-width: 368px;
  margin: 0;
}

.verify-otp-view-p span {
  text-decoration: underline;
}

.verify-resend-otp-btn {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1.5px;
  color: #5a50fe;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.verify-resend-otp-btn:disabled {
  color: #e2e0ff;
}

.sign-container .form-details .verify-box .verify-email-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #444052;
}

.sign-container .form-details .verify-box .verify-email-text img {
  width: 15px;
}

@media only screen and (max-width: 1024px) {
  .sign-container .logo {
    left: 20px;
    width: 100px;
  }
  .sign-container .login-link p {
    font-size: 12px;
    color: white;
  }

  .sign-container .login-link {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1000;
  }
  .sign-container .content {
    flex-direction: column;
  }
}

.country-phone-input {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.country-phone-input .drpdown {
  position: relative;
  display: inline-block;
}

.country-phone-input .drpdown-button {
  border: 1px solid #cbcae5;
  border-right: none;
  border-radius: 8px 0 0 8px;
  padding: 8px 0px 8px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 56px;
  background: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444052;
}

.country-phone-input .drpdown-button:focus {
  border-radius: 8px 0 0 8px;
  outline: none;
  border: 1px solid #cbcae5;
  border-right: none;
}

.country-phone-input .drpdown-button .arrow-btn {
  width: 20px;
  margin-left: 10px;
}

.country-phone-input .drpdown-button span {
  margin-left: 10px;
}

.country-phone-input .drpdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  /* width: 100%; */
  width: 400px;
  background-color: #ffffff;
  border: 1px solid #cbcae5;
  border-radius: 4px;
  margin-top: 4px;
  list-style: none;
  padding: 10px;
  z-index: 1;
  overflow-y: scroll;
  height: 150px;
}

.country-phone-input .drpdown-menu .search-input {
  background-color: #ffffff;
  border: 1px solid #cbcae5;
  padding: 0 16px;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6f6e80;
  margin-bottom: 5px;
}

.country-phone-input .drpdown-menu .drpdown-item {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444052;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 5px;
}

.country-phone-input .drpdown-menu .drpdown-item:hover {
  background-color: #E9EBED;
}

.country-phone-input .drpdown-menu .drpdown-item span {
  margin-left: 5px;
}

.country-phone-input .phone-input {
  background-color: #ffffff;
  border: 1px solid #cbcae5;
  border-left: none;
  padding: 0 16px;
  border-radius: 0 8px 8px 0;
  height: 56px;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6f6e80;
}

.country-phone-input .phone-input:focus {
  border-radius: 0 8px 8px 0;
  outline: none;
  border: 1px solid #cbcae5;
  border-left: none;
}

.styled-modal_overlay__3-Wr3{position:fixed;top:0;left:0;background-color:rgba(4,0,82,.3);z-index:1000;height:100%;width:100%}.styled-modal_header__3e3eL{position:relative;top:0px;left:0px;width:100vw;height:10vh;z-index:1000}@media screen and (max-width: 768px){.styled-modal_header__3e3eL{height:0;padding:24px;display:flex;align-items:center;justify-content:flex-end;background-color:#fff}}.styled-modal_close__3y_Gi{position:absolute;right:10px;top:40px;cursor:pointer;padding:4px;width:32px;height:32px;background:#fff;border-radius:6px;padding:0}@media screen and (max-width: 768px){.styled-modal_close__3y_Gi{position:absolute;top:24px;height:24px;width:24px;right:24px}}.styled-modal_body__EWfBw{top:0px;left:0px;width:100%;height:90vh;border-radius:12px 12px 0 0;z-index:1000;background-color:#fff;overflow-y:auto}@media screen and (max-width: 768px){.styled-modal_body__EWfBw{border-radius:0;height:calc(100% - 32px)}}
.banner_banner__3UuV2{border-radius:8px;border:1px solid #faebff;background:#fbf0ff;padding:8px 12px;grid-gap:16px;gap:16px;display:flex;justify-content:space-between;flex-direction:column;align-items:center}@media(min-width: 1024px){.banner_banner__3UuV2{flex-direction:row}}.banner_cta__2mf83{display:flex;justify-content:space-between;grid-gap:16px;gap:16px;width:100%}@media(min-width: 1024px){.banner_cta__2mf83{width:auto}}.banner_closeButton__2x0DE{padding:0}.banner_content__1e9YF{display:flex;align-items:center;grid-gap:16px;gap:16px;color:#8f59a0}.banner_content__1e9YF>p,.banner_content__1e9YF i,.banner_content__1e9YF a,.banner_content__1e9YF a:hover{color:inherit}.banner_icon__2tLmM{width:24px;height:24px;border-radius:50%;background-color:#eaa5ff;display:flex;align-items:center;justify-content:center;color:#8f59a0}.banner_closeIcon__3TJwt{width:24px;height:24px}.banner_iconWrapper__LMaPH{border:6px solid #f8e1ff;height:40px;width:40px;border-radius:28px;display:flex;align-items:center;justify-content:center}
.button_button__26HMJ{padding:4px 10px;border-radius:8px;display:flex;align-items:center;justify-content:center;grid-gap:4px;gap:4px;border:none}.button_button__26HMJ.button_primary__1mAyx{background-color:#5a50fe;color:#fff}.button_button__26HMJ.button_primary__1mAyx:active{background-color:rgb(9.71875,.8835227273,154.6164772727)}.button_button__26HMJ.button_primary__1mAyx:hover{background-color:rgb(11.3125,1.0284090909,179.9715909091)}.button_button__26HMJ.button_primary__1mAyx:disabled:hover{background-color:#e2e0ff}.button_button__26HMJ.button_transparent__2No2D{background-color:rgba(0,0,0,0)}.button_button__26HMJ.button_small__1PCFY{padding:8px 16px}.button_button__26HMJ.button_medium__3ZYOj{padding:12px 16px}.button_button__26HMJ:disabled{background-color:#e2e0ff;cursor:not-allowed}.button_button__26HMJ .button_link__voCJk{background-color:rgba(0,0,0,0);color:#5a50fe;padding:0;border:none}
.styles_loader__2G9sJ{animation:styles_rotate__2n0Y1 2s linear infinite}@keyframes styles_rotate__2n0Y1{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}.styles_small__333EG{width:12px;height:12px}.styles_medium__uYmdk{width:24px;height:24px}.styles_large__15Gbo{width:24px;height:24px}
.tagBanner_text__2GAuD{font-size:16px;padding:0px;margin:0px}.tagBanner_bold__3HWe3{font-weight:bold;margin-right:6px}
/* App.css */
.custom-date-input {
  display: flex;
  align-items: center;
  width: 240px; /* Adjust the width as needed */
  height: 45px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #cbcae5;
}

.custom-date-input input {
  border: none;
  outline: none;
  width: 100%;
}

.icon {
  margin-right: 8px; /* Add space between the icon and input field */
}

.Cards_container__xfkU-{padding:24px}.Cards_buttonContainer__az03j{display:flex;justify-content:space-between;margin-top:1.5rem;grid-gap:8px;gap:8px}@media screen and (max-width: 768px){.Cards_buttonContainer__az03j{width:100%}}
.styles_card__3H0Ct{padding:16px;border-radius:4px;border:1px solid #e4eaf2;background:#fbf0ff;display:grid;grid-template-columns:auto 1fr;grid-gap:16px;gap:16px}.styles_card__3H0Ct p{color:#55555e;padding:0;margin:0 0 4px 0}.styles_card__3H0Ct .styles_button__1tkDQ.styles_button__1tkDQ{padding:4px 10px;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}.styles_container__3vPza{display:flex;flex-direction:column}.styles_icon__EdUUX{width:32px;height:32px}
.styles_container__1OjGl{position:relative}.styles_container__1OjGl .styles_copy__31dhb{position:absolute;right:24px;top:50%;transform:translateY(-50%)}.styles_textContainer__2q7Dt{padding:6px 16px 8px 16px;background-color:#f4f6f7;border-radius:16px;display:flex;align-items:center;justify-content:space-between;grid-gap:8px;gap:8px}.styles_copyIcon__30r-2 path{fill:#5a50fe}
.styles_pill__2VOZo{padding:8px;border-radius:24px;background-color:#128a11;font-size:12px;color:#fff;letter-spacing:1px;height:24px;display:flex;align-items:center}
.steps_wrapper__3b1d4{font-size:14px;width:100%;display:flex;flex-direction:row}@media(min-width: 1024px){.steps_wrapper__3b1d4{grid-gap:32px;gap:32px;flex-direction:column}}.steps_stepWrapper__1I3n3{display:flex;width:100%;align-items:baseline;flex:1 1;border:none;background:rgba(0,0,0,0);padding:4px 0;position:relative;cursor:none}@media(min-width: 1024px){.steps_stepWrapper__1I3n3:not(:first-of-type){position:static}}.steps_stepWrapper__1I3n3:last-of-type{flex:0 1}.steps_stepWrapper__1I3n3:hover .steps_step__1pGfV:not(.steps_active__1bmmS):not(.steps_completed__aZsrg){border-color:#5a50fe;color:#5a50fe}.steps_stepWrapper__1I3n3:hover .steps_title__3RBJ9:not(.steps_activeTitle__TAgtF){color:#5a50fe}.steps_stepWrapper__1I3n3:last-child .steps_step__1pGfV::after{display:none}.steps_clickable__2oGg6{cursor:pointer}.steps_step__1pGfV{border:1px solid #e9ebed;width:32px;height:32px;border-radius:50%;display:flex;align-items:center;justify-content:center}@media(min-width: 1024px){.steps_step__1pGfV{position:relative;margin-right:16px}}.steps_step__1pGfV::after{position:absolute;content:"";right:16px;height:4px;width:calc(100% - 62px);display:block;top:50%;border-radius:2px;background:#e9ebed}@media(min-width: 1024px){.steps_step__1pGfV::after{width:4px;height:16px;bottom:-30px;top:unset}}.steps_step__1pGfV:last-child{content:none}.steps_completed__aZsrg{background-color:#128a11;color:#fff}.steps_active__1bmmS{background-color:#5a50fe;color:#fff}.steps_activeTitle__TAgtF{font-weight:bold}.steps_title__3RBJ9{text-overflow:ellipsis;margin:0;display:none}@media(min-width: 1024px){.steps_title__3RBJ9{display:block}}
.StepsWrapper_wrapper__3On1r{height:100%;width:100%;display:flex;flex-direction:column;padding:0 24px 24px;border-radius:12px 12px 0 0;z-index:1000;background-color:#fff}@media(min-width: 1024px){.StepsWrapper_wrapper__3On1r{display:grid;grid-template-columns:1fr 2fr;padding:40px 64px}}.StepsWrapper_header__2WU3f{background-color:#fff;display:flex;align-items:center;justify-content:space-between;padding:0;margin-bottom:16px;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content}@media(min-width: 1024px){.StepsWrapper_header__2WU3f{margin-bottom:32px}}.StepsWrapper_title__ZqDtd{display:inline-block;padding:16px 0;font-size:23px;font-weight:bold}@media screen and (max-width: 480px){.StepsWrapper_title__ZqDtd{font-size:18px}}.StepsWrapper_back__hWIbV{color:#74748a;display:flex;align-items:center;position:fixed;top:24px;z-index:1000000000000;isolation:isolate;padding:0;height:24px;margin-right:0}.StepsWrapper_back__hWIbV:hover,.StepsWrapper_back__hWIbV:focus{color:inherit}@media(min-width: 1024px){.StepsWrapper_back__hWIbV{position:static}}.StepsWrapper_steps__30YxS{margin:24px 0}.StepsWrapper_step__3jDtv{min-height:auto}.StepsWrapper_step__3jDtv:not(:first-of-type){margin:8px 0}.StepsWrapper_content__SNTVi{background-color:#fff;overflow-y:auto;padding-top:16px;height:100%;width:100%;display:grid;grid-template-columns:minmax(0, 1fr);margin-top:16px;height:calc(100% - 128px)}@media(min-width: 1024px){.StepsWrapper_content__SNTVi{grid-template-columns:repeat(2, 1fr);margin-right:0;height:auto}}.StepsWrapper_aside__AReN8{box-shadow:none;background-color:#fff;position:relative;flex:none;padding:0;width:auto}@media(min-width: 1024px){.StepsWrapper_aside__AReN8{width:352px}}
.payout-modal-body {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
  width: 40px;
  padding: 40px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.payout-modal-text > div {
  /* flex: 1 1 20%; */

  &:empty {
    /* display: none; */
  }
}

.payout-modal-text .selected {
  display: flex;
  justify-content: center;
}

.payout-modal-text .selected .grey-bg-name {
  background: #f6f6f7;
  border-radius: 4px;
  margin-top: 10px;
  padding: 10px 16px;
  max-width: 300px;
  width: 300px;
}

.payout-modal-text .selected .grey-bg-name .receipient-name {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #1c223c;
  margin-bottom: 0px;
}

.first-view-payout {
  width: 480px;
  margin: auto;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.first-view-payout h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #1c223c;
}

.first-view-payout .gb-bg {
  padding: 10px;

  border: 1px solid #e5e5e5;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
}

.first-view-payout .gb-bg img {
  margin-right: 10px;
}

.first-view-payout p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #48484d;
}

.grey-btn {
  background: #f0f0f0;
  border-radius: 4px;
  font-style: normal;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: #5a50fe;
  height: 57px;
}

.grey-btn:hover {
  color: #5a50fe;
  border: none;
  background: #f0f0f0;
}

.pay-blue-btn {
  background: #5a50fe;
  border-radius: 4px;
  /* width:100%;
  max-width:100%; */
  font-style: normal;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: white;
  height: 57px;
}

.pay-blue-btn:hover {
  background: #5a50fe;
  color: white;
}

.input-par {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.015em;
  color: #555555;
}

.input-par span {
  color: #2f80ed;
}

.payment-notify-card {
  background: #fffbe6;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
}

.payment-notify-card img {
  margin-top: 5px;
}

.payment-notify-card p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.015em;

  color: #806900;
  max-width: 200px;
}

.payout-receipt .payout-success-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #48484d;
  margin: 10px 0 20px 0;
}

.payout-receipt .payout-sent {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #48484d;
}

.payout-receipt .payout-amount {
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 39px;
  letter-spacing: 0.01em;
  color: #1c223c;
}

.payout-receipt .payout-initials {
  background-color: #ff84bf;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #ffffff;
  padding: 5px;
  border-radius: 50%;
}

.payout-receipt .payout-user-name {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #1c223c;
}

.payout-receipt a {
  text-decoration: none;
}

.payout-receipt .payout-view-more {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #1c223c;
}

.payout-receipt .beneficiary-payout {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: #2f80ed;
  margin-bottom: 20px;
  text-align: center;
}

.playground-pay .ant-space-item .ant-radio-wrapper {
  padding: 10px;
  width: 100%;
  border: 1px solid #35438e;
  border-radius: 4px;
}

.table-in {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ff84bf;
  padding: 5px;
  color: white;
}

.payout-modal-text .selected .grey-bg-name .benefit-name {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #1c223c;
  margin-bottom: 0px;
}

.in-houseModal h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 39px;
  letter-spacing: 0.01em;
  color: #1c223c;
}

.in-houseModal .ant-space-item .ant-radio-wrapper {
  padding: 10px;
  width: 100%;
  border: 1px solid #35438e;
  border-radius: 4px;
}

.payout_form__34fLv{width:100%}.payout_formWrapper__3xTHL{flex:1 1;max-width:480px}.payout_wrapper__3naUV{display:flex;justify-content:space-between;flex-direction:column}@media(min-width: 1024px){.payout_wrapper__3naUV{flex-direction:row}}.payout_wrapper__3naUV>div{flex:1 1}@media(min-width: 1024px){.payout_wrapper__3naUV>div:only-child{min-width:480px}}.payout_userCard__1XSHe{width:100%;order:-1}@media(min-width: 1024px){.payout_userCard__1XSHe{order:0}}.payout_footer__23Wem{position:absolute;left:24px;right:24px;bottom:24px}@media(min-width: 1024px){.payout_footer__23Wem{position:relative}}
.select-phone-input {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.select-phone-input .drpdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.select-phone-input .drpdown-button {
  border: 1px solid #cbcae5;
  border-radius: 8px;
  padding: 8px 18px 8px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 50px;
  background: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  color: #444052;
}

.select-phone-input .drpdown-button:focus {
  border-radius: 8px;
  outline: none;
  border: 1px solid #cbcae5;
}

.select-phone-input .drpdown-button .arrow-btn {
  width: 20px;
  margin-left: 10px;
}

.select-phone-input .drpdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  /* width: 365px; */
  background-color: #ffffff;
  border-radius: 8px;
  margin-top: 4px;
  list-style: none;
  padding: 20px 10px;
  z-index: 1;
  overflow-y: scroll;
  height: 370px;
  box-shadow: 0px 2px 8px 0px #0000000A;
  box-shadow: 0px 4px 12px -3px #11182621;
}

.select-phone-input .drpdown-menu .search-input {
  background-color: #ffffff;
  border: 1px solid #cbcae5;
  padding: 0 16px;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6f6e80;
  margin-bottom: 5px;
}

.select-phone-input .drpdown-menu .drpdown-item {
  color: #040052;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
}

.select-phone-input .drpdown-menu .drpdown-item-first {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  color: #5a50fe;
  cursor: pointer;
}

/* .select-phone-input .drpdown-menu .drpdown-item:hover {
  background-color: #e9ebed;
} */

.beneficiary_wrapper__2HBNN{width:100%;height:100%}@media screen and (max-width: 768px){.beneficiary_wrapper__2HBNN{flex-direction:column;justify-content:unset}}.beneficiary_wrapper__2HBNN>div{flex:1 1}.beneficiary_wrapper__2HBNN>div:only-child{flex:none}.beneficiary_content__33zSd{height:100%}@media screen and (max-width: 768px){.beneficiary_content__33zSd{width:auto}}.beneficiary_cardWrapper__3lESG{display:flex;justify-content:center;order:-1}@media(min-width: 640px){.beneficiary_cardWrapper__3lESG{order:1}}.beneficiary_card__2ONkX{background:#f6f6f7;border-radius:4px;margin-top:10px;padding:10px 16px;width:300px;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content}@media screen and (max-width: 768px){.beneficiary_card__2ONkX{width:100%;margin-bottom:16px}}.beneficiary_label__1q51Q{margin-bottom:8px;font-weight:bold}.beneficiary_contentWrapper__xBelc{display:flex;flex-direction:column}@media(min-width: 640px){.beneficiary_contentWrapper__xBelc{display:grid}}@media screen and (max-width: 768px){.beneficiary_footer__3sROy{width:100%;display:flex;justify-content:center}.beneficiary_footer__3sROy>div{width:100%}}.beneficiary_button__1EYwN{float:right}@media screen and (max-width: 768px){.beneficiary_button__1EYwN{width:100%;float:none}}.beneficiary_tabs__3c_pU{margin:16px 0}
.input_input__3mG11{padding:16px 24px;border-radius:8px;border:1px solid #5a50fe;font-size:16px;width:100%}.input_input__3mG11:focus,.input_input__3mG11:focus-visible{border-width:2px;outline:none}.input_container__2urAG{border:none;display:flex;flex-direction:column;align-items:flex-start;font-size:16px;font-weight:400;width:100%}.input_inputcontainer__32_q2{position:relative;width:100%}.input_icon__3ALP2{width:24px;height:24px;display:flex;align-items:center;justify-content:center;position:absolute;right:24px;top:50%;transform:translateY(-50%);z-index:1;padding:inherit;background-color:#fff}.input_label__13beI{margin-bottom:12px}.input_error__1aEmA{border-color:#ea0016}.input_errorMessage__1KXIy{color:#ea0016;margin-top:14px;font-size:14px}.input_errorMessage__1KXIy svg,.input_errorMessage__1KXIy path{fill:currentColor}
.styles_icon__30EFE{width:14px;height:14px}
.requestTagsModal_body__2Ity9{display:grid;grid-template-columns:1fr;width:100%;height:100%;text-align:center;padding:24px}@media(min-width: 1024px){.requestTagsModal_body__2Ity9{grid-template-columns:repeat(3, 1fr);grid-template-areas:". middle .";padding:80px}}.requestTagsModal_container__2rzPz{display:flex;flex-direction:column;align-items:center;width:100%;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content}@media(min-width: 1024px){.requestTagsModal_container__2rzPz{grid-area:middle}}.requestTagsModal_button__1abq0{margin-top:40px;width:100%}.requestTagsModal_qRCodeContainer__1KD8N{width:175px;height:175px;padding:20px}.requestTagsModal_qRCode__3xrMu{width:100%;height:100%}.requestTagsModal_description__iaHAi p{font-size:14px;padding:0;margin:0}.requestTagsModal_description__iaHAi p:first-of-type{font-weight:bold;margin-bottom:8px;font-weight:16px}.requestTagsModal_title__3xw0y{font-weight:bold}.requestTagsModal_wrapper__32uzL{display:flex;flex-direction:column;grid-gap:16px;gap:16px;align-items:center}.requestTagsModal_input__1YK4I{flex:1 1}.requestTagsModal_download__1hEzq{color:#5a50fe;font-weight:bold;display:flex;justify-content:center;align-items:baseline;grid-gap:4px;gap:4px;text-decoration:none}.requestTagsModal_downloadIcon__Rnx9x{width:24px;height:24px;display:flex;justify-content:center}.requestTagsModal_tag__2L4J3{display:flex;justify-content:space-between;grid-gap:16px;gap:16px;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}
