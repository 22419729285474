.payout-modal-body {
  display: flex;
  gap: 12px;
  width: 40px;
  padding: 40px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.payout-modal-text > div {
  /* flex: 1 1 20%; */

  &:empty {
    /* display: none; */
  }
}

.payout-modal-text .selected {
  display: flex;
  justify-content: center;
}

.payout-modal-text .selected .grey-bg-name {
  background: #f6f6f7;
  border-radius: 4px;
  margin-top: 10px;
  padding: 10px 16px;
  max-width: 300px;
  width: 300px;
}

.payout-modal-text .selected .grey-bg-name .receipient-name {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #1c223c;
  margin-bottom: 0px;
}

.first-view-payout {
  width: 480px;
  margin: auto;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.first-view-payout h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #1c223c;
}

.first-view-payout .gb-bg {
  padding: 10px;

  border: 1px solid #e5e5e5;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
}

.first-view-payout .gb-bg img {
  margin-right: 10px;
}

.first-view-payout p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #48484d;
}

.grey-btn {
  background: #f0f0f0;
  border-radius: 4px;
  font-style: normal;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: #5a50fe;
  height: 57px;
}

.grey-btn:hover {
  color: #5a50fe;
  border: none;
  background: #f0f0f0;
}

.pay-blue-btn {
  background: #5a50fe;
  border-radius: 4px;
  /* width:100%;
  max-width:100%; */
  font-style: normal;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: white;
  height: 57px;
}

.pay-blue-btn:hover {
  background: #5a50fe;
  color: white;
}

.input-par {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.015em;
  color: #555555;
}

.input-par span {
  color: #2f80ed;
}

.payment-notify-card {
  background: #fffbe6;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
}

.payment-notify-card img {
  margin-top: 5px;
}

.payment-notify-card p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.015em;

  color: #806900;
  max-width: 200px;
}

.payout-receipt .payout-success-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #48484d;
  margin: 10px 0 20px 0;
}

.payout-receipt .payout-sent {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #48484d;
}

.payout-receipt .payout-amount {
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 39px;
  letter-spacing: 0.01em;
  color: #1c223c;
}

.payout-receipt .payout-initials {
  background-color: #ff84bf;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #ffffff;
  padding: 5px;
  border-radius: 50%;
}

.payout-receipt .payout-user-name {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #1c223c;
}

.payout-receipt a {
  text-decoration: none;
}

.payout-receipt .payout-view-more {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #1c223c;
}

.payout-receipt .beneficiary-payout {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: #2f80ed;
  margin-bottom: 20px;
  text-align: center;
}

.playground-pay .ant-space-item .ant-radio-wrapper {
  padding: 10px;
  width: 100%;
  border: 1px solid #35438e;
  border-radius: 4px;
}

.table-in {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ff84bf;
  padding: 5px;
  color: white;
}

.payout-modal-text .selected .grey-bg-name .benefit-name {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #1c223c;
  margin-bottom: 0px;
}

.in-houseModal h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 39px;
  letter-spacing: 0.01em;
  color: #1c223c;
}

.in-houseModal .ant-space-item .ant-radio-wrapper {
  padding: 10px;
  width: 100%;
  border: 1px solid #35438e;
  border-radius: 4px;
}
