@import "../../../scss/mixins";

.form {
  width: 100%;
}

.formWrapper {
  flex: 1;
  max-width: 480px;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @include breakpoint(large) {
    flex-direction: row;
  }

  & > div {
    flex: 1;

    &:only-child {
      @include breakpoint(large) {
        min-width: 480px;
      }
    }
  }
}

.userCard {
  width: 100%;
  order: -1;

  @include breakpoint(large) {
    order: 0;
  }
}

.footer {
  position: absolute;
  left: 24px;
  right: 24px;
  bottom: 24px;

  @include breakpoint(large) {
    position: relative;
  }
}
