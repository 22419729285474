.body-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
}
.body-div .logo {
  position: absolute;
  top: 20px;
  left: 60px;
  width: 200px;
}

.body-div .reg-link {
  margin-top:20px;
}

.body-div .reg-link p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #4b487a;
}

.body-div .reg-link p a {
  color: #5a50fe;
  font-weight: 700;
}

.body-div .title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 24px;
  color: #040052;
  text-align: center;
  margin-bottom: 20px;
}

.body-div .paragraph {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #4b487a;
  margin-bottom: 30px;
}

.body-div .login-form {
  width: 100%;
  max-width: 400px;
  padding: 10px;
}

.body-div .form-group {
  margin-bottom: 15px;
}

.body-div .label-form {
  display: block;
  margin-bottom: 5px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444052;
}

.body-div .ant-checkbox-wrapper {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #74748a;
}

.body-div .auth-input {
  width: 100%;
  padding: 10px 20px;
  height: 56px;
  font-size: 16px;
  background: transparent;
  border: 1px solid #cbcae5;
  border-radius: 8px !important;
}

.body-div .auth-input:focus {
  outline: none !important;
  border: 1px solid #5a50fe !important;
  box-shadow: none;
}

/* .ant-input-focused,
.ant-input-focused:hover,
.ant-input-focused:active {
    background-color: transparent !important;
} */

.body-div .external-link {
  background: transparent;
  border: none;
  outline: none;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #5a50fe;
  cursor: pointer;
}

.no-otp {
  border: none;
  outline: none;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: black;
}

.body-div .submit-btn {
  width: 100%;
  padding: 10px;
  border: none;
  cursor: pointer;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  height: 56px;
  background: #5a50fe;
  border-radius: 12px;
}

.body-div .submit-btn:disabled {
  background-color:#E2E0FF;
  cursor: not-allowed;
}

.body-div .submit-btn:focus {
  outline: none;
  border:none;
}

.body-div .auth-footer {
  position: absolute;
  bottom: 30px;
  /* width: 30%; */
}

.body-div .auth-footer .under-auth-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 45px;
  padding: 20px;
}

.body-div .auth-footer p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #55555e;
}

.body-div .auth-footer p a:hover {
  color: #5a50fe;
}

@media only screen and (max-width: 1024px) {
  .body-div .logo {
    left: 20px;
    width: 100px;
  }

  .body-div .auth-footer {
    bottom: 10px;
  }
}
