@import url("dashboard.css");
@import url("sidebar.css");
@import url("header.css");
@import url("wallets.css");
@import url("card.css");
.site-layout {
  margin-left: 0;
}

.layout-changes {
  position: relative;
}

.layout-changes .ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 2px;
  left: 2px;
  cursor: pointer;
}

.layout-changes .ant-layout-content {
  background-color: #ffffff;
  overflow-x: scroll;
  height: 100vh;
}

.layout-changes .ant-layout-content::-webkit-scrollbar {
  display: none;
}

.layout-changes .ant-layout-content {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.site-layout-background {
  background-color: var(--pageBackgroundColor) !important;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.site-layout-background > div {
  width: 100%;
  max-width: calc(1720px - 4rem);
}

.ant-dropdown-menu {
  box-shadow: var(--dropdownMenuBoxShadow);
  border-radius: 0.25rem;
}

@media only screen and (min-width: 768px) {
  .site-layout-background {
    padding: 0.5rem 1.5rem;
  }
}

/* @media only screen and (min-width: 992px) {
    .site-layout {
        margin-left: 200px;
    }
} */

@media only screen and (min-width: 1200px) {
  .site-layout-background {
    padding: 1rem 2rem;
  }
}
