.loader {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.small {
  width: 12px;
  height: 12px;
}

.medium {
  width: 24px;
  height: 24px;
}

.large {
  width: 24px;
  height: 24px;
}
