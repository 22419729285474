@import "../../../scss/variables";
@import "../../../scss/mixins";

.banner {
  border-radius: 8px;
  border: 1px solid #faebff;
  background: #fbf0ff;
  padding: 8px 12px;
  gap: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  @include breakpoint(large) {
    flex-direction: row;
  }
}

.cta {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;

  @include breakpoint(large) {
    width: auto;
  }
}

.closeButton {
  padding: 0;
}

.content {
  display: flex;
  align-items: center;
  gap: 16px;
  color: #8f59a0;

  & > p,
  i,
  a,
  a:hover {
    color: inherit;
  }
}

.icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: $light-purple;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $purple;
}

.closeIcon {
  width: 24px;
  height: 24px;
}

.iconWrapper {
  border: 6px solid #f8e1ff;
  height: 40px;
  width: 40px;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
