@import "../../../../scss/mixins";

.wrapper {
  width: 100%;
  height: 100%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: unset;
  }

  & > div {
    flex: 1;
    &:only-child {
      flex: none;
    }
  }
}

.content {
  height: 100%;

  @media screen and (max-width: 768px) {
    width: auto;
  }
}

.cardWrapper {
  display: flex;
  justify-content: center;
  order: -1;

  @include breakpoint(medium) {
    order: 1;
  }
}

.card {
  background: #f6f6f7;
  border-radius: 4px;
  margin-top: 10px;
  padding: 10px 16px;
  width: 300px;
  height: fit-content;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 16px;
  }
}

.label {
  margin-bottom: 8px;
  font-weight: bold;
}

.contentWrapper {
  display: flex;
  flex-direction: column;

  @include breakpoint(medium) {
    display: grid;
  }
}

.footer {
  @media screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: center;

    & > div {
      width: 100%;
    }
  }
}

.button {
  float: right;

  @media screen and (max-width: 768px) {
    width: 100%;
    float: none;
  }
}

.tabs {
  margin: 16px 0;
}
