.bg-grey {
  background: #e5e5e5 !important;
}

.color-warning {
  color: #ffd200 !important;
}

.color-dark-green {
  color: #554600 !important;
}

.bg-white {
  background: var(--whiteColor) !important;
  border-radius: 4px !important;
}

.bg-light-blue {
  background: #f7fafe;
}

.bg-light-grey {
  background: #f7f7fa;
}

.bg-primary {
  color: white  !important;
  background: #5A50FE !important;
}

.bg-primary:hover {
  background: #5A50FE !important;
}

.sider.bg-white {
  background-color: var(--whiteColor);
}

.bg-pink {
  background: #efcfd6;
}

.text-error {
  color: #930d28;
}

.btn-light {
  background: #f0f0f0 !important;
  border-radius: 4px;
}

.btn-primary {
  color: #fff !important;
}
