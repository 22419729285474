:root {
    --pageBackgroundColor: #ffffff;
    --primaryColor: #5a50fe;
    --blackColor: #000000;
    --secondaryButtonColor: #eeeeee;
    --successColor: #48d38a;
    --negativeColor: #f7685b;
    --textColor: #707070;
    --whiteColor: #ffffff;
    --offWhite: #f9f9f9;
    --offWhiteTrans: rgba(219, 220, 224, 0.3);
    --borderOff: rgba(219, 220, 224, 0.5);
    --offText: rgba(0, 0, 0, 0.5);
    --bgOffset: #f4f4f4;
    --bgGrey: #e5e5e5;
    --placeHolder: #979797;
    --offBlue: #eff1ff;
    --boxShadow: rgba(64, 25, 109, 0.07);
    --ctaOffBlue: rgba(64, 25, 109, 0.3);
    --overlay: rgba(64, 25, 109, 0.2);
    --successColorTrans: #dcffec;
    --lightBlue: #5a50fe;
    --textColorLightGrey: #cacaca;
    --menuColor: #5a50fe;
    --textColorTwo: #555555;
    --boldTextColor: #1c223c;
    --purpleLightColor: #ccccf9;
    --dashboardCardBoxShadow: rgba(50, 50, 93, 0.15) 0px 3px 4px -1px, rgba(0, 0, 0, 0.2) 0px 1.5px 3.5px -1.5px;
    --sidebarBoxShadow: rgba(50, 50, 93, 0.25) 0px 3px 6px -1px, rgba(0, 0, 0, 0.3) 0px 1.5px 3.5px -1.75px;
    --headerBoxShadow: rgba(50, 50, 93, 0.15) 0px 2px 5px -1px, rgba(0, 0, 0, 0.15) 0px 1px 3px -1px;
    --dashboardCardFooterbackgroundColor: #f5f5f6;
    --titleColor: #040052;
    --subTitleColor: #55555E;
    --bodyTextColor: #4B487A;
    --positiveChangeColor: #008A00;
    --negativeChangeColor: #FD66A3;
    --chartLoaderBarColor: #E5E5E5;
    --dropdownMenuBoxShadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    --dropdownMenuSectionBackgroundColor: #F4F6F7;
    --dropdownMenuSelectedRadioButtonBackgroundColor: #ECEBFF;
    --dropdownMenuTitleColor: #444052;
    --dropdownMenuListBorderColor: #D9DBDD;
    --lightPrimaryColor: #CBCAE5;
    --sidebarMenuColor: #616474;
    --sidebarSubMenuBackgroundColor: #F6F6F7;
    --walletInListHoverBoxShadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    --xxxsFont: 0.65rem;
    --xxsFont: 0.75rem;
    --xsFont: 0.85rem;
    --smFont: 0.95rem;
    --mdFont: 1.05rem;
    --lgFont: 1.15rem;
    --xlFont: 1.25rem;
    --xxlFont: 1.35rem;
    --xxxlFont: 1.45rem;
    --bgLightGrey: #f6f9fe;
    --inputHeight: 2.5rem;
}