.select-phone-input {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.select-phone-input .drpdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.select-phone-input .drpdown-button {
  border: 1px solid #cbcae5;
  border-radius: 8px;
  padding: 8px 18px 8px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 50px;
  background: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  color: #444052;
}

.select-phone-input .drpdown-button:focus {
  border-radius: 8px;
  outline: none;
  border: 1px solid #cbcae5;
}

.select-phone-input .drpdown-button .arrow-btn {
  width: 20px;
  margin-left: 10px;
}

.select-phone-input .drpdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  /* width: 365px; */
  background-color: #ffffff;
  border-radius: 8px;
  margin-top: 4px;
  list-style: none;
  padding: 20px 10px;
  z-index: 1;
  overflow-y: scroll;
  height: 370px;
  box-shadow: 0px 2px 8px 0px #0000000A;
  box-shadow: 0px 4px 12px -3px #11182621;
}

.select-phone-input .drpdown-menu .search-input {
  background-color: #ffffff;
  border: 1px solid #cbcae5;
  padding: 0 16px;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6f6e80;
  margin-bottom: 5px;
}

.select-phone-input .drpdown-menu .drpdown-item {
  color: #040052;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
}

.select-phone-input .drpdown-menu .drpdown-item-first {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  color: #5a50fe;
  cursor: pointer;
}

/* .select-phone-input .drpdown-menu .drpdown-item:hover {
  background-color: #e9ebed;
} */
