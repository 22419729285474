/* Anti Alias */

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: none !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  font-size: 16px;
  color: var(--textColor);
  font-weight: 400;
  font-family: "eFont", sans-serif;
  background-color: #fbfaff;
  font-size: 1rem;
  min-height: 100vh;
  min-height: 100dvh;
}

a {
  text-decoration: none;
  color: inherit;
}

input {
  /* Remove First */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input::placeholder,
input:placeholder-shown {
  color: var(--textColor);
  line-height: 29px;
  font-weight: 400;
}

img {
  border-style: none;
}

p,
li {
  font-size: 1rem;
  line-height: 1.5;
}
