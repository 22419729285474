@import "../../../scss/variables";

.input {
  padding: 16px 24px;
  border-radius: 8px;
  border: 1px solid #5a50fe;
  font-size: 16px;
  width: 100%;

  &:focus,
  &:focus-visible {
    border-width: 2px;
    outline: none;
  }
}

.container {
  border: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
}

.inputcontainer {
  position: relative;
  width: 100%;
}

.icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  padding: inherit;
  background-color: white;
}

.label {
  margin-bottom: 12px;
}

.error {
  border-color: $error;
}

.errorMessage {
  color: $error;
  margin-top: 14px;
  font-size: 14px;

  svg,
  path {
    fill: currentColor;
  }
}
