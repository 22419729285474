.text {
  font-size: 16px;
  padding: 0px;
  margin: 0px;
}

.bold {
  font-weight: bold;
  margin-right: 6px;
}
