.card-virtual-create {
  background-color: var(--cardBackgroundColor);
  border-radius: 0.5rem;
  box-shadow: var(--cardBoxShadow);
  width: 100%;
  height: 200px;
  border-radius: 12px;
}

.colors-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.colors-container .color {
  width: 50px;
  height: 50px;
  border: 2px;
  padding: 8px;
  border-radius: 50%;
  border: 4px solid #fff;
}

.colors-container .color.blue {
  background: #5a50fe;
}

.colors-container .color.black {
  background: #040052;
}

.colors-container .color.purple {
  /* border: 2px solid var(--dangerColor); */
  background: #FF3599;
}

.colors-container .color.orange {
  /* border: 2px solid var(--dangerColor); */
  background: #00C279;
}

.colors-container .color.yellow {
  /* border: 2px solid var(--dangerColor); */
  background: #EAA5FF;
}
