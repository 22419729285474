.country-phone-input {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.country-phone-input .drpdown {
  position: relative;
  display: inline-block;
}

.country-phone-input .drpdown-button {
  border: 1px solid #cbcae5;
  border-right: none;
  border-radius: 8px 0 0 8px;
  padding: 8px 0px 8px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 56px;
  background: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444052;
}

.country-phone-input .drpdown-button:focus {
  border-radius: 8px 0 0 8px;
  outline: none;
  border: 1px solid #cbcae5;
  border-right: none;
}

.country-phone-input .drpdown-button .arrow-btn {
  width: 20px;
  margin-left: 10px;
}

.country-phone-input .drpdown-button span {
  margin-left: 10px;
}

.country-phone-input .drpdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  /* width: 100%; */
  width: 400px;
  background-color: #ffffff;
  border: 1px solid #cbcae5;
  border-radius: 4px;
  margin-top: 4px;
  list-style: none;
  padding: 10px;
  z-index: 1;
  overflow-y: scroll;
  height: 150px;
}

.country-phone-input .drpdown-menu .search-input {
  background-color: #ffffff;
  border: 1px solid #cbcae5;
  padding: 0 16px;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6f6e80;
  margin-bottom: 5px;
}

.country-phone-input .drpdown-menu .drpdown-item {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444052;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 5px;
}

.country-phone-input .drpdown-menu .drpdown-item:hover {
  background-color: #E9EBED;
}

.country-phone-input .drpdown-menu .drpdown-item span {
  margin-left: 5px;
}

.country-phone-input .phone-input {
  background-color: #ffffff;
  border: 1px solid #cbcae5;
  border-left: none;
  padding: 0 16px;
  border-radius: 0 8px 8px 0;
  height: 56px;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6f6e80;
}

.country-phone-input .phone-input:focus {
  border-radius: 0 8px 8px 0;
  outline: none;
  border: 1px solid #cbcae5;
  border-left: none;
}
