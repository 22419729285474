.no-business-hero-section {
  display: flex;
  height: 100vh;
  align-items: center;
  padding: 0 150px;
}

.no-business-hero-section .text-column {
  flex: 1;
  padding: 20px;
}
.no-business-hero-section .text-column h1 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 28px;
  color: #444052;
}

.no-business-hero-section .text-column h2 {
  max-width: 452.96px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 40px;
  color: #040052;
}

.no-business-hero-section .text-column h3 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #4b487a;
}

.no-business-hero-section .text-column h3 span {
  color: #5a50fe;
  cursor: pointer;
}

.no-business-hero-section .text-column p {
  max-width: 363.76px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #4b487a;
}

.no-business-hero-section .text-column p a {
  color: #5a50fe;
}

.no-business-hero-section .text-column .visit-site-second {
  background: #5a50fe;
  border-radius: 12px;
  /* width: 100%; */
  padding: 15px 70px;
  border: none;
  cursor: pointer;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  height: 54px;
  max-width: 285px;
  margin-bottom: 20px;
}

.no-business-hero-section .text-column button {
  background: #5a50fe;
  border-radius: 12px;
  /* width: 100%; */
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  height: 54px;
  max-width: 285px;
  margin-bottom: 20px;
}

.no-business-hero-section .text-column .visit-site {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #5a50fe;
}

.no-business-hero-section .text-column .visit-site  img {
  width: 20px;
}

.no-business-hero-section .image-column {
  flex: 1;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-business-hero-section .image-column img {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .no-business-hero-section {
    flex-direction: column;
    justify-content: center;
    padding: 20px 20px 0 20px;
  }

  .no-business-hero-section .text-column,
  .no-business-hero-section .image-column {
    flex: 1;
    padding: 10px;
  }

  .no-business-hero-section .image-column img {
    max-height: 50%;
  }
}

.sign-container {
  display: flex;
  flex-direction: column;
}

.sign-container .logo {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 200px;
  z-index: 1;
}

.sign-container .login-link {
  position: absolute;
  top: 20px;
  right: 60px;
}

.sign-container .login-link p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4b487a;
}

.sign-container .login-link p a {
  color: #5a50fe;
  font-weight: 700;
}

.sign-container .content {
  display: flex;
  height: 100vh;
}

.sign-container .image-container {
  flex: 0 0 40%; /* Change the width percentage here */
  position: relative;
  background-color: #f0f0f0;
  overflow: hidden;
}

.sign-container .image-container .image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.sign-container .image-container .image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}

/* .sign-container .image-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  );
} */

.sign-container .form-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #ffffff;
}

.sign-container .form-container h2 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  color: #040052;
  max-width: 359px;
  margin-bottom: 20px;
}

.sign-container .form-container .form-group {
  margin-bottom: 15px;
}

.sign-container .form-container .form-group .label-form {
  display: block;
  margin-bottom: 5px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444052;
}
.sign-container .form-details {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
}

.sign-container .form-details .custom-width {
  width: 100%;
  max-width: 280px;
}

.sign-container .form-details .terms-cond {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1px;
  color: #4b487a;
  margin-top: 15px;
}

.sign-container .form-details .input-row {
  display: flex;
  width: 100%;
}

.sign-container .form-details .input-row input {
  flex: 1;
  margin-bottom: 10px;
  padding: 10px 20px;
  height: 56px;
  font-size: 16px;
  border: 1px solid #cbcae5;
  border-radius: 8px 0 0 8px;
  width: 100%;
  color: #6f6e80;
}

.sign-container .form-details .input-row input:last-child {
  border-radius: 0 8px 8px 0;
  border-left: 0px;
}

.sign-container .form-details .input-row input:focus {
  outline: none;
  border: 1px solid #5a50fe;
  box-shadow: none;
}

.sign-container .form-details .reg-input {
  margin-bottom: 10px;
  padding: 10px 20px;
  height: 56px;
  font-size: 16px;
  border: 1px solid #cbcae5;
  border-radius: 8px;
  width: 100%;
  color: #6f6e80;
}

.sign-container .form-details .reg-input:focus {
  outline: none;
  border: 1px solid #5a50fe;
  box-shadow: none;
}

.sign-container .form-details .auth-input {
  width: 100%;
  padding: 10px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #cbcae5;
  border-radius: 8px !important;
  color: #6f6e80;
}

.sign-container .form-details .auth-input:focus {
  outline: none !important;
  border: 1px solid #5a50fe !important;
  box-shadow: none;
}

.verify-edit .country-phone-input .drpdown-button {
  height: 40px !important;
}
.verify-edit .country-phone-input .phone-input {
  height: 40px !important;
  width: 52% !important;
}

.sign-container .form-details .submit-reg {
  width: 100%;
  padding: 10px;
  border: none;
  cursor: pointer;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  height: 56px;
  background: #5a50fe;
  border-radius: 12px;
}

.sign-container .form-details .submit-reg:disabled {
  background-color: #e2e0ff;
}

.body-div .submit-btn:focus {
  outline: none;
  border:none;
}

.custom-radio-group {
  display: flex;
  flex-direction: column;
}

.custom-radio {
  position: relative;
  padding-left: 54px;
  padding-top: 16px;
  cursor: pointer;
  user-select: none;

  margin-bottom: 15px;
  height: 56px;
  font-size: 16px;
  border: 1px solid #cbcae5;
  border-radius: 8px;
}

.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-radio .checkmark {
  position: absolute;
  top: 18px;
  left: 20px;
  height: 20px;
  width: 20px;
  /* background-color: #ccc; */
  border: 1px solid #444052;
  border-radius: 50%;
}

.custom-radio:hover input ~ .checkmark {
  border: 1px solid #5a50fe;
}

.custom-radio input:checked ~ .checkmark {
  border: 1px solid #5a50fe;
}

.custom-radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-radio input:checked ~ .checkmark:after {
  display: block;
}

.custom-radio .checkmark:after {
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #5a50fe;
}

.sign-container .form-container .progress-bar {
  width: 100px;
  height: 8px;
  background-color: #e9ebed;
  border-radius: 10px;
  position: relative;
}

.sign-container .form-container .progress-step {
  position: absolute;
  width: 20px;
  height: 8px;
  border-radius: 10px;
  background-color: #5a50fe;
}

.sign-container .form-container .back-button {
  background: transparent;
  border: none;
  outline: none;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #74748a;
  cursor: pointer;
}

.custom-radio-group-two {
  display: flex;
  flex-direction: column;
}

.custom-radio-two {
  position: relative;
  padding-left: 50px;
  padding-top: 17px;
  padding-bottom: 90px;
  cursor: pointer;
  user-select: none;

  margin-bottom: 10px;
  height: 56px;
  font-size: 14px;
  border: 1px solid #cbcae5;
  border-radius: 8px;
}

.custom-radio-two input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-radio-two .checkmarktwo {
  position: absolute;
  top: 42px;
  left: 17px;
  height: 20px;
  width: 20px;
  /* background-color: #ccc; */
  border: 1px solid #444052;
  border-radius: 50%;
}

.custom-radio-two:hover input ~ .checkmarktwo {
  border: 1px solid #5a50fe;
}

.custom-radio-two input:checked ~ .checkmarktwo {
  border: 1px solid #5a50fe;
}

.custom-radio-two .checkmarktwo:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-radio-two input:checked ~ .checkmarktwo:after {
  display: block;
}

.custom-radio-two .checkmarktwo:after {
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #5a50fe;
}

.biz-title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #040052;
}

.biz-desc {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4b487a;
  max-width: 312px;
}

.sign-container .form-details .verify-box {
  padding: 16px;
  max-width: 400px;
  background: #ffffff;
  border: 1px solid #e9ebed;
  border-radius: 8px;
  margin: 15px 0;
}

.sign-container .form-details .verify-box .verify-title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #444052;
}

.sign-container .form-details .verify-box .verify-btn {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #ffffff;
  padding: 8px 20px;
  background: #5a50fe;
  border-radius: 8px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 8px;
}

.sign-container .form-details .verify-box .verify-btn:focus {
  outline: none;
  border: none;
}

.sign-container .form-details .verify-box .verify-btn:disabled {
  background-color: #e2e0ff;
  cursor: not-allowed;
}

.email-edit-box {
  background: #f8f9fa;
  border-radius: 4px;
  padding: 8px;
  height: 44px;
}

.verify-edit-btn::before {
  content: "|";
  margin-right: 8px;
  color: #cbcae5;
}

.verify-edit-btn {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #5a50fe;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.verify-edit-btn:disabled {
  color: #e2e0ff;
  cursor: not-allowed;
}

.verify-confirm-btn {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #5a50fe;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.re-enter-email-input {
  border: 1px solid #cbcae5;
  border-radius: 8px;

  padding: 8px 16px;
  height: 40px;
  width: 75%;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6f6e80;
}

.re-enter-email-input:focus {
  border: 1px solid #5a50fe;
  outline: none;
}

.verify-box .formInput {
  width: 90% !important;
  height: 40px !important;
}

.verify-otp-view-p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #4b487a;
  max-width: 368px;
  margin: 0;
}

.verify-otp-view-p span {
  text-decoration: underline;
}

.verify-resend-otp-btn {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1.5px;
  color: #5a50fe;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.verify-resend-otp-btn:disabled {
  color: #e2e0ff;
}

.sign-container .form-details .verify-box .verify-email-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #444052;
}

.sign-container .form-details .verify-box .verify-email-text img {
  width: 15px;
}

@media only screen and (max-width: 1024px) {
  .sign-container .logo {
    left: 20px;
    width: 100px;
  }
  .sign-container .login-link p {
    font-size: 12px;
    color: white;
  }

  .sign-container .login-link {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1000;
  }
  .sign-container .content {
    flex-direction: column;
  }
}
